import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import FormActions from '../../../components/formActions';
import { crmService } from '../../../services/central-api/crm';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';

interface Props {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    maintenanceRequestId?: number;
    onSubmit?: VoidFunction;
}

function ActMaintenanceRequestModal({ isVisible, setIsVisible, maintenanceRequestId, onSubmit }: Props) {
    const { styles, theme } = useStyles(stylesheet);
    const [loading, setIsLoading] = useState(true);
    const [maintenanceRequest, setMaintenanceRequest] = useState<MaintenanceRequest>();

    async function setMaintenanceRequestActedByUser() {
        if (!maintenanceRequestId) return;

        try {
            await crmService.ackMaintenanceRequest(maintenanceRequestId);

            setIsVisible(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function getMaintenanceRequestInfo() {
        if (!maintenanceRequestId) return;

        try {
            const maintenanceRequest = await crmService.getMaintenanceRequest(maintenanceRequestId);
            setMaintenanceRequest(maintenanceRequest);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        getMaintenanceRequestInfo();
    }, [maintenanceRequestId]);

    return (
        <Modal visible={isVisible} animationType='fade' transparent onRequestClose={() => setIsVisible(false)}>
            <View style={styles.modalContainer}>
                <View style={styles.modalComponent}>

                    {loading ? <View style={{ height: 40 }}>
                        <ActivityIndicator size='small' color={theme.colors.loadingColor} />
                    </View>
                        :
                        <>
                            <View>
                                <MyAppText style={styles.modalTitle}>{translate('reason')}:</MyAppText>
                                <MyAppText>{maintenanceRequest?.reason}</MyAppText>
                            </View>
                            <MyAppText style={styles.modalTitle}>{translate('actMaintenanceRequestConfirmMessage')}</MyAppText>

                            <FormActions
                                onSubmit={async () => {
                                    await setMaintenanceRequestActedByUser();
                                    onSubmit?.();
                                }}
                                onClose={() => {
                                    setIsVisible(false);
                                }}
                                confirmText={'confirm'}
                                disabled={false}
                            />
                        </>
                    }
                </View>
            </View>
        </Modal>
    );
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
        justifyContent: 'center',
        alignItems: 'center',
    },

    modalComponent: {
        minWidth: {
            xs: undefined,
            md: 450
        },
        minHeight: 150,
        width: {
            md: '50%',
            xs: '90%'
        },
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },

    modalTitle: {
        fontSize: 18,
        fontWeight: '600'
    },

    dateFieldsContainer: {
        flexDirection: 'row',
        gap: {
            xs: 10,
            md: 50
        },
        flexWrap: 'wrap'
    }
}));

export default ActMaintenanceRequestModal;
