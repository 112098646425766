import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { facialService } from '../../services/central-api/facial';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function PeopleDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: string; }[]>([]);

    async function getItems() {
        try {
            const people = await facialService.getFacialPeople({ page: 0, limit: 999999 });
            setItems(people.rows.map((person) => {
                return {
                    value: String(person.id),
                    label: person.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    return (
        <FilterDropdown
            label={translate('person')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default PeopleDropdown;
