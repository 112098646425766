import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import Toast from 'react-native-toast-message';
import { crmService } from '../../../services/central-api/crm';
import { ClientError } from '../../../services/central-api/base-service';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface ModalProps {
    onClose: (success: boolean) => void;
    cameraData?: { id?: string, disabledAt: Date | null; };
    setDisabledAt: React.Dispatch<React.SetStateAction<Date | null>>;
    isModalVisible: boolean;
}

export default function DisableEnableModal({ onClose, cameraData, setDisabledAt, isModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);

    const [description, setDescription] = useState('');
    function isDescriptionInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isDescriptionInvalid(description);
    }

    async function formSubmit() {
        try {
            if (cameraData?.id) {
                const camera = await crmService.disableEnableCamera(cameraData.id, cameraData.disabledAt !== null ? 'enable' : 'disable', description);
                setDisabledAt(camera.disabledAt);
            }
            onClose(true);
            setDescription('');
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            transparent={true}
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => onClose(false)}
        >
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <View>
                        <FormInput
                            label={translate('description')}
                            placeholder={translate('description')}
                            value={description}
                            onChangeText={setDescription}
                            invalid={isDescriptionInvalid}
                        />
                    </View>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => onClose(false)}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        width: '25%',
        minHeight: 150,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25,
        justifyContent: 'space-between'
    },
}));
