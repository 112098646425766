import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView, ActivityIndicator, TouchableOpacity, TextInput } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons';

import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { Action, Log, auditLogsService, GetLogsParameters } from '../../services/central-api/audit-logs';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { LogsParamList } from '../../typings/Params';
import { RouteProp, useRoute } from '@react-navigation/native';
import CustomButton from '../../components/CustomButton';


export default function LogList({ navigation }: { navigation: Navigation; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<LogsParamList, 'LogList'>>();

    const [filters, setFilters] = useState<GetLogsParameters>({
        page: 0,
        limit: 100,
        sort: route.params.sort ?? 'desc',
        dateGte: route.params.dateGte ? new Date(route.params.dateGte).toISOString() : undefined,
        dateLte: route.params.dateLte ? new Date(route.params.dateLte).toISOString() : undefined,
        queryString: route.params.queryString ?? '',
        userId: route.params.userId ?? undefined,
        action: route.params.action ?? undefined
    });

    const [logs, setLogs] = useState<Log[]>([]);
    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isActionsPickerOpen, setIsActionsPickerOpen] = useState<boolean>(false);
    const [selectableActionsFilter, setSelectableActionsFilter] = useState<{ label: string, value: Action | ''; }[]>([]);
    const [selectedActionFilter, setSelectedActionFilter] = useState<Action | ''>(filters.action as Action || '');
    const [isUsersPickerOpen, setIsUsersPickerOpen] = useState<boolean>(false);
    const [selectableUsersFilter, setSelectableUsersFilter] = useState<{ label: string, value: number; }[]>([]);
    const [selectedUserFilter, setSelectedUserFilter] = useState<number>(filters.userId || 0);
    const [descriptionFilter, setDescriptionFilter] = useState<string | undefined>(filters.queryString);
    const [sortBy, setSortBy] = useState<'asc' | 'desc'>(filters.sort || 'desc');

    const now = new Date();
    const [dateFilter, setDateFilter] = useState(moment(filters.dateLte ?? now).format('DD/MM/YYYY'));
    const [hourStartFilter, setHourStartFilter] = useState(filters.dateGte ? moment(filters.dateGte).format('HH:mm') : '');
    const [hourEndFilter, setHourEndFilter] = useState(filters.dateLte ? moment(filters.dateLte).format('HH:mm') : '');

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    function getActions() {
        const actions: { label: string, value: Action | ''; }[] = Object.keys(Action).map((item) => {
            const action = Action[item as keyof typeof Action];
            return {
                label: translate(item),
                value: action
            };
        });
        actions.push({ label: '', value: '' });
        setSelectableActionsFilter(actions.sort((a, b) => b.label > a.label ? -1 : 1));
    }

    async function getUsers() {
        try {
            const users = await auditLogsService.getAllUsers();
            setSelectableUsersFilter([{
                label: '',
                value: 0
            }, {
                label: translate('systemGenerated'),
                value: -1
            }, ...users.map((user) => {
                return {
                    label: user.name,
                    value: user.id
                };
            })]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getLogs(filters: GetLogsParameters) {
        try {
            setIsLoading(true);
            const newLogs = await auditLogsService.getLogs(filters);
            if (newLogs.length < filters.limit) {
                setHasMoreResults(false);
            } else {
                setHasMoreResults(true);
            }
            if (filters.page == 0) {
                logs.length = 0;
            }
            logs.push(...newLogs);
            setLogs(logs);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getActions();
        getUsers();
    }, []);

    useEffect(() => {
        getLogs(filters);
    }, [filters]);

    function useFilters() {
        setIsActionsPickerOpen(false);

        let dateGte: Date | undefined = undefined;
        let dateLte: Date | undefined = undefined;

        if (dateFilter) {
            if (hourStartFilter) {
                dateGte = new Date(moment(`${dateFilter} ${hourStartFilter}`, 'DD/MM/YYYY HH:mm').valueOf());
            }

            if (hourEndFilter) {
                dateLte = new Date(moment(`${dateFilter} ${hourEndFilter}`, 'DD/MM/YYYY HH:mm').valueOf());
            }
        }

        setFilters({
            ...filters,
            page: 0,
            action: !selectedActionFilter ? undefined : selectedActionFilter,
            userId: selectedUserFilter == 0 ? undefined : selectedUserFilter,
            queryString: descriptionFilter,
            dateGte: dateGte ? dateGte.toISOString() : undefined,
            dateLte: dateLte ? dateLte.toISOString() : undefined,
            sort: sortBy
        });
        navigation.setParams({
            ...route.params,
            userId: selectedUserFilter == 0 ? undefined : selectedUserFilter,
            action: selectedActionFilter,
            queryString: descriptionFilter,
            dateGte: dateGte ? dateGte.getTime() : undefined,
            dateLte: dateLte ? dateLte.getTime() : undefined,
            sort: sortBy
        });
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={{ maxWidth: 120, minWidth: 120 }}>
                    <MyAppText style={styles.filterText}>{translate('date')}</MyAppText>
                    <TextInput
                        style={styles.filterInput}
                        value={dateFilter}
                        onChangeText={setDateFilter}
                    />
                </View>
                <View style={{ maxWidth: 80, minWidth: 80 }}>
                    <MyAppText style={styles.filterText}>{translate('start')}</MyAppText>
                    <TextInput
                        style={styles.filterInput}
                        value={hourStartFilter}
                        onChangeText={setHourStartFilter}
                    />
                </View>
                <View style={{ maxWidth: 80, minWidth: 80 }}>
                    <MyAppText style={styles.filterText}>{translate('end')}</MyAppText>
                    <TextInput
                        style={styles.filterInput}
                        value={hourEndFilter}
                        onChangeText={setHourEndFilter}
                    />
                </View>
                <View style={{ flexGrow: 1, minWidth: 300, zIndex: 3 }}>
                    <MyAppText style={styles.filterText}>{translate('actions')}</MyAppText>
                    <MyDropDownPicker
                        open={isActionsPickerOpen}
                        value={selectedActionFilter}
                        items={selectableActionsFilter}
                        setOpen={setIsActionsPickerOpen}
                        setValue={setSelectedActionFilter}
                        searchable={true}
                        borderColor={getThemedColor(theme, '#888888')}
                    />
                </View>
                <View style={{ flexGrow: 1, minWidth: 300, zIndex: 2 }}>
                    <MyAppText style={styles.filterText}>{translate('users')}</MyAppText>
                    <MyDropDownPicker
                        open={isUsersPickerOpen}
                        value={selectedUserFilter}
                        items={selectableUsersFilter}
                        setOpen={setIsUsersPickerOpen}
                        setValue={setSelectedUserFilter}
                        searchable={true}
                        borderColor={getThemedColor(theme, '#888888')}
                    />
                </View>
                <View style={{ flexGrow: 1, minWidth: 300 }}>
                    <MyAppText style={styles.filterText}>{translate('description')}</MyAppText>
                    <TextInput
                        style={styles.filterInput}
                        value={descriptionFilter}
                        onChangeText={setDescriptionFilter}
                    />
                </View>
                <View>
                    <MyAppText style={styles.filterText} />
                    <CustomButton
                        onPress={useFilters}
                        text={translate('toSearch')}
                        icon={faSearch}
                    />
                </View>
            </View>
            <View style={styles.cardList}>
                <View style={styles.listHeader}>
                    <View style={{ flexBasis: '10%' }}>
                        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                            const sort = filters.sort === 'asc' ? 'desc' : 'asc';
                            setSortBy(sort);
                            setFilters({ ...filters, sort, page: 0 });
                        }}>
                            <MyAppText style={styles.headerCell}>{translate('date')}</MyAppText>
                            {filters.sort === 'asc' ?
                                <FontAwesomeIcon icon={faAngleUp}
                                    style={{ ...styles.headerCell, alignSelf: 'center', position: 'absolute', right: 25 }}
                                />
                                :
                                <FontAwesomeIcon icon={faAngleDown}
                                    style={{ ...styles.headerCell, alignSelf: 'center', position: 'absolute', right: 25 }}
                                />
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexBasis: '10%' }}>
                        <MyAppText style={styles.headerCell}>{translate('user')}</MyAppText>
                    </View>
                    <View style={{ flexBasis: '20%' }}>
                        <MyAppText style={styles.headerCell}>{translate('ipAddress')}</MyAppText>
                    </View>
                    <View style={{ flexBasis: '10%' }}>
                        <MyAppText style={styles.headerCell}>{translate('action')}</MyAppText>
                    </View>
                    <View style={{ flexBasis: '50%' }}>
                        <MyAppText style={styles.headerCell}>{translate('description')}</MyAppText>
                    </View>
                </View>
                <ScrollView>
                    <View style={{ gap: 10 }}>
                        {isLoading ?
                            <View>
                                <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                            </View>
                            : null
                        }
                        {logs.length ?
                            logs.map((log, logIndex) => (
                                <View key={logIndex} style={{ rowGap: 9 }}>
                                    <View style={styles.row} >
                                        <View style={{ flexBasis: '10%' }}>
                                            <MyAppText style={styles.cell}>
                                                {moment(log.date).format('DD/MM/YYYY HH:mm:ss')}
                                            </MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '10%' }}>
                                            <MyAppText style={styles.cell}>
                                                {log.userId == -1 && log.userName ? translate(log.userName) : log.userId ? `${log.userId} - ${log.userName}` : translate('notApplicable')}
                                            </MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '20%' }}>
                                            <MyAppText style={styles.cell}>{log.ip}</MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '10%' }}>
                                            <MyAppText style={styles.cell}>{translate(log.action)}</MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '50%' }}>
                                            <MyAppText style={styles.cell}>{JSON.stringify(log.detail)}</MyAppText>
                                        </View>
                                    </View >
                                    <View style={styles.tableLine}></View>
                                </View>
                            )) :
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <MyAppText style={{ color: getThemedColor(theme, '#58595B') }}>
                                    {translate('noResults')}.
                                </MyAppText>
                            </View>
                        }
                    </View>
                    {logs.length && hasMoreResults && !isLoading ?
                        <Hoverable style={styles.loadMore}>
                            {({ hovered }) => (
                                <TouchableOpacity style={[styles.loadMore, {
                                    borderBottomWidth: hovered ? 1 : 0
                                }]} onPress={() => setFilters({ ...filters, page: filters.page + 1 })}>
                                    <MyAppText style={styles.loadMoreText}>{translate('loadMore')}...</MyAppText>
                                </TouchableOpacity>

                            )}
                        </Hoverable>
                        : null
                    }
                </ScrollView>
            </View>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        filterButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        filterButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        loadMoreText: {
            color: getThemedColor(theme, '#000028'),
            fontSize: 16,
        },
        loadMore: {
            borderColor: getThemedColor(theme, '#000028'),
            alignItems: 'center',
            justifyContent: 'center'
        },
        header: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 15,
            flexWrap: 'wrap',
            rowGap: 10,
            zIndex: 1
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B')
        },
        listHeader: {
            flexDirection: 'row',
            rowGap: 7
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
    });
}
