import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { PersonType } from '../../services/central-api/facial';
import { useAuth } from '../../context/Auth';

type Props = {
    zIndex: number;
    disabled?: boolean;
} & ({
    includeEmptyItem: true;
    value: PersonType | '';
    setValue: React.Dispatch<React.SetStateAction<PersonType | ''>>;
} | {
    includeEmptyItem?: false;
    value: PersonType;
    setValue: React.Dispatch<React.SetStateAction<PersonType>>;
});

function PersonTypeDropdown({ value, setValue, zIndex, disabled, includeEmptyItem }: Props) {
    const [items, setItems] = useState<{ label: string, value: string; }[]>([]);
    const { user } = useAuth();

    function getPersonType() {
        try {
            const personTypes: string[] = [];

            if (user?.isAdmin || user?.permissions.facial_module_access) {
                personTypes.push('wanted', 'missing', 'allow_list');
            } else {
                if (user?.permissions.manage_wanted_person) {
                    personTypes.push('wanted');
                }

                if (user?.permissions.manage_missing_person) {
                    personTypes.push('missing');
                }

                if (user?.permissions.manage_allow_list_person) {
                    personTypes.push('allow_list');
                }
            }

            const items = personTypes.map((personType) => {
                return {
                    value: personType,
                    label: translate(personType)
                };
            });

            if (includeEmptyItem) {
                items.unshift({
                    value: '',
                    label: ''
                });
            }
            setItems(items);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (user) {
            getPersonType();
        }
    }, [user]);

    return (
        <FilterDropdown
            label={translate('type')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value ? value : null}
            zIndex={zIndex}
            disabled={disabled}
        />
    );
}

export default PersonTypeDropdown;
