import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import FormActions from '../../../components/formActions';
import FormInput from '../../../components/formInput';
import { crmService } from '../../../services/central-api/crm';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';

interface Props {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    maintenanceRequestId?: number;
    onSubmit?: VoidFunction;
}

export default function MaintenanceRequestModal({ isVisible, setIsVisible, maintenanceRequestId, onSubmit }: Props) {
    const { styles, theme } = useStyles(stylesheet);

    const [reason, setReason] = useState('');
    const [observation, setObservation] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isVisible) {
            getMaintenanceRequest();
        }
    }, [isVisible, maintenanceRequestId]);

    async function getMaintenanceRequest() {
        if (!maintenanceRequestId) return;

        try {
            setIsLoading(true);

            const data = await crmService.getMaintenanceRequest(maintenanceRequestId);

            setReason(data.reason || '');
            setObservation(data.observation || '');
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }


    async function updateMaintenanceRequest() {
        if (!maintenanceRequestId) return;

        try {
            await crmService.updateMaintenanceRequest(maintenanceRequestId, {
                observation,
            });

            setIsVisible(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            visible={isVisible}
            animationType='fade'
            transparent
            onRequestClose={() => {
                setIsVisible(false);

                setObservation('');
                setReason('');
            }}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalComponent}>
                    <MyAppText style={styles.modalTitle}>{translate('maintenanceRequest')}</MyAppText>

                    {isLoading
                        ?
                        <ActivityIndicator size='small' color={theme.colors.loadingColor} /> :
                        <>
                            <FormInput
                                label={translate('reason')}
                                value={reason}
                                onChangeText={() => { }}
                                disabled={true}
                                multiline
                                numberOfLines={4}
                            />

                            <FormInput
                                label={translate('observation')}
                                value={observation}
                                onChangeText={(obs) => {
                                    setObservation(obs);
                                }}
                                invalid={(value: string) => {
                                    return value == '';
                                }}
                                multiline
                                numberOfLines={4}
                            />
                        </>

                    }

                    <FormActions
                        onSubmit={async () => {
                            await updateMaintenanceRequest();
                            onSubmit?.();
                        }}
                        onClose={() => {
                            setIsVisible(false);

                            setObservation('');
                            setReason('');

                        }}
                        disabled={isLoading || !observation}
                        confirmText={'resolve'}
                    />
                </View>
            </View>
        </Modal>
    );
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
        justifyContent: 'center',
        alignItems: 'center',
    },

    modalComponent: {
        minWidth: {
            xs: undefined,
            md: 450
        },
        minHeight: 150,
        width: {
            md: undefined,
            xs: '90%'
        },
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },

    modalTitle: {
        fontSize: 18,
        fontWeight: '600'
    },

    dateFieldsContainer: {
        flexDirection: 'row',
        gap: {
            xs: 10,
            md: 50
        },
        flexWrap: 'wrap'
    }
}));
