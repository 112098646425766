import React, { useState } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../MyAppText';
import MyDropDownPicker, { ValueType } from '../../MyDropDownPicker';
import { DropDownPickerProps } from 'react-native-dropdown-picker';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributiveOmit<T, K extends keyof any> = T extends any
    ? Omit<T, K>
    : never;

type Props = DistributiveOmit<DropDownPickerProps<ValueType>, 'open' | 'setOpen'>
    & { label?: string, onChange?: () => void; };

function FilterDropdown({ label, ...props }: Props) {
    const { styles, theme } = useStyles(stylesheet);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <View style={{ minWidth: 200, zIndex: props.zIndex }}>
            {label ? <MyAppText style={styles.filterText}>{label}</MyAppText> : null}
            <MyDropDownPicker
                {...props}
                borderColor={theme.colors.field.border}
                searchable={true}
                height={40}
                open={isOpen}
                setOpen={setIsOpen}
                dropDownDirection={'BOTTOM'}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        flexGrow: 1,
        minWidth: 200,
    },

    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));

export default FilterDropdown;
