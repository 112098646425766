import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import personDefaultPicture from '../../../assets/person-default-picture.png';
import { TextMask } from 'react-native-masked-text';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export function MpFaceDetectedOccurrence({ occurrence, navigation, imageUrl }: { occurrence: Occurrence; navigation: Navigation; imageUrl: string; }) {
    const { styles, theme } = useStyles(styleSheet);

    if (!occurrence.resume || !occurrence.id) {
        return <></>;
    }
    const resume = occurrence.resume as MpFaceDetectedResumeData;

    function PersonInfoInRow({ title, desc }: { title: string; desc: string | number; }) {
        return <View style={styles.personInfoRow}>
            <MyAppText style={styles.cardBoldText}>
                {title}:
            </MyAppText>
            <MyAppText style={styles.cardText}>
                {desc}
            </MyAppText>
        </View>;
    }

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 20 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: theme.colors.textColor, fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: theme.colors.textColor }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={theme.colors.iconColor} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('mpFaceDetectedAlert')}:
                </MyAppText>
                <MyAppText style={styles.alertName}>{resume.name}</MyAppText>
                <View style={styles.imageBox}>
                    <Image source={{ uri: imageUrl ?? personDefaultPicture }} style={styles.image} />
                </View>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('visualizedOn')}</MyAppText>
                    <View style={styles.visualizedRow}>
                        <FontAwesomeIcon icon={faLocationDot} color={theme.colors.iconColor} />
                        <MyAppText style={styles.cardText}>{resume.location}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <FontAwesomeIcon icon={faCalendarCheck} color={theme.colors.iconColor} />
                        <MyAppText style={styles.cardText}>{moment(resume.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                    </View>
                </View>
                <View>
                    {occurrence.id ? <PersonInfoInRow title={translate('occurrenceId')} desc={occurrence.id} /> : null}
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    {occurrence.narrative ? <PersonInfoInRow title={translate('narrative')} desc={occurrence.narrative} /> : null}
                </View>
                <View style={{ gap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('individualData')}:</MyAppText>
                    <View>
                        <View style={{ flexDirection: 'row', columnGap: 5 }}>
                            <MyAppText style={styles.cardBoldText}>CPF:</MyAppText>
                            <TextMask
                                style={[styles.cardText, { fontFamily: 'Open Sans' }]}
                                value={resume.document}
                                type={'cpf'}
                            />
                        </View>
                        <PersonInfoInRow title={translate('birth')} desc={resume.birth} />
                        <PersonInfoInRow title={translate('motherName')} desc={resume.motherName} />
                        <PersonInfoInRow title={translate('registeredOn')} desc={moment(resume.occurrenceDate).format('DD/MM/YYYY HH:mm:ss')} />
                    </View>
                </View>
                <View style={{ rowGap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('description')}:</MyAppText>
                    <MyAppText style={styles.cardText}>{resume.historic}</MyAppText>
                </View>
                <View style={{ rowGap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('systemData')}:</MyAppText>
                    <View>
                        <PersonInfoInRow title={translate('occurrenceNumber')} desc={resume.occurrenceNumber} />
                        <PersonInfoInRow title={translate('policeReportYear')} desc={resume.policeReportYear} />
                        <PersonInfoInRow title={translate('policeReportLocation')} desc={`${resume.occurrenceMunicipality}/${resume.occurrenceFederativeUnit}`} />
                        <PersonInfoInRow title={translate('occurrenceUnit')} desc={resume.occurrenceUnit} />
                        <PersonInfoInRow title={translate('situation')} desc={resume.situation} />
                        <PersonInfoInRow title={translate('state')} desc={resume.state} />
                    </View>
                </View>
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    card: {
        gap: 20,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 14,
        display: 'flex',
        alignItems: 'flex-start',
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    detectionInfoText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    text: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    alertText: {
        color: theme.colors.textColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    cardText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    cardBoldText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    alertName: {
        color: theme.colors.textColor,
        fontSize: 18,
        fontWeight: 'bold',
    },
    imageBox: {
        height: 314,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: 314,
        width: 311,
        objectFit: 'contain',
        borderRadius: 8,
        resizeMode: 'contain',
        overflow: 'hidden',
    },
    personInfoRow: {
        flexDirection: 'row',
        gap: 5,
        alignItems: 'flex-end'
    }
}));
