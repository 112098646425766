import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Modal } from 'react-native';
import MyAppText from '../../MyAppText';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';
import FormInput from '../../formInput';
import MyDropDownPicker from '../../MyDropDownPicker';
import FormActions from '../../formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import { ScrollView } from 'react-native-web-hover';

interface InvolvedObjectModalParameters {
    involvedObject: InvolvedObject;
    setInvolvedObject: (value: InvolvedObject) => void;
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    actingBodyId: number;
}

export default function InvolvedObjectModal({ involvedObject, setInvolvedObject, isModalVisible, setModalVisible, onSubmit, actingBodyId }: InvolvedObjectModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [isOpenObjectCategory, setIsOpenObjectCategory] = useState<boolean>(false);
    const [isOpenObjectSubCategory, setIsOpenObjectSubCategory] = useState<boolean>(false);
    const [selectableObjectsCategories, setSelectableObjectsCategories] = useState<ObjectCategory[]>([]);
    const [selectableObjectSubCategories, setSelectableObjectSubCategories] = useState<ObjectSubCategory[]>([]);

    const [amount, setAmount] = useState<string>('0');

    async function loadPageInfo() {
        const objectCategories = await dispatchService.getSimplifiedObjectsCategories(actingBodyId);
        setSelectableObjectsCategories(objectCategories);
        setAmount(String(involvedObject.amount));
        if (involvedObject.objectCategoryId) {
            await getObjectSubCategories(involvedObject.objectCategoryId);
        }
    }

    async function getObjectSubCategories(objectCategoryId: number) {
        const objectSubCategories = await dispatchService.getSimplifiedObjectSubCategories(objectCategoryId);
        setSelectableObjectSubCategories(objectSubCategories);
    }

    function isValidForm() {
        if (amount.trim() === '' || Number.isNaN(Number(amount))) {
            return false;
        }
        if (involvedObject.objectCategoryId === 0) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        loadPageInfo();
    }, [isModalVisible]);


    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                setSelectableObjectSubCategories([]);
                setModalVisible(false);
            }}
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                        <ScrollView>

                            <View style={{ rowGap: 20 }}>
                                <View style={[styles.row, { zIndex: 4 }]}>
                                    <View style={[{ gap: 5, zIndex: 3 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText style={styles.label}>{translate('vehicle_metadata_category')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableObjectsCategories.map((objectCategory) => {
                                                return {
                                                    value: objectCategory.id,
                                                    label: objectCategory.name
                                                };
                                            })}
                                            multiple={false}
                                            value={involvedObject.objectCategoryId}
                                            setValue={async (value) => {
                                                const objectCategoryId = value(involvedObject.objectCategoryId);
                                                setInvolvedObject({
                                                    ...involvedObject,
                                                    objectCategoryId,
                                                    objectCategory: selectableObjectsCategories.find(objectCategory => objectCategory.id == objectCategoryId)
                                                });
                                                await getObjectSubCategories(value(involvedObject.objectCategoryId));
                                            }}
                                            setOpen={setIsOpenObjectCategory}
                                            open={isOpenObjectCategory}
                                            zIndex={3}
                                        />
                                    </View>
                                    <View style={[{ gap: 5, zIndex: 2 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText style={styles.label}>{translate('subCategory')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableObjectSubCategories.map((objectSubCategory) => {
                                                return {
                                                    value: objectSubCategory.id,
                                                    label: objectSubCategory.name
                                                };
                                            })}
                                            multiple={false}
                                            value={involvedObject.objectSubCategoryId || null}
                                            setValue={(value) => {
                                                const objectSubCategoryId = value(involvedObject.objectSubCategoryId);
                                                setInvolvedObject({
                                                    ...involvedObject,
                                                    objectSubCategoryId,
                                                    objectSubCategory: selectableObjectSubCategories.find(objectSubCategory => objectSubCategory.id == objectSubCategoryId)
                                                });
                                            }}
                                            setOpen={setIsOpenObjectSubCategory}
                                            open={isOpenObjectSubCategory}
                                            zIndex={2}
                                        />
                                    </View>
                                    <FormInput
                                        label={translate('amount')}
                                        placeholder={translate('amount')}
                                        value={amount}
                                        onChangeText={setAmount}
                                        invalid={() => false}
                                        viewStyle={[{ flex: 1 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}
                                    />
                                </View>
                                <View style={styles.row}>
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={involvedObject.description ?? ''}
                                        onChangeText={(value) => setInvolvedObject({ ...involvedObject, description: value })}
                                        invalid={() => false}
                                        viewStyle={{ flex: 1 }}
                                    />
                                </View>
                                <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                                    <FormActions
                                        onSubmit={() => {
                                            involvedObject.amount = Number(amount);
                                            onSubmit();
                                            setSelectableObjectSubCategories([]);
                                            setModalVisible(false);
                                        }}
                                        onClose={() => {
                                            setSelectableObjectSubCategories([]);
                                            setModalVisible(false);
                                        }}
                                        disabled={!isValidForm()} />
                                </View>
                            </View>
                        </ScrollView>

                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        videoNotReadyText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 560,
            padding: 30,
        },
        row: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            columnGap: 30,
            minHeight: 'auto',
            rowGap: 20
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        }
    });
}
