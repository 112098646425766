import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { dispatchService } from '../../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import ActingBodiesDropdown from './ActingBodiesDropdown';

interface ModalProps {
    isModalVisible: boolean;
    onClose: () => void;
    assignmentId?: number;
    authenticatedUser?: AuthenticatedUser;
}

export default function AssignmentModal({ onClose, assignmentId, authenticatedUser, isModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);

    async function getAssignment(id: number) {
        const assignment = await dispatchService.getAssignment(id);
        setName(assignment.name);
        setDescription(assignment.description);
        setActingBodyId(assignment.actingBodyId);
    }

    async function loadPageInfo() {
        try {
            if (assignmentId) {
                await getAssignment(assignmentId);
            }
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function clearValues() {
        setName('');
        setDescription('');
        setActingBodyId(0);
    }

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    function isEmpty(value: string) {
        return value.trim() == '';
    }

    function isFormValid() {
        return !isEmpty(name);
    }

    async function formSubmit() {
        try {
            if (assignmentId) {
                await dispatchService.updateAssignment({
                    id: assignmentId,
                    name,
                    description
                });
            } else {
                await dispatchService.createAssignment({
                    name,
                    description,
                    actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
                });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onClose();
                clearValues();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 635 }]}>
                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_assignments) &&
                        <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={2} disabled={!!assignmentId} />
                    }
                    <FormInput
                        label={translate('name')}
                        placeholder={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isEmpty}
                    />
                    <FormInput
                        label={translate('description')}
                        placeholder={translate('description')}
                        value={description}
                        onChangeText={setDescription}
                        invalid={() => false}
                    />
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View >
            </View>
        </Modal >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
