import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { facialService } from '../../services/central-api/facial';
import { translate } from '../../services/translate';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    zIndex: number;
}

function TagsDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getTags() {
        try {
            const tags = await facialService.getFacialMarkers();
            setItems(tags.map((tag) => {
                return {
                    value: tag.id || 0,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getTags();
    }, []);

    return (
        <FilterDropdown
            label={translate('tags')}
            items={items}
            setValue={setValue}
            value={value}
            multiple={true}
            mode='BADGE'
            zIndex={zIndex}
        />
    );
}

export default TagsDropdown;
