import React from 'react';
import { View, Modal } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import CustomButton from './CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { WindowInformation } from '../services/window-information';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    onCancel: () => void;
    userName: string;
    customConfirmText?: string;
}

export default function ConflictUpdateModal({ isModalVisible, setModalVisible, onSubmit, onCancel, userName, customConfirmText }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onCancel();
                setModalVisible(false);
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.body, windowInfo.isMobile ? { width: '95%', height: 215 } : { width: 565, height: 200 }]}>
                    <MyAppText style={styles.headerText}>{translate('unableToUpdate')}</MyAppText>
                    <MyAppText>{translate('RecordNotLatestValues', { userName })}</MyAppText>
                    <View style={styles.buttonsView}>
                        <CustomButton
                            onPress={() => {
                                onCancel();
                                setModalVisible(false);
                            }}
                            text={translate('cancel')}
                            type='secondary' />
                        <CustomButton
                            onPress={() => {
                                onSubmit();
                                setModalVisible(false);
                            }}
                            text={translate(customConfirmText ?? 'refresh')} />
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    body: {
        backgroundColor: theme.colors.cardBackground,
        flexDirection: 'column',
        borderRadius: 4,
        padding: 25,
        rowGap: 20
    },
    headerText: {
        fontSize: 20,
    },
    buttonsView: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: 20
    },
}));
