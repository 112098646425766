import React, { useState, useEffect } from 'react';
import { View, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import MyAppText from '../../../components/MyAppText';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { MosaicPreset, cameraService } from '../../../services/central-api/cameras';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import { WindowInformation } from '../../../services/window-information';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface ModalProps {
    isModalVisible: boolean;
    onClose: () => void;
    mosaic?: Mosaic;
}

export default function MosaicEditModal({ onClose, mosaic, isModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [selectableCameras, setSelectableCameras] = useState<SelectableItem[]>([]);
    const [selectedCameras, setSelectedCameras] = useState<SelectableItem[]>([]);
    const [title, setTitle] = useState(mosaic?.title || '');
    const [preset, setPreset] = useState<MosaicPreset>(mosaic?.preset || MosaicPreset['4_PRESET']);
    const [selectablePresetsFilter, setSelectablePresetsFilter] = useState<{ label: string, value: string; }[]>([]);
    const [selectableTagsFilter, setSelectableTagsFilter] = useState<{ label: string, value: number; }[]>([]);
    const [isTagPickerOpen, setIsTagPickerOpen] = useState(false);
    const [isPresetPickerOpen, setIsPresetPickerOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [displayFilter, setDisplayFilter] = useState<string[]>([]);
    const [cameras, setCameras] = useState<AccessSimplifiedCameraData[]>([]);

    function cleanValues() {
        setTitle('');
        setPreset(MosaicPreset['4_PRESET']);
        setCameras([]);
        setSelectedTags([]);
        setIsTagPickerOpen(false);
        setIsPresetPickerOpen(false);
        setSelectedCameras([]);
        setSelectableCameras([]);
    }

    async function getMosaic(id: string, cameras: AccessSimplifiedCameraData[]) {
        try {
            const mosaicRes = await cameraService.getMosaic(id);

            const cameraIdList: string[] = [];
            for (const camera of mosaicRes.cameras) {
                if (camera.cameraId) {
                    cameraIdList.push(camera.cameraId);
                }
            }

            const selectedCameras = cameras.filter(camera => {
                return mosaicRes.cameras.find(cam => {
                    return cam.cameraId == camera.id;
                });
            }).map((camera) => {
                return {
                    id: camera.id,
                    name: camera.title
                };
            });
            setSelectedCameras(selectedCameras);
            setSelectableCameras(cameras
                .filter((camera) => !selectedCameras.find((selected) => selected.id == camera.id))
                .map((camera) => {
                    return {
                        id: camera.id,
                        name: camera.title
                    };
                })
            );
        } catch (err) {
            console.error(err);
        }
    }

    function getPresetsOptions() {
        const mosaicPresets: { label: string, value: MosaicPreset | ''; }[] = Object.keys(MosaicPreset).map((item) => {
            const mosaicPreset = MosaicPreset[item as keyof typeof MosaicPreset];
            return {
                label: translate(item),
                value: mosaicPreset
            };
        });
        setSelectablePresetsFilter(mosaicPresets);
    }

    async function loadPageInfo() {
        try {
            const tags = await cameraService.getCameraTagsSimplified();
            const cameras = await cameraService.getCamerasSimplified({});
            getPresetsOptions();

            setSelectableTagsFilter(tags.map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name
                };
            }));
            setCameras(cameras);
            setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));

            if (mosaic?.id) {
                await getMosaic(mosaic.id, cameras);
            } else {
                setSelectableCameras(cameras.map((camera) => {
                    return {
                        id: camera.id || 0,
                        name: camera.title
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, [isModalVisible]);

    useEffect(() => {
        if (!selectableTagsFilter || !selectedTags || !selectableCameras || !selectedCameras || !cameras) {
            return;
        }

        if (selectedTags.length == 0) {
            return setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));
        }

        setDisplayFilter(cameras.filter((camera: AccessSimplifiedCameraData) => {
            return camera.tagList?.some(tag => selectedTags.includes(tag));
        }).map(camera => {
            return camera.id;
        }));
    }, [selectedTags]);


    async function formSubmit() {
        try {
            if (mosaic?.id) {
                mosaic.title = title;
                mosaic.preset = preset;
                await cameraService.updateMosaic(mosaic, selectedCameras.map(camera => {
                    return String(camera.id);
                }));
            } else {
                await cameraService.createMosaic({
                    title: title,
                    camerasToAdd: selectedCameras.map(camera => {
                        return String(camera.id);
                    }),
                    preset
                });
            }
            cleanValues();
            onClose();
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function isTitleInvalid(value: string) {
        return value == '';
    }

    function isFormValid(): boolean {
        return !isTitleInvalid(title);
    }

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                cleanValues();
                onClose();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                    <View style={{ flexDirection: 'row', zIndex: 3, columnGap: 10 }}>
                        <View style={{ flexGrow: 1 }}>
                            <FormInput
                                label={translate('title')}
                                placeholder={translate('title')}
                                value={title}
                                onChangeText={setTitle}
                                invalid={isTitleInvalid}
                            />
                        </View>
                        <View style={{ rowGap: 5, minWidth: 140 }}>
                            <MyAppText>{translate('model')}</MyAppText>
                            <MyDropDownPicker
                                open={isPresetPickerOpen}
                                value={preset}
                                items={selectablePresetsFilter}
                                setOpen={setIsPresetPickerOpen}
                                setValue={setPreset}
                                searchable={false}
                            />
                        </View>
                    </View>
                    <View style={{ flex: 1, rowGap: 15 }}>
                        <View style={styles.camerasTagsContainer}>
                            <MyAppText style={styles.camerasTagsTitle}>{translate('tags')}</MyAppText>
                            <View style={styles.dropdownStructure}>
                                <MyDropDownPicker
                                    placeholder={translate('filter')}
                                    open={isTagPickerOpen}
                                    items={selectableTagsFilter}
                                    setOpen={setIsTagPickerOpen}
                                    value={selectedTags}
                                    setValue={setSelectedTags}
                                    multiple={true}
                                    mode='BADGE'
                                    searchable={true}
                                />
                            </View>
                        </View>
                        <SelectableList
                            displayFilter={displayFilter}
                            selectableLabel={translate('availableCameras')}
                            selectableContent={selectableCameras}
                            selectedLabel={translate('selectedCameras')}
                            selectedContent={selectedCameras}
                        />
                    </View>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            cleanValues();
                            onClose();
                        }}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        maxWidth: 850,
        maxHeight: 650,
        backgroundColor: theme.colors.cardBackground,
        borderRadius: 4,
        padding: 20,
        rowGap: 15
    },
    camerasContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    camerasTagsContainer: {
        flexDirection: 'row',
        zIndex: 1,
        minHeight: 40,
        columnGap: 20,
        alignItems: 'center'
    },
    camerasTagsTitle: {
        color: theme.colors.labelColor,
        fontSize: 14,
        height: 25,
        fontWeight: 'bold',
    },
    dropdownStructure: {
        flex: 1
    },
}));
