import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';
import Toast from 'react-native-toast-message';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import MyAppText from '../../../components/MyAppText';
import { Action, auditLogsService } from '../../../services/central-api/audit-logs';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import MyRadio from '../../../components/MyRadio';
import 'react-calendar/dist/Calendar.css';
import '../../../styles/date-ranger-picker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
const regex = /^[a-zA-Z0-9 +\-./:=_ãõẽíáàâêóôúüçÃÕẼÍÁÀÂÊÓÔÚÜÇ]*$/;

export default function ReportModal({ onClose, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<ValuePiece>(new Date());
    const [dateTo, setDateTo] = useState<ValuePiece>(new Date());
    const [isActionsPickerOpen, setIsActionsPickerOpen] = useState<boolean>(false);
    const [selectableActionsFilter, setSelectableActionsFilter] = useState<{ label: string, value: Action | ''; }[]>([]);
    const [selectedActionFilter, setSelectedActionFilter] = useState<Action | ''>('');
    const [isUsersPickerOpen, setIsUsersPickerOpen] = useState<boolean>(false);
    const [selectableUsersFilter, setSelectableUsersFilter] = useState<{ label: string, value: number; }[]>([]);
    const [selectedUserFilter, setSelectedUserFilter] = useState<number>(0);
    const [isPdf, setIsPdf] = useState<boolean>(true);

    async function getUsers() {
        try {
            const users = await auditLogsService.getAllUsers();
            setSelectableUsersFilter([{
                label: translate('masculineAll'),
                value: 0
            }, {
                label: translate('systemGenerated'),
                value: -1
            }, ...users.map((user) => {
                return {
                    label: user.name,
                    value: user.id
                };
            })]);
        } catch (err) {
            console.error(err);
        }
    }

    function getActions() {
        const actions: { label: string, value: Action | ''; }[] = Object.keys(Action).map((item) => {
            const action = Action[item as keyof typeof Action];
            return {
                label: translate(item),
                value: action
            };
        });
        actions.push({ label: translate('all'), value: '' });
        setSelectableActionsFilter(actions.sort((a, b) => b.label > a.label ? -1 : 1));
    }

    function isNameInvalid(value: string) {
        return value == '' || !regex.test(value);
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    function onCloseNewReportModal() {
        setModalVisible(false);
        resetReportForm();
    }

    async function formSubmit() {
        try {
            if (!dateTo || !dateFrom) {
                return;
            }
            dateTo.setHours(23, 59, 59);
            dateFrom.setHours(0, 0, 0);
            await auditLogsService.createReport({
                name: name,
                action: selectedActionFilter ? selectedActionFilter : undefined,
                userId: selectedUserFilter !== 0 ? selectedUserFilter : undefined,
                startDate: dateFrom.toISOString(),
                endDate: dateTo.toISOString()
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            onCloseNewReportModal();
            onClose();
        }
    }

    function resetReportForm() {
        setName('');
        setIsPdf(true);
    }

    useEffect(() => {
        if (isModalVisible) {
            getActions();
            getUsers();
        }
    }, [isModalVisible]);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => onCloseNewReportModal()} >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 400 }]}>
                    <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: theme.colors.labelColor }}>{translate('logsReport')}</MyAppText>
                    <FormInput
                        label={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isNameInvalid}
                        errorMessage={translate('invalidCharacters')}
                    />
                    <View style={{ maxWidth: 240, zIndex: 4 }}>
                        <MyAppText style={styles.filterText}>{translate('period')}</MyAppText>
                        <DateRangePicker
                            calendarProps={{ locale: 'pt-BR' }}
                            maxDate={moment().toDate()}
                            onChange={(value: Value) => {
                                if (Array.isArray(value)) {
                                    setDateFrom(value[0]);
                                    setDateTo(value[1]);
                                }
                            }}
                            autoFocus={false}
                            shouldOpenCalendar={({ reason }) => reason !== 'focus'}
                            value={[dateFrom, dateTo]}
                            clearIcon={null} locale='pt-BR'
                            className={UnistylesRuntime.themeName === 'light' ? 'react-daterange-picker__wrapper-light' : 'react-daterange-picker__wrapper-dark'}
                        />
                    </View>
                    <View style={{ gap: 5 }}>
                        <MyAppText style={styles.filterText}>{translate('type')}</MyAppText>
                        <MyRadio
                            selected={isPdf}
                            setSelected={setIsPdf}
                            options={[{ value: true, label: 'PDF' }, { value: false, label: 'XLSX' }]}
                        />
                    </View>
                    <View style={{ zIndex: 3 }}>
                        <MyAppText style={styles.filterText}>{translate('actions')}</MyAppText>
                        <MyDropDownPicker
                            open={isActionsPickerOpen}
                            value={selectedActionFilter}
                            items={selectableActionsFilter}
                            setOpen={setIsActionsPickerOpen}
                            setValue={setSelectedActionFilter}
                            onOpen={() => {
                                setIsUsersPickerOpen(false);
                            }}
                            searchable={true}
                            borderColor={theme.colors.cardDetailText}
                            zIndex={3}
                        />
                    </View>
                    <View style={{ zIndex: 2 }}>
                        <MyAppText style={styles.filterText}>{translate('users')}</MyAppText>
                        <MyDropDownPicker
                            open={isUsersPickerOpen}
                            value={selectedUserFilter}
                            items={selectableUsersFilter}
                            setOpen={setIsUsersPickerOpen}
                            setValue={setSelectedUserFilter}
                            onOpen={() => {
                                setIsActionsPickerOpen(false);
                            }}
                            searchable={true}
                            borderColor={theme.colors.cardDetailText}
                            zIndex={2}
                        />
                    </View>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => onCloseNewReportModal()}
                        // isLoading={isDownloading}
                        disabled={!isFormValid()}
                        confirmText={'download'} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 15,
        maxHeight: 690
    },
    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));
