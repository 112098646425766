import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../../services/window-information';
import MyAppText from '../../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../components/DeleteModal';
import { ClientError } from '../../../services/central-api/base-service';
import { dispatchService } from '../../../services/central-api/dispatch';
import { ScrollView } from 'react-native-web-hover';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomTextInput from '../../../components/CustomTextInput';
import ActingBodiesDropdown from './ActingBodiesDropdown';

interface ModalProps {
    isModalVisible: boolean;
    onClose: () => void;
    objectCategoryId?: number;
    authenticatedUser?: AuthenticatedUser;
}

export default function ObjectCategoryModal({ onClose, objectCategoryId, authenticatedUser, isModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [objectSubCategories, setObjectSubCategories] = useState<ObjectSubCategory[]>([{ name: '' }]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedObjectSubCategoryForDeletion, setSelectedObjectSubCategoryForDeletion] = useState<ObjectSubCategory>();

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    function clearValues() {
        setName('');
        setActingBodyId(0);
        setObjectSubCategories([]);
    }

    async function deleteObjectSubCategory(objectSubCategory: ObjectSubCategory) {
        try {
            if (!objectSubCategory.id) {
                return;
            }
            await dispatchService.deleteObjectSubCategory(objectSubCategory.id);
            const index = objectSubCategories.findIndex(r => r.id == objectSubCategory.id);
            objectSubCategories.splice(index, 1);
            setObjectSubCategories([...objectSubCategories]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getObjectCategory(objectCategoryId: number) {
        try {
            const objectCategory = await dispatchService.getObjectCategory(objectCategoryId);
            setName(objectCategory.name);
            setActingBodyId(objectCategory.actingBodyId);
            setObjectSubCategories(objectCategory.objectSubCategories || []);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (objectCategoryId && isModalVisible) {
            getObjectCategory(objectCategoryId);
        }
    }, [objectCategoryId, isModalVisible]);

    async function formSubmit() {
        try {
            if (objectCategoryId) {
                await dispatchService.updateObjectCategory({
                    id: objectCategoryId,
                    name,
                    objectSubCategories: objectSubCategories.filter(osc => osc.name !== ''),
                });
            } else {
                await dispatchService.createObjectCategory({
                    name,
                    objectSubCategories: objectSubCategories.filter(osc => osc.name !== ''),
                    actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
                });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onClose();
                clearValues();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_objects_categories) &&
                        <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={2} disabled={!!objectCategoryId} />
                    }
                    <FormInput
                        label={translate('name')}
                        placeholder={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isNameInvalid}
                    />
                    <View style={{ gap: 8, maxHeight: 400 }}>
                        <MyAppText>{translate('objectSubCategories')}</MyAppText>
                        <ScrollView contentContainerStyle={{ gap: 5 }}>
                            {objectSubCategories.map((objectSubCategory, index) => (
                                <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                    <CustomTextInput
                                        placeholder={'subCategory'}
                                        value={objectSubCategory.name}
                                        onChangeText={(value) => {
                                            objectSubCategories[index].name = value;
                                            setObjectSubCategories([...objectSubCategories]);
                                        }} />
                                    <TouchableOpacity onPress={() => {
                                        if (objectSubCategories[index].id) {
                                            setSelectedObjectSubCategoryForDeletion(objectSubCategories[index]);
                                            setIsDeleteModalVisible(true);
                                            return;
                                        }
                                        objectSubCategories.splice(index, 1);
                                        setObjectSubCategories([...objectSubCategories]);
                                    }}>
                                        <FontAwesomeIcon fontSize={24} icon={faTimes} color={theme.colors.iconColor} />
                                    </TouchableOpacity>
                                </View>))
                            }
                        </ScrollView>
                        <TouchableOpacity onPress={() => {
                            objectSubCategories.push({ name: '' });
                            setObjectSubCategories([...objectSubCategories]);
                        }}>
                            <MyAppText>+ {translate('addObjectSubCategory')}</MyAppText>
                        </TouchableOpacity>
                    </View>


                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View>

                <DeleteModal
                    setModalVisible={setIsDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={selectedObjectSubCategoryForDeletion?.name}
                    onSubmit={async () => {
                        if (selectedObjectSubCategoryForDeletion) {
                            await deleteObjectSubCategory(selectedObjectSubCategoryForDeletion);
                        }
                    }}
                    onCancel={() => {
                        setSelectedObjectSubCategoryForDeletion(undefined);
                    }}
                />
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
