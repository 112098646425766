import React, { useEffect, useState } from 'react';
import { ScrollView, View, ActivityIndicator, TouchableOpacity, Switch, Image } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import MyRadio from '../../../components/MyRadio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import DisableEnableModal from './DisableEnableModal';
import Tooltip from '../../../components/Tooltip';
import Toast from 'react-native-toast-message';
import { useNavigationState } from '@react-navigation/native';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { DuplicatedCameraValue, crmService } from '../../../services/central-api/crm';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import DeleteModal from '../../../components/DeleteModal';
import FormInput from '../../../components/formInput';
import PasswordInput from '../../../components/passwordInput';
import { ClientError } from '../../../services/central-api/base-service';
import CustomButton from '../../../components/CustomButton';
import AlertGroupsDropdown from '../../../components/Dropdowns/AlertGroupsDropdown';
import PasswordModal from './PasswordModal';
import { useAuth } from '../../../context/Auth';
import InstallationCompanyDropdown from './installationCompanyDropdown';
import InternetCompanyDropdown from './InternetCompanyDropdown';

interface ManagementDetailsParameters {
    navigation: Navigation;
    route: {
        params?: {
            id: string;
        };
    };
}

export default function ManagementDetails({ navigation, route }: ManagementDetailsParameters) {
    const { styles, theme } = useStyles(styleSheet);

    const { user: authenticatedUser } = useAuth();

    const index = useNavigationState(state => state);
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [canEditFields, setCanEditFields] = useState(false);
    const [isPasswordModalVisible, setPasswordModalVisible] = useState(false);
    const [isDisableEnableModalVisible, setDisableEnableModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [isCameraTypePickerOpen, setIsCameraTypePickerOpen] = useState<boolean>(false);
    const [selectableCamerasTypeFilter, setSelectableCamerasTypeFilter] = useState<{ label: string, value: CameraType; }[]>([]);

    // Third party cameras cannot be edited
    const [isThirdParty, setIsThirdParty] = useState<boolean>(false);

    // fields
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [httpAddress, setHttpAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [type, setType] = useState<CameraType | ''>('');
    const [internetType, setInternetType] = useState<InternetType>('4g');
    const [installationCompanyId, setInstallationCompanyId] = useState(0);
    const [internetCompanyId, setInternetCompanyId] = useState(0);
    const [selectedAlertGroupId, setSelectedAlertGroupId] = useState<number>(0);
    const [rtspAddress, setRtspAddress] = useState<string>('');
    const [installer, setInstaller] = useState('');
    const [installedAmountCameras, setInstalledAmountCameras] = useState('');
    const [networkType, setNetworkType] = useState<NetworkType>('ipv6');
    const [sourceTech, setSourceTech] = useState<SourceTech>('rtsp');
    const [hasLpr, setHasLpr] = useState(false);
    const [hasFacial, setHasFacial] = useState(false);
    const [hasAreaInvasion, setHasAreaInvasion] = useState(false);
    const [ptzUsername, setPtzUsername] = useState('admin');
    const [ptzPassword, setPtzPassword] = useState('Tecvoz12');
    const [cameraPassword, setCameraPassword] = useState('');

    const [disabledAt, setDisabledAt] = useState<Date | null>(null);
    const [initialSourceTech, setInitialSourceTech] = useState<SourceTech>('rtsp');

    async function getCamera(id: string) {
        try {
            setIsLoading(true);
            const camera = await crmService.getCamera(id);
            setTitle(camera.title);
            setAddress(camera.address);
            setHttpAddress(camera.httpAddress || '');
            setLatitude(String(camera.latitude));
            setLongitude(String(camera.longitude));
            setSerialNo(camera.serialNo || '');
            setHasFacial(camera.hasFacial);
            setHasLpr(camera.hasLpr);
            setHasAreaInvasion(camera.hasAreaInvasion);
            setInternetType(camera.internetType);
            setInstaller(camera.installer);
            setInstalledAmountCameras(String(camera.installedAmountCameras));
            setNetworkType(camera.networkType);
            setRtspAddress(camera.rtspAddress ?? '');
            setDisabledAt(camera.disabledAt);
            setSourceTech(camera.sourceTech || 'rtsp');
            setInitialSourceTech(camera.sourceTech || 'rtsp');
            setPtzUsername(camera.ptzConfiguration?.username || '');
            setPtzPassword(camera.ptzConfiguration?.password || '');
            setInstallationCompanyId(camera.installationCompanyId || 0);
            setInternetCompanyId(camera.internetCompanyId || 0);
            setType(camera.type);
            setIsThirdParty(camera.thirdPartyCamera ? true : false);
            setSelectedAlertGroupId(camera.alertGroup?.id ?? 0);
        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (authenticatedUser) {
            setCanEditFields(authenticatedUser.isAdmin || authenticatedUser.permissions.crm_module_access);
        }
    }, [authenticatedUser]);

    useEffect(() => {
        setSelectableCamerasTypeFilter([
            { label: translate('ptz'), value: 'ptz' },
            { label: translate('lpr'), value: 'lpr' },
            { label: translate('pinned'), value: 'pinned' },
            { label: translate('panoramic'), value: 'panoramic' }
        ]);
        if (route.params?.id && !alreadyLoaded) {
            getCamera(route.params.id);
        }
    }, [route.params]);

    useEffect(() => {
        if (title === '') {
            return setIsFormValid(false);
        } else if (address === '') {
            return setIsFormValid(false);
        } else if (installer === '' && !route.params?.id) {
            return setIsFormValid(false);
        } else if (serialNo === '' && !route.params?.id) {
            return setIsFormValid(false);
        } else if (installedAmountCameras.trim() === '' || Number.isNaN(Number(installedAmountCameras)) || Number(installedAmountCameras) < 1) {
            return setIsFormValid(false);
        } else if ((rtspAddress === '' || rtspAddress == undefined) && networkType === 'ipv4') {
            return setIsFormValid(false);
        } else if (latitude.trim() === '' || Number.isNaN(Number(latitude))) {
            return setIsFormValid(false);
        } else if (longitude.trim() === '' || Number.isNaN(Number(longitude))) {
            return setIsFormValid(false);
        } else if (type === '') {
            return setIsFormValid(false);
        } else if ((httpAddress === '' || httpAddress == undefined) && networkType === 'ipv4') {
            return setIsFormValid(false);
        } else if ((ptzUsername === '' || ptzUsername == undefined) && type === 'ptz') {
            return setIsFormValid(false);
        } else if ((ptzPassword === '' || ptzPassword == undefined) && type === 'ptz') {
            return setIsFormValid(false);
        } else if (Number.isNaN(Number(installationCompanyId)) || installationCompanyId == 0) {
            return setIsFormValid(false);
        } else if (Number.isNaN(Number(internetCompanyId)) || internetCompanyId == 0) {
            return setIsFormValid(false);
        } else if (hasAreaInvasion && selectedAlertGroupId == 0) {
            return setIsFormValid(false);
        }

        return setIsFormValid(true);
    }, [title,
        address,
        latitude,
        longitude,
        installer,
        installedAmountCameras,
        rtspAddress,
        networkType,
        serialNo,
        type,
        httpAddress,
        ptzUsername,
        ptzPassword,
        installationCompanyId,
        internetCompanyId,
        hasAreaInvasion,
        hasFacial,
        selectedAlertGroupId
    ]);

    async function save() {
        try {
            if (!type) {
                return;
            }
            setIsLoading(true);
            if (route.params?.id) {
                await crmService.updateCamera(route.params.id, {
                    title,
                    address,
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                    serialNo,
                    hasFacial,
                    hasLpr,
                    hasAreaInvasion,
                    internetType,
                    installer,
                    installationCompanyId: installationCompanyId,
                    internetCompanyId: internetCompanyId,
                    alertGroupId: selectedAlertGroupId > 0 ? selectedAlertGroupId : null,
                    sourceTech: sourceTech,
                    rtspAddress: networkType === 'ipv4' && sourceTech == 'rtsp' ? rtspAddress : undefined,
                    httpAddress: networkType === 'ipv4' ? httpAddress : undefined,
                    networkType,
                    installedAmountCameras: Number(installedAmountCameras),
                    ptzConfiguration: type == 'ptz' ? {
                        address: networkType === 'ipv4' ? httpAddress.replace('http://', '').split(':')[0] : undefined,
                        port: networkType === 'ipv4' ? Number(httpAddress.replace('http://', '').split(':')[1] ?? 80) : 80,
                        username: ptzUsername,
                        password: ptzPassword
                    } : undefined
                });
            } else {
                await crmService.createCamera({
                    title,
                    address,
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                    serialNo,
                    hasFacial,
                    hasLpr,
                    hasAreaInvasion,
                    type,
                    internetType,
                    installationCompanyId: installationCompanyId,
                    internetCompanyId: internetCompanyId,
                    alertGroupId: selectedAlertGroupId > 0 ? selectedAlertGroupId : null,
                    rtspAddress: networkType === 'ipv4' && sourceTech == 'rtsp' ? rtspAddress : undefined,
                    httpAddress: networkType === 'ipv4' ? httpAddress : undefined,
                    installer,
                    networkType,
                    installedAmountCameras: Number(installedAmountCameras),
                    ptzConfiguration: type == 'ptz' ? {
                        address: networkType === 'ipv4' ? httpAddress.replace('http://', '').split(':')[0] : '',
                        port: networkType === 'ipv4' ? Number(httpAddress.replace('http://', '').split(':')[1] ?? 80) : 80,
                        username: ptzUsername,
                        password: ptzPassword
                    } : undefined
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            if (index.routes.length > 1) {
                return navigation.goBack();
            } else {
                window.location.href = '/CRM/cameras';
            }
        } catch (err) {
            if (err instanceof DuplicatedCameraValue) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message, { cameraId: err.cameraId }),
                    visibilityTime: 10000,
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function getCameraPassword() {
        try {
            setPasswordModalVisible(true);

            const result = await crmService.getCameraPassword(route.params?.id || '');
            setCameraPassword(result.password);
        } catch (err) {

            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.headerContent}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        <TouchableOpacity onPress={() => {
                            if (index.routes.length > 1) {
                                return navigation.goBack();
                            } else {
                                window.location.href = '/CRM/cameras';
                            }
                        }}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                        </TouchableOpacity>
                        <MyAppText style={{ fontSize: 24, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                            {translate('camera')}
                        </MyAppText>
                    </View>
                    {canEditFields && route.params?.id && !isThirdParty ?
                        <CustomButton
                            text={translate('delete')}
                            onPress={() => {
                                setDeleteModalVisible(true);
                            }}
                            style={{ borderColor: '#FE0127' }}
                            type='secondary'
                            textStyle={{ color: '#FE0127' }}
                        /> : null}
                    {canEditFields && route.params?.id && !isThirdParty ?
                        <CustomButton
                            text={disabledAt !== null ? translate('enable') : translate('disable')}
                            onPress={() => {
                                setDisableEnableModalVisible(true);
                            }}
                            style={{ backgroundColor: disabledAt !== null ? '#008E2C' : '#FE0127' }}
                        /> : null}
                    {(authenticatedUser?.permissions.get_camera_password || authenticatedUser?.isAdmin) && route.params?.id ?
                        <CustomButton text={translate('getCameraPassword')} onPress={getCameraPassword} type='secondary' />
                        : null}
                    {route.params?.id && hasLpr && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.lpr_module_access) ?
                        <CustomButton
                            text={translate('watchLprEvents')}
                            onPress={() => {
                                navigation.navigate('LPR', { screen: 'LprLiveEvents', params: { cameras: [route.params?.id] } });
                            }}
                            type='secondary'
                        /> : null}
                    {route.params?.id && hasFacial && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.facial_module_access) ?
                        <CustomButton
                            text={translate('watchFacialEvents')}
                            onPress={() => {
                                navigation.navigate('Facial', { screen: 'LiveEvents', params: { cameras: [route.params?.id] } });
                            }}
                            type='secondary'
                        /> : null}
                    {route.params?.id ?
                        <CustomButton
                            text={translate('watchCamera')}
                            onPress={() => {
                                window.open(`/cameras/map/${route.params?.id}`);
                            }}
                            type='secondary'
                        /> : null
                    }
                    {canEditFields ?
                        <CustomButton text={translate('save')} onPress={save} disabled={!isFormValid} />
                        : null
                    }
                </View>
                <View style={styles.cardList}>
                    <ScrollView style={{ padding: 24 }} contentContainerStyle={{ rowGap: 15 }}>
                        {isLoading &&
                            <ActivityIndicator size='small' color={theme.colors.iconColor} />
                        }
                        {isThirdParty ?
                            <MyAppText style={{ fontSize: 12 }}>{translate('thirdPartyCameraWarning')}</MyAppText> : null
                        }
                        <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row', columnGap: 40, rowGap: 10 }}>
                            <View style={{ rowGap: 20, maxWidth: 400, width: '100%' }}>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('title')}
                                        placeholder=''
                                        value={title}
                                        disabled={!canEditFields || isThirdParty}
                                        onChangeText={setTitle}
                                        invalid={() => title === ''}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('address')}
                                        placeholder=''
                                        value={address}
                                        disabled={!canEditFields || isThirdParty}
                                        onChangeText={setAddress}
                                        invalid={() => address === ''}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('latitude')}
                                        placeholder=''
                                        value={latitude}
                                        disabled={!canEditFields || isThirdParty}
                                        onChangeText={setLatitude}
                                        invalid={() => latitude === ''}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('longitude')}
                                        placeholder=''
                                        value={longitude}
                                        disabled={!canEditFields || isThirdParty}
                                        onChangeText={setLongitude}
                                        invalid={() => longitude === ''}
                                    />
                                </View>
                                <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                    <View style={{ width: '95%' }}>
                                        <FormInput
                                            label={translate('serialNo')}
                                            placeholder=''
                                            value={serialNo}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setSerialNo}
                                            invalid={() => serialNo === ''}
                                            maxLength={9}
                                        />
                                    </View>
                                    <View style={{ paddingTop: 24 }}>
                                        <Tooltip tooltipDirection='right' width={600}
                                            tooltipContent={
                                                <View style={{ flexDirection: 'column', rowGap: 10, alignItems: 'center', paddingVertical: 5 }}>
                                                    <MyAppText style={{ color: theme.colors.labelColor }}>{translate('hikSerialNoExplanation')}</MyAppText>
                                                    <Image source={require('../../../../assets/hikvision_serial_no.png')} style={{ height: 300, width: 580, objectFit: 'contain' }} />
                                                </View>
                                            }>
                                            <FontAwesomeIcon icon={faInfoCircle} color={theme.colors.iconColor} />
                                        </Tooltip>
                                    </View>
                                </View>
                                <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                    <MyAppText>{translate('networkType')}</MyAppText>
                                    <MyRadio
                                        editable={!canEditFields || !isThirdParty}
                                        selected={networkType}
                                        setSelected={setNetworkType}
                                        options={[{ value: 'ipv6', label: 'IPv6' }, { value: 'ipv4', label: 'IPv4' }]}
                                    />
                                </View>
                                {initialSourceTech == 'rtmp' ?
                                    <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                        <MyAppText>{translate('internetType')}</MyAppText>
                                        <MyRadio
                                            selected={sourceTech}
                                            setSelected={setSourceTech}
                                            options={[
                                                { label: 'RTSP', value: 'rtsp' },
                                                { label: 'RTMP', value: 'rtmp' },
                                            ]}
                                        />
                                    </View> : null}
                                {networkType === 'ipv4' ?
                                    <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                        <View style={[type == 'panoramic' ? { width: '95%' } : { width: '100%' }]}>
                                            <FormInput
                                                label={translate('rtspAddress')}
                                                placeholder=''
                                                value={rtspAddress}
                                                disabled={!canEditFields || isThirdParty}
                                                onChangeText={setRtspAddress}
                                                invalid={() => rtspAddress === ''}
                                            />
                                        </View>
                                        {type == 'panoramic' ?
                                            <View style={{ paddingTop: 24 }}>
                                                <Tooltip tooltipDirection='right' width={300}
                                                    tooltipContent={
                                                        <View style={{ paddingVertical: 5 }}>
                                                            <View>{translate('rtspIpv4Panoramic')}</View>
                                                        </View>
                                                    }>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </Tooltip>
                                            </View>
                                            : null}
                                    </View> : null
                                }
                                {route.params?.id || networkType == 'ipv4' ?
                                    <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                        <View style={[route.params?.id ? { width: '95%' } : { width: '100%' }]}>
                                            <FormInput
                                                label={translate('httpAddress')}
                                                placeholder=''
                                                value={httpAddress}
                                                disabled={networkType != 'ipv4' || isThirdParty}
                                                onChangeText={setHttpAddress}
                                                invalid={() => false}
                                            />
                                        </View>
                                        {route.params?.id ?
                                            <View style={{ paddingTop: 24 }}>
                                                <TouchableOpacity onPress={() => {
                                                    const a = document.createElement('a');
                                                    if (!httpAddress.includes('http')) {
                                                        a.href = `http://${httpAddress}`;
                                                    } else {
                                                        a.href = httpAddress;
                                                    }
                                                    a.target = '_blank';
                                                    a.click();
                                                }}>
                                                    <FontAwesomeIcon icon={faUpRightFromSquare} color={theme.colors.iconColor} />
                                                </TouchableOpacity>
                                            </View> : null
                                        }
                                    </View> : null
                                }
                                <View style={[styles.generalInformationContainer, { zIndex: 5 }]}>
                                    <InstallationCompanyDropdown
                                        value={installationCompanyId}
                                        setValue={setInstallationCompanyId}
                                        zIndex={5}
                                        disabled={!(authenticatedUser?.isAdmin) && !!(route.params?.id) || isThirdParty}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('installer')}
                                        placeholder=''
                                        value={installer}
                                        disabled={!(authenticatedUser?.isAdmin) && !!(route.params?.id) || isThirdParty}
                                        onChangeText={setInstaller}
                                        invalid={() => installer === ''}
                                    />
                                </View>
                                <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                    <MyAppText>{translate('internetType')}</MyAppText>
                                    <MyRadio
                                        selected={internetType}
                                        editable={!canEditFields || !isThirdParty}
                                        setSelected={setInternetType}
                                        options={[
                                            { label: translate('fiber'), value: 'fiber' },
                                            { label: '5G', value: '5g' },
                                            { label: '4G', value: '4g' },
                                            { label: translate('radio'), value: 'radio' }
                                        ]}
                                    />
                                </View>
                            </View>
                            <View style={{ rowGap: 20, maxWidth: 400, width: '100%' }}>
                                <View style={[styles.generalInformationContainer, { zIndex: 4 }]}>
                                    <InternetCompanyDropdown
                                        value={internetCompanyId}
                                        setValue={setInternetCompanyId}
                                        zIndex={4}
                                        disabled={!canEditFields || isThirdParty}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('installedAmountCameras')}
                                        placeholder=''
                                        value={installedAmountCameras}
                                        disabled={!canEditFields || isThirdParty}
                                        onChangeText={setInstalledAmountCameras}
                                        invalid={() => Number(installedAmountCameras) <= 0}
                                    />
                                </View>
                                <View style={[styles.generalInformationContainer, { zIndex: 2, rowGap: 5 }]}>
                                    <MyAppText>{translate('cameraType')}</MyAppText>
                                    <View>
                                        <MyDropDownPicker
                                            open={isCameraTypePickerOpen}
                                            value={type}
                                            items={selectableCamerasTypeFilter}
                                            setOpen={setIsCameraTypePickerOpen}
                                            setValue={(value) => {
                                                setType(value);
                                                if (value(type) == 'lpr') {
                                                    setHasLpr(true);
                                                    setHasFacial(false);
                                                    setHasAreaInvasion(false);
                                                } else {
                                                    setHasLpr(false);
                                                }
                                            }}
                                            height={40}
                                            disabled={!!(route.params?.id) || isThirdParty}
                                        />
                                    </View>
                                </View>
                                <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                    <MyAppText>{translate('algorithm')}</MyAppText>
                                    <View style={{ flexDirection: 'row', columnGap: 20 }}>
                                        <View>
                                            <MyAppText>{translate('lpr')}</MyAppText>
                                            <Switch
                                                trackColor={{ false: '#767577', true: '#81b0ff' }}
                                                thumbColor={hasLpr ? '#f5dd4b' : '#f4f3f4'}
                                                disabled={type == 'lpr' || isThirdParty}
                                                onValueChange={(value) => {
                                                    if (canEditFields || !isThirdParty) {
                                                        setHasLpr(value);
                                                        setHasFacial(false);
                                                        setHasAreaInvasion(false);
                                                    }
                                                }}
                                                value={hasLpr}
                                            />
                                        </View>
                                        <View>
                                            <MyAppText>{translate('facial')}</MyAppText>
                                            <Switch
                                                trackColor={{ false: '#767577', true: '#81b0ff' }}
                                                thumbColor={hasFacial ? '#f5dd4b' : '#f4f3f4'}
                                                disabled={isThirdParty}
                                                onValueChange={(value) => {
                                                    if (canEditFields || !isThirdParty) {
                                                        setHasFacial(value);
                                                        setHasLpr(false);
                                                        setHasAreaInvasion(false);
                                                    }
                                                }}
                                                value={hasFacial}
                                            />
                                        </View>
                                        <View>
                                            <MyAppText>{translate('area_invasion')}</MyAppText>
                                            <Switch
                                                trackColor={{ false: '#767577', true: '#81b0ff' }}
                                                thumbColor={hasAreaInvasion ? '#f5dd4b' : '#f4f3f4'}
                                                disabled={isThirdParty}
                                                onValueChange={(value) => {
                                                    if (canEditFields || !isThirdParty) {
                                                        setHasAreaInvasion(value);
                                                        setHasLpr(false);
                                                        setHasFacial(false);
                                                    }
                                                }}
                                                value={hasAreaInvasion}
                                            />
                                        </View>
                                    </View>
                                </View>
                                {
                                    hasAreaInvasion ?
                                        <View style={[styles.generalInformationContainer, { zIndex: 4 }]}>
                                            <View style={[styles.generalInformationContainer, { zIndex: 3, rowGap: 5 }]}>
                                                <AlertGroupsDropdown
                                                    value={selectedAlertGroupId}
                                                    setValue={setSelectedAlertGroupId}
                                                    zIndex={4} />
                                            </View>
                                        </View> : null
                                }
                                {type == 'ptz' ?
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('ptzUsername')}
                                            placeholder=''
                                            value={ptzUsername}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setPtzUsername}
                                            invalid={() => ptzUsername === ''}
                                        />
                                    </View> : null
                                }
                                {type == 'ptz' ?
                                    <View style={styles.generalInformationContainer}>
                                        <PasswordInput
                                            label={translate('ptzPassword')}
                                            placeholder=''
                                            value={ptzPassword}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setPtzPassword}
                                            invalid={() => ptzPassword === ''}
                                        />
                                    </View> : null
                                }
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </View>

            <DisableEnableModal
                isModalVisible={isDisableEnableModalVisible}
                onClose={(success: boolean) => {
                    setDisableEnableModalVisible(false);
                    if (success) {
                        Toast.show({
                            type: 'sentinelxSuccess',
                            text1: translate('ActionSuccessfully'),
                        });
                    }
                }}
                cameraData={{ id: route.params?.id, disabledAt: disabledAt }}
                setDisabledAt={setDisabledAt}
            />

            <PasswordModal
                isModalVisible={isPasswordModalVisible}
                setIsModalVisible={setPasswordModalVisible}
                cameraPassword={cameraPassword}
            />

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={serialNo ?? title}
                onSubmit={async () => {
                    try {
                        if (route.params?.id) {
                            await crmService.deleteCamera(route.params?.id);
                            Toast.show({
                                type: 'sentinelxSuccess',
                                text1: translate('ActionSuccessfully'),
                            });
                            if (index.routes.length > 1) {
                                return navigation.goBack();
                            } else {
                                window.location.href = '/CRM/cameras';
                            }
                        }
                    } catch (err) {
                        if (err instanceof ClientError) {
                            return Toast.show({
                                type: 'sentinelxError',
                                text1: translate(err.message),
                            });
                        }

                        console.error(err);
                        Toast.show({
                            type: 'sentinelxError',
                            text1: translate('unexpectedError'),
                        });
                    }
                }}
            />
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15,
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        paddingHorizontal: 10,
        flex: 1
    },

    generalInformationContainer: {
        minHeight: 40,
    },

}));
