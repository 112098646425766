import React, { useEffect, useState } from 'react';
import { translate } from '../../../services/translate';
import { cameraService } from '../../../services/central-api/cameras';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';

interface Props {
    value?: number;
    onChange: (value: string) => void;
    zIndex: number;
}

function UserDropdown({ value, onChange, zIndex }: Props) {
    const [persons, setPersons] = useState<{ label: string, value: string; }[]>([]);

    const [selectedPersonFilter, setSelectedPersonFilter] = useState<string>(value?.toString() ?? '');
    async function getPeople() {
        try {
            const people = await cameraService.getUsers();
            setPersons(people.map((person) => {
                return {
                    value: String(person.id),
                    label: person.warName ?? person.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getPeople();
    }, []);

    useEffect(() => {
        onChange(selectedPersonFilter);
    }, [selectedPersonFilter]);

    return (
        <FilterDropdown
            label={translate('requester')}
            items={persons}
            multiple={false}
            setValue={setSelectedPersonFilter}
            value={selectedPersonFilter}
            zIndex={zIndex}
        />
    );
}

export default UserDropdown;
