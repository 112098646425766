import React, { useState } from 'react';
import { CamerasParamList } from '../../../typings/Params';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import Filters from '../../../components/Filter';
import UserDropdown from './UserDropdown';
import StatusDropdown from './StatusDropdown';
import FilterDatePicker from '../../../components/Filter/components/FilterDateInput';
import { translate } from '../../../services/translate';
import moment from 'moment';

interface Props {
    filters: CamerasParamList['VideoAnalysis'];
    setFilters: React.Dispatch<React.SetStateAction<CamerasParamList['VideoAnalysis']>>;
}

function Filter({ filters, setFilters }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<CamerasParamList, 'VideoAnalysis'>>>();

    const [requesterUserId, setRequesterUserId] = useState<number | undefined>(filters.requesterUserId);
    const [status, setStatus] = useState<VideoAnalysisStatus | undefined>(filters.status as VideoAnalysisStatus);
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.startDate ? new Date(filters.startDate) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.startDate ? moment(filters.startDate).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endDate ? new Date(filters.endDate) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endDate ? moment(filters.endDate).format('HH:mm') : '');

    const handleCleanFilter = () => {
        setRequesterUserId(0);
        setStatus(undefined);
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        navigation.setParams({
            ...filters,
            requesterUserId: requesterUserId != 0 ? requesterUserId : undefined,
            status: status !== undefined ? status : undefined,
            startDate: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endDate: endIn && !isNaN(endIn) ? endIn : undefined,
            page: 1,
        });

        setFilters({
            ...filters,
            requesterUserId: requesterUserId != 0 ? requesterUserId : undefined,
            status: status !== undefined ? status : undefined,
            startDate: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endDate: endIn && !isNaN(endIn) ? endIn : undefined,
            page: 1,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <StatusDropdown
                value={status}
                onChange={status => {
                    setStatus(status as VideoAnalysisStatus);
                }}
                zIndex={5}
            />
            <UserDropdown
                value={requesterUserId}
                onChange={user => {
                    setRequesterUserId(+user);
                }}
                zIndex={4}
            />
            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('createdAt')}
                zIndex={3}
            />
            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('endIn')}
                zIndex={2}
            />
        </Filters>
    );
}

export default Filter;
