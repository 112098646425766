import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import MyAppText from './MyAppText';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface TabInfo {
    key: string;
    label: string;
    icon?: IconDefinition;
}

interface TabsParameters {
    tabs: TabInfo[];
    children: ({ selectedTab }: { selectedTab: string; }) => React.ReactNode | undefined;
}

export function Tabs({ children, tabs }: TabsParameters) {
    const { styles, theme } = useStyles(stylesheet);
    const [selectedTab, setSelectedTab] = useState(tabs[0].key);

    return (
        <View style={{ rowGap: 15, flex: 1 }}>
            <View style={styles.container}>
                {tabs.map((tab) => (
                    <View key={tab.key} style={{
                        borderBottomWidth: selectedTab == tab.key ? 2 : 0,
                        borderColor: selectedTab == tab.key ? theme.colors.tabs.selected : undefined,
                        padding: 6
                    }}>
                        <TouchableOpacity onPress={() => setSelectedTab(tab.key)} style={{
                            flexDirection: 'row',
                            columnGap: 5,
                            alignItems: 'center',
                            paddingHorizontal: tab.icon ? 5 : 0
                        }}>
                            {tab.icon ?
                                <FontAwesomeIcon icon={tab.icon} fontSize={14} color={selectedTab == tab.key ? theme.colors.tabs.selected : theme.colors.tabs.border} />
                                : null
                            }

                            <MyAppText style={[styles.tabText, {
                                color: selectedTab == tab.key ? theme.colors.tabs.selected : theme.colors.tabs.border
                            }]}>{tab.label}</MyAppText>
                        </TouchableOpacity>
                    </View>
                ))}
            </View >
            {children({ selectedTab })}
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: theme.colors.tabs.border,
        columnGap: 8,
    },
    tabText: {
        fontWeight: 'bold',
        fontSize: 16,
    }
}));
