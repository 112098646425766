import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import 'react-calendar-timeline/lib/Timeline.css';
import { useDrag, useDrop } from 'react-dnd';


import { centralAPI } from '../../../services/central-api';
import { MosaicCamera, MosaicPreset, cameraService } from '../../../services/central-api/cameras';
import VideoPlayer from '../../../components/VideoPlayer';

interface MosaicDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser,
    route: {
        params?: {
            id: string;
        };
    };
}

interface CameraParams {
    watermark: string;
    mosaic: Mosaic;
    camera?: MosaicCamera;
    setCameras: (value: MosaicCamera[]) => void;
    cameras: MosaicCamera[];
}

interface CameraSquareProps {
    watermark: string;
    mosaic: Mosaic;
    id: number;
    cameras: MosaicCamera[],
    setCameras: (value: MosaicCamera[]) => void;
}

function Camera({ watermark, mosaic, camera, setCameras, cameras }: CameraParams) {

    const videoPlayerRef = React.useCallback(async (videoPlayer: VideoPlayer) => {
        try {
            if (videoPlayer?.props?.camera?.streamUrl) {
                const token = await centralAPI.getToken();
                videoPlayer.setSrc(videoPlayer.props.camera.streamUrl, { Authorization: `Bearer ${token}` });
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const [{ isDragging }, drag] = useDrag(() => {
        if (!camera) {
            return {
                type: ''
            };
        }
        return {
            type: 'camera',
            item: { name: camera.id },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
            end: async (item, monitor) => {
                const dropResult = monitor.getDropResult() as { position: number; };
                if (item && dropResult) {
                    const toReplaceCamera = cameras.find(camera => camera.position == dropResult.position);
                    if (toReplaceCamera) {
                        toReplaceCamera.position = camera.position;
                    }
                    camera.position = dropResult.position;
                    await cameraService.changeMosaicCamerasPosition(mosaic.id, {
                        cameraId: camera.id,
                        position: camera.position
                    });
                    setCameras([...cameras]);
                    location.reload();
                }
            },
        };
    }, [camera]);

    if (!camera) {
        return <></>;
    }

    return (
        <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move', display: 'flex', flex: 1 }}>
            <VideoPlayer
                totalCameras={cameras.length}
                camera={camera}
                ref={videoPlayerRef}
                watermark={watermark}
                hideControls={true}
                onClickEvent={() => {
                    window.open(`/cameras/map/${camera.id}`);
                }}
                onSetLive={() => { }}
            />
        </div>
    );
}

function CameraSquare({ watermark, mosaic, id, cameras, setCameras }: CameraSquareProps) {
    const [{ canDrop }, drop] = useDrop(
        () => ({
            accept: 'camera',
            drop: () => ({ position: id }),
            canDrop: () => true,
            collect: (monitor) => ({
                canDrop: !!monitor.canDrop()
            })
        }),
        [id]
    );

    return (
        <div ref={drop} style={{ display: 'flex', flex: 1, borderWidth: 1, borderColor: '#CCCCCC', borderStyle: canDrop ? 'solid' : undefined }}>
            <Camera
                watermark={watermark}
                mosaic={mosaic}
                camera={cameras.find(camera => camera.position == id)}
                cameras={cameras}
                setCameras={setCameras}
            />
        </div>
    );
}

export default function MosaicDetail({ navigation, authenticatedUser, route }: MosaicDetailParams) {

    const [mosaic, setMosaic] = useState<Mosaic>();
    const [cameras, setCameras] = useState<MosaicCamera[]>([]);
    const [watermark, setWatermark] = useState<string>('');

    useEffect(() => {
        if (!authenticatedUser || !route) return;

        if (route?.params?.id) {
            try {
                getMosaic(route.params.id);
                getCameras(route.params.id);
                setWatermark(String(authenticatedUser.id));
            } catch (err) {
                console.error(err);
                navigation.navigate('Mosaic');
            }

            // ping backend so the token doesn't expire
            const interval = setInterval(async () => {
                // idk why but this effect is being called even when accessing others pages
                // this is a guarantee that the interval will only work when the page is active
                if (!window.location.href.endsWith('cameras/mosaic/' + route.params?.id)) {
                    return;
                }

                await centralAPI.checkAuthentication();
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [route, authenticatedUser]);


    async function getMosaic(id: string) {
        try {
            const mosaic = await cameraService.getMosaic(id);
            setMosaic(mosaic);
        } catch (err) {
            console.error(err);
        }
    }

    async function getCameras(id: string) {
        try {
            const cameras = await cameraService.getMosaicCameras(id);
            setCameras(cameras);
        } catch (err) {
            console.error(err);
        }
    }

    if (!mosaic) {
        return <></>;
    }

    const params = {
        watermark: watermark,
        mosaic: mosaic,
        cameras: cameras,
        setCameras: setCameras
    };

    return (
        <>
            {mosaic.preset == MosaicPreset['4_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={1} {...params} />
                        <CameraSquare id={2} {...params} />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={3} {...params} />
                        <CameraSquare id={4} {...params} />
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['8_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 3, flexDirection: 'row' }}>
                        <View style={{ flex: 3 }}>
                            <CameraSquare id={1} {...params} />
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <CameraSquare id={2} {...params} />
                            <CameraSquare id={3} {...params} />
                            <CameraSquare id={4} {...params} />
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={5} {...params} />
                        <CameraSquare id={6} {...params} />
                        <CameraSquare id={7} {...params} />
                        <CameraSquare id={8} {...params} />
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['9_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={1} {...params} />
                        <CameraSquare id={2} {...params} />
                        <CameraSquare id={3} {...params} />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={4} {...params} />
                        <CameraSquare id={5} {...params} />
                        <CameraSquare id={6} {...params} />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={7} {...params} />
                        <CameraSquare id={8} {...params} />
                        <CameraSquare id={9} {...params} />
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['10_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={1} {...params} />
                        <CameraSquare id={2} {...params} />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={3} {...params} />
                                <CameraSquare id={4} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={5} {...params} />
                                <CameraSquare id={6} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={7} {...params} />
                                <CameraSquare id={8} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={9} {...params} />
                                <CameraSquare id={10} {...params} />
                            </View>
                        </View>
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['13_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={1} {...params} />
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={2} {...params} />
                                <CameraSquare id={3} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={4} {...params} />
                                <CameraSquare id={5} {...params} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={6} {...params} />
                                <CameraSquare id={7} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={8} {...params} />
                                <CameraSquare id={9} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={10} {...params} />
                                <CameraSquare id={11} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={12} {...params} />
                                <CameraSquare id={13} {...params} />
                            </View>
                        </View>
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['16_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={1} {...params} />
                                <CameraSquare id={2} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={3} {...params} />
                                <CameraSquare id={4} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={5} {...params} />
                                <CameraSquare id={6} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={7} {...params} />
                                <CameraSquare id={8} {...params} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={9} {...params} />
                                <CameraSquare id={10} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={11} {...params} />
                                <CameraSquare id={12} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={13} {...params} />
                                <CameraSquare id={14} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={15} {...params} />
                                <CameraSquare id={16} {...params} />
                            </View>
                        </View>
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['18_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={1} {...params} />
                                <CameraSquare id={2} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={3} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={4} {...params} />
                                <CameraSquare id={5} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={6} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={7} {...params} />
                                <CameraSquare id={8} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={9} {...params} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={10} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={11} {...params} />
                                <CameraSquare id={12} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={13} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={14} {...params} />
                                <CameraSquare id={15} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={16} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <CameraSquare id={17} {...params} />
                                <CameraSquare id={18} {...params} />
                            </View>
                        </View>
                    </View>
                </View>
                : null
            }

            {mosaic.preset == MosaicPreset['27_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <CameraSquare id={1} {...params} />
                        <CameraSquare id={2} {...params} />
                        <CameraSquare id={3} {...params} />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={4} {...params} />
                                <CameraSquare id={5} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={6} {...params} />
                                <CameraSquare id={7} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={8} {...params} />
                                <CameraSquare id={9} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={10} {...params} />
                                <CameraSquare id={11} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={12} {...params} />
                                <CameraSquare id={13} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={14} {...params} />
                                <CameraSquare id={15} {...params} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={16} {...params} />
                                <CameraSquare id={17} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={18} {...params} />
                                <CameraSquare id={19} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={20} {...params} />
                                <CameraSquare id={21} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={22} {...params} />
                                <CameraSquare id={23} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={24} {...params} />
                                <CameraSquare id={25} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={26} {...params} />
                                <CameraSquare id={27} {...params} />
                            </View>
                        </View>
                    </View>
                </View> : null
            }

            {mosaic.preset == MosaicPreset['36_PRESET'] ?
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={1} {...params} />
                                <CameraSquare id={2} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={3} {...params} />
                                <CameraSquare id={4} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={5} {...params} />
                                <CameraSquare id={6} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={7} {...params} />
                                <CameraSquare id={8} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={9} {...params} />
                                <CameraSquare id={10} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={11} {...params} />
                                <CameraSquare id={12} {...params} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={13} {...params} />
                                <CameraSquare id={14} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={15} {...params} />
                                <CameraSquare id={16} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={17} {...params} />
                                <CameraSquare id={18} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={19} {...params} />
                                <CameraSquare id={20} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={21} {...params} />
                                <CameraSquare id={22} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={23} {...params} />
                                <CameraSquare id={24} {...params} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={25} {...params} />
                                <CameraSquare id={26} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={27} {...params} />
                                <CameraSquare id={28} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={29} {...params} />
                                <CameraSquare id={30} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={31} {...params} />
                                <CameraSquare id={32} {...params} />
                            </View>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={33} {...params} />
                                <CameraSquare id={34} {...params} />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <CameraSquare id={35} {...params} />
                                <CameraSquare id={36} {...params} />
                            </View>
                        </View>
                    </View>
                </View> : null
            }
        </>
    );
}
