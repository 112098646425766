import { PaginatedResource } from '../../typings/Paginated';
import { BaseService, ClientError, ExtendableError, GenericPaginatedParameters, InvalidValue, NotFound, Unauthorized, UnexpectedError } from './base-service';


export class DuplicatedCameraValue extends ExtendableError {
    cameraId: string | undefined;
    constructor(code: string, cameraId: string) {
        super(code);
        this.cameraId = cameraId;
    }
}

interface UpdatePtzConfiguration {
    address?: string;
    port?: number;
    username?: string;
    password?: string;
}

interface UpdateCameraParameters {
    title?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    serialNo?: string;
    hasFacial?: boolean;
    hasLpr?: boolean;
    hasAreaInvasion?: boolean;
    isPanoramic?: boolean;
    internetType?: InternetType;
    installer?: string;
    networkType?: NetworkType;
    installedAmountCameras?: number;
    installationCompanyId?: number;
    internetCompanyId?: number;
    alertGroupId?: number | null;
    rtspAddress?: string;
    httpAddress?: string;
    sourceTech?: SourceTech;
    ptzConfiguration?: UpdatePtzConfiguration;
}

interface CreateCameraParameters {
    title: string;
    address: string;
    latitude: number;
    longitude: number;
    serialNo: string;
    hasFacial: boolean;
    hasLpr: boolean;
    hasAreaInvasion: boolean;
    type: CameraType;
    internetType: InternetType;
    installer: string;
    networkType: NetworkType;
    installationCompanyId: number;
    internetCompanyId: number;
    installedAmountCameras: number;
    alertGroupId?: number | null;
    rtspAddress?: string;
    httpAddress?: string;
    ptzConfiguration?: PtzConfiguration;
}

interface GetCamerasPaginatedParameters extends GenericPaginatedParameters {
    offline?: boolean;
    showDisabled: boolean;
    hideChildren: boolean;
    type?: CameraType;
    integrationType?: IntegrationType;
}

interface CreateReportParameters {
    name: string;
    startDate: string;
    endDate: string;
    isPdf: boolean;
}

interface CreateAlarmCenterParameters {
    title: string;
    address: string;
    latitude: number;
    longitude: number;
    serialNo: string;
    internetType: InternetType;
    installer: string;
    networkType: NetworkType;
    installationCompanyId: number;
    internetCompanyId: number;
}

interface UpdateAlarmCenterParameters {
    title?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    serialNo?: string;
    internetType?: InternetType;
    installer?: string;
    networkType?: NetworkType;
    installationCompanyId?: number;
    internetCompanyId?: number;
}

class CrmService extends BaseService {

    async getCamerasPaginated(params: GetCamerasPaginatedParameters): Promise<PaginatedResource<CrmPaginatedCamera>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/cameras/paginated${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
            offline: params.offline,
            showDisabled: params.showDisabled,
            hideChildren: params.hideChildren,
            type: params.type,
            integrationType: params.integrationType
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} CannotUpdateToCurrentAction
     * @returns
     */
    async disableEnableCamera(id: string, action: 'enable' | 'disable', reason: string): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}/${action}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ reason })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async updateCamera(id: string, data: UpdateCameraParameters): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(data)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'RtspAlreadyRegistered' || resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    /**
     *
     * @throws {ClientError} RtspAlreadyRegistered | SerialNoAlreadyRegistered | RtspCameraMustHaveAnAddress
     * @returns
     */
    async createCamera(data: CreateCameraParameters): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                ...data,
                sourceTech: 'rtsp',
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'RtspAlreadyRegistered' || resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCamera(id: string): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getCameraPassword(id: string): Promise<{ id: string; password: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}/password`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCameraHistoryPaginated(id: string, filters: { page: number; limit: number; }): Promise<PaginatedResource<CameraHistoryPage>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}/offline-historic${this.encodeQueryParams(filters)}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOfflineEvents(): Promise<OfflineEvent[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/offline-events`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getMaintenanceRequests(): Promise<MaintenanceRequestSimplified[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/maintenance-requests`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async ackOfflineEvent(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/offline-event/${id}/ack`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async ackMaintenanceRequest(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/maintenance-request/${id}/ack`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async getCameraHistory(id: number): Promise<CameraHistory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera-offline-historic/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getMaintenanceRequest(id: number): Promise<MaintenanceRequest> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/maintenance-request/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateCameraHistory(id: number, body: { reason: string, observation: string; }): Promise<CameraHistory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera-offline-historic/${id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(body)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateMaintenanceRequest(id: number, body: { observation: string; }): Promise<MaintenanceRequest> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/maintenance-request/${id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(body)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteCamera(id: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async downloadCameraOfflineTime(params: CreateReportParameters) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/cameras-offline-time`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getReports(params: GenericPaginatedParameters): Promise<PaginatedResource<CameraOfflineReport>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/reports/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReport(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/crm/report/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} SerialNoAlreadyRegistered
     * @returns
     */
    async createAlarmCenter(data: CreateAlarmCenterParameters): Promise<AlarmCenter> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-center`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                ...data,
                sourceTech: 'rtsp',
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAlarmCenter(id: string): Promise<AlarmCenter> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-center/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getAlarmCenterPaginated(params: GenericPaginatedParameters): Promise<PaginatedResource<AlarmCenter>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-centers/paginated${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateAlarmCenter(id: string, data: UpdateAlarmCenterParameters): Promise<AlarmCenter> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-center/${id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(data)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async deleteAlarmCenter(id: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/alarm-center/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

}

export const crmService = new CrmService();
