import AsyncStorage from '@react-native-async-storage/async-storage';
import { BaseService, ClientError, NotFound, Unauthorized, UnexpectedError } from './central-api/base-service';

interface AuthenticateParams {
    /**
     * user email
     */
    email: string;
    /**
     * user password
     */
    password: string;
}

interface RegisterUserDeviceParams {
    /**
     * the unique firebase token generated by firebase/messaging
     */
    firebaseToken: string;
}


interface UpdateOwnUserParams {
    name?: string;
    email?: string;
    registry?: string;
    document?: string;
    warName?: string;
    phone?: string;
    oldPassword?: string;
    password?: string;
    confirmPassword?: string;
}

interface UpdatePictureParams {
    userId: number;
    photo: File;
}

class CentralAPI extends BaseService {

    /**
     * Authenticates user
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async authenticate({ email, password }: AuthenticateParams) {
        const res = await fetch(`${this.centralEndpoint}/authenticate`, {
            method: 'POST',
            headers: this.getHeaders(null, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                'email': email,
                'password': password
            }),
            signal: AbortSignal.timeout(30000)
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        const json_response = await res.json();
        const token = json_response.token;

        if (token) {
            await AsyncStorage.setItem('BEARER_TOKEN', token);
        }

    }

    /**
     * Log outs the user
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async logout() {
        const token = await this.getToken();

        await fetch(`${this.centralEndpoint}/logout`, {
            method: 'GET',
            headers: this.getHeaders(token)
        });

        await AsyncStorage.removeItem('BEARER_TOKEN');
    }

    /**
     * @returns Returns if there's a bearer token stored on cache.
     */
    async isAuthenticated() {
        try {
            const res = await this.checkAuthentication();
            return !isNaN(res.id);
        } catch (e) {
            if (e instanceof Unauthorized) {
                return false;
            }
            throw e;
        }

    }


    //These 3 private variables are to avoid spamming the backend.
    //If there is currently a request being made
    //we just wait for the first one to end and return the same response to all the callers.
    //Once there is no request being made, we can start a new one when called.
    private isCheckReqOngoing: boolean = false;
    private lastRes: Promise<AuthenticatedUser> | null = null;
    private pendingResolves: ((value: AuthenticatedUser) => void)[] = [];

    async checkAuthentication(): Promise<AuthenticatedUser> {

        if (!this.isCheckReqOngoing) {
            this.isCheckReqOngoing = true;
        } else {
            if (this.lastRes) {
                return this.lastRes;
            } else {
                const lastRes = await new Promise<AuthenticatedUser>((resolve) => {
                    this.pendingResolves.push(resolve);
                });
                return lastRes;
            }
        }

        try {
            const token = await this.getToken();

            const res = await fetch(`${this.centralEndpoint}/authentication/check`, {
                method: 'GET',
                headers: this.getHeaders(token)
            });

            if (res.status == 401) {
                throw new Unauthorized();
            }

            if (res.status != 200) {
                throw new UnexpectedError();
            }

            const resp = await res.json();

            this.pendingResolves.forEach(resolve => resolve(resp));
            this.pendingResolves = [];

            return resp;
        } catch (error) {
            this.lastRes = null;
            this.pendingResolves = [];
            throw error;
        } finally {
            this.isCheckReqOngoing = false;
        }
    }



    /**
     * @returns Returns the registered token on cache.
     */
    async getToken() {
        return await AsyncStorage.getItem('BEARER_TOKEN');
    }

    /**
     * Registers the device so that it can be able to receive notifications
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async registerUserDevice({ firebaseToken }: RegisterUserDeviceParams) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/register-device`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                firebaseToken: firebaseToken,
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }
    }

    async getTermsOfUse(): Promise<{ termsOfUse: string; }> {
        const res = await fetch(`${this.centralEndpoint}/terms-of-use`, {
            method: 'GET',
            headers: this.getHeaders(null, 'application/json;charset=utf-8')
        });

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async acceptTermsOfUse() {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/terms-of-use/accept`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async gcmAgentOccurrences(): Promise<Occurrence[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrences`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async gcmAgentOccurrence(id: number): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDistanceTime(origin: LatLng, destination: LatLng): Promise<number> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/time-distance-between-points`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                originLat: origin.lat,
                originLng: origin.lng,
                destLat: destination.lat,
                destLng: destination.lng
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        const json = await res.json();

        const seconds = json.rows[0]?.elements[0]?.duration?.value || 0;
        return Math.ceil(seconds / 60);
    }

    /**
     * Deletes the registry of device so that it won't receive any notifications
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async unregisterUserDevice({ firebaseToken }: { firebaseToken: string; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/register-device/${firebaseToken}`, {
            method: 'DELETE',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getPlaceByAddress(address: string) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/place-by-address/${encodeURIComponent(address)}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }


    async getOwnUser(params: { includeRole: boolean; }): Promise<UserData> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/user/me${this.encodeQueryParams(params)}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @param params
     * @throws {ClientError} EmailAlreadyExists, InvalidOldPassword, PasswordsDoNotMatch, UnmatchedPasswordRequirements
     * @returns
     */
    async updateOwnUser(params: UpdateOwnUserParams): Promise<UserData> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/user/me`, {
            method: 'PUT',
            body: JSON.stringify(params),
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateUserPicture({ photo, userId }: UpdatePictureParams): Promise<void> {
        const token = await this.getToken();

        const body = new FormData();

        body.append('faceImage', photo);

        const res = await fetch(`${this.centralEndpoint}/user/${userId}/facePicture`, {
            method: 'PUT',
            headers: this.getHeaders(token),
            body
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return;
    }

    async getOwnUserSessions(): Promise<Session[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/user/me/sessions`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteOwnUserSession(sessionId: number) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/user/me/session/${sessionId}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async saveLocationToken() {
        try {
            const token = await this.getToken();

            if (!this.isAuthenticated()) {
                return;
            }

            const locationToken = await AsyncStorage.getItem('LOCATION_TOKEN');

            if (locationToken) {
                const res = await fetch(`${this.centralEndpoint}/native-app/save-location-token`, {
                    method: 'POST',
                    body: JSON.stringify({ token: locationToken }),
                    headers: this.getHeaders(token, 'application/json;charset=utf-8'),
                });

                if (res.status == 401) {
                    throw new Unauthorized();
                }

                if (res.status != 200) {
                    throw new UnexpectedError();
                }

                return res.json();
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async forgotPassword(email: string) {
        const res = await fetch(`${this.centralEndpoint}/user/forgot-password`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: this.getHeaders(null, 'application/json;charset=utf-8'),
        });

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async resetPassword(password: string, confirmPassword: string, token: string) {
        const res = await fetch(`${this.centralEndpoint}/user/reset-password`, {
            method: 'POST',
            body: JSON.stringify({ password, confirmPassword, token }),
            headers: this.getHeaders(null, 'application/json;charset=utf-8'),
        });

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status == 406) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }
}

export const centralAPI = new CentralAPI();
