import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { centralAPI } from './central-api';
import { translate } from './translate';

type FirebaseNotification = ({
    type: 'NEW_OCCURRENCE_FACE_DETECTED';
    occurrenceId: string;
    imageUrl: string;
} | {
    type: 'NEW_OCCURRENCE';
    occurrenceId: string;
    occurrenceTypeName: string;
} | {
    type: 'SCENE_CHANGE_OCCURRENCE';
    occurrenceId: string;
    cameraTitle: string;
} | {
    type: 'PLATE_DETECTED_OCCURRENCE';
    occurrenceId: string;
    imageUrl: string;
    platePosition: string;
    description: string;
} | {
    type: 'IRREGULAR_VEHICLE_OCCURRENCE';
    occurrenceId: string;
    imageUrl: string;
    platePosition: string;
    description: string;
} | {
    type: 'AREA_INVASION_OCCURRENCE';
    occurrenceId: string;
    imageUrl: string;
    cameraTitle: string;
} | {
    type: 'MP_FACE_DETECTED_OCCURRENCE';
    occurrenceId: string;
    imageUrl: string;
} | {
    type: 'MP_PLATE_DETECTED_OCCURRENCE';
    occurrenceId: string;
    plate: string;
    imageUrl: string;
} | {
    type: 'ALARM_CENTER_OCCURRENCE';
    occurrenceId: string;
    alarmCenterTitle: string;
} | {
    type: 'NOT_ALLOWED_FACE_DETECTED_OCCURRENCE';
    occurrenceId: string;
    imageUrl: string;
} | {
    type: 'MISSING_FACE_DETECTED_OCCURRENCE';
    occurrenceId: string;
    imageUrl: string;
});

class ExtendableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

export class invalidFirebaseToken extends ExtendableError {
}

export class tryingToCreateFirebaseTokenBeforeAuth extends ExtendableError {
}

interface VehicleNotification {
    title: string;
    occurrenceId: string;
    imageUrl: string;
    platePosition: { x: number, y: number; }[];
    description: string;
}

class NotificationService {
    token: string;
    messaging: firebase.messaging.Messaging | undefined;

    constructor() {
        this.token = '';

        try {

            const app = firebase.initializeApp({
                apiKey: process.env.FIREBASE_API_KEY,
                authDomain: process.env.FIREBASE_AUTH_DOMAIN,
                projectId: process.env.FIREBASE_PROJECT_ID,
                storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.FIREBASE_APP_ID
            });

            this.messaging = firebase.messaging(app);

            this.messaging.onMessage(async (payload: firebase.messaging.MessagePayload) => {
                try {
                    if (!payload.data) {
                        return;
                    }
                    const data = payload.data as unknown as FirebaseNotification;
                    if (data.type === 'NEW_OCCURRENCE_FACE_DETECTED') {
                        return await this.occurrenceFaceDetected(data);
                    } else if (data.type == 'MISSING_FACE_DETECTED_OCCURRENCE') {
                        return await this.occurrenceMissingFaceDetected(data);
                    } else if (data.type == 'NEW_OCCURRENCE') {
                        return await this.occurrence(data);
                    } else if (data.type == 'SCENE_CHANGE_OCCURRENCE') {
                        return await this.occurrenceSceneChangedDetected(data);
                    } else if (data.type == 'IRREGULAR_VEHICLE_OCCURRENCE') {
                        let description = '';
                        if (data.description == 'on_phone') {
                            description = translate('onPhone');
                        }
                        if (data.description == 'without_belt') {
                            description = translate('withoutBelt');
                        }

                        return await this.vehicleNotification({
                            title: translate('irregular_vehicle'),
                            description: description,
                            occurrenceId: data.occurrenceId,
                            imageUrl: data.imageUrl,
                            platePosition: JSON.parse(data.platePosition)
                        });
                    } else if (data.type == 'PLATE_DETECTED_OCCURRENCE') {
                        return await this.vehicleNotification({
                            title: translate('plateDetectedAlert'),
                            description: data.description,
                            occurrenceId: data.occurrenceId,
                            imageUrl: data.imageUrl,
                            platePosition: JSON.parse(data.platePosition)
                        });
                    } else if (data.type === 'AREA_INVASION_OCCURRENCE') {
                        return await this.occurrenceAreaInvasion(data);
                    } else if (data.type === 'MP_FACE_DETECTED_OCCURRENCE') {
                        return await this.occurrenceMpFaceDetected(data);
                    } else if (data.type === 'MP_PLATE_DETECTED_OCCURRENCE') {
                        return await this.occurrenceMpPlateDetected(data);
                    } else if (data.type === 'ALARM_CENTER_OCCURRENCE') {
                        return await this.occurrenceAlarmCenter(data);
                    } else if (data.type === 'NOT_ALLOWED_FACE_DETECTED_OCCURRENCE') {
                        return await this.occurrenceNotAllowedFaceDetected(data);
                    }
                } catch (err) {
                    console.error(err);
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    private async vehicleNotification({ title, occurrenceId, imageUrl, platePosition, description }: VehicleNotification) {
        const img = new Image();
        img.src = imageUrl;
        img.crossOrigin = 'anonymous';

        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        const lprImageWidth = 1920;
        const lprImageHeight = 1080;
        const lprImageRatio = lprImageWidth / lprImageHeight;

        if (platePosition.length == 0) {
            await serviceWorkerRegistration.showNotification(title, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                image: imageUrl,
                body: description,
                icon: window.location.origin + '/favicon.ico',
                badge: window.location.origin + '/favicon.ico',
                data: {
                    clickAction: `/gcm-agent/agent-occurrence-details/${occurrenceId}`
                },
            });
            return;
        }

        const rect = {
            minX: Infinity,
            maxX: 0,
            minY: Infinity,
            maxY: 0
        };

        for (const position of platePosition) {
            if (position.x < rect.minX) {
                rect.minX = position.x;
            }

            if (position.x > rect.maxX) {
                rect.maxX = position.x;
            }

            if (position.y < rect.minY) {
                rect.minY = position.y;
            }

            if (position.y > rect.maxY) {
                rect.maxY = position.y;
            }
        }

        const x = rect.minX;
        const y = rect.minY;
        const width = rect.maxX - rect.minX;
        const height = rect.maxY - rect.minY;

        const vehicleWidth = width * 4;
        const widthCenter = x + width - (width / 2);
        const heightCenter = y + height - (height / 2);


        const vehicleDimension = {
            x: widthCenter - (vehicleWidth / 2),
            y: heightCenter - (vehicleWidth / 2),
            width: vehicleWidth,
            height: vehicleWidth
        };

        if (vehicleDimension.width / vehicleDimension.height > lprImageRatio) {
            // based on vehicle width
            const oldHeight = vehicleDimension.height;
            vehicleDimension.height = vehicleDimension.width / lprImageRatio;
            const heightDiff = vehicleDimension.height - oldHeight;
            vehicleDimension.y -= (heightDiff / 2);

            if (vehicleDimension.height + vehicleDimension.y > lprImageHeight) {
                vehicleDimension.y -= vehicleDimension.height + vehicleDimension.y - lprImageHeight;
            }

            if (vehicleDimension.y < 0) {
                vehicleDimension.y = 0;
            }
        } else {
            // based on vehicle height
            const oldWidth = vehicleDimension.width;
            vehicleDimension.width = vehicleDimension.height * lprImageRatio;
            const widthDiff = vehicleDimension.width - oldWidth;
            vehicleDimension.x -= (widthDiff / 2);

            if (vehicleDimension.width + vehicleDimension.x > lprImageWidth) {
                vehicleDimension.x -= vehicleDimension.width + vehicleDimension.x - lprImageWidth;
            }

            if (vehicleDimension.x < 0) {
                vehicleDimension.x = 0;
            }
        }

        img.onload = async () => {
            const canvas = document.createElement('canvas');

            const ctx = canvas.getContext('2d');

            canvas.width = 1920;
            canvas.height = 1080;

            ctx?.drawImage(
                img,
                vehicleDimension.x,
                vehicleDimension.y,
                vehicleDimension.width,
                vehicleDimension.height,
                0,
                0,
                canvas.width,
                canvas.height
            );

            canvas.toBlob(async (blob) => {
                if (!blob) {
                    return;
                }
                const newUrl = URL.createObjectURL(blob);

                const serviceWorkerRegistration = await navigator.serviceWorker.ready;
                serviceWorkerRegistration.showNotification(title, {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    image: newUrl,
                    body: description,
                    icon: window.location.origin + '/favicon.ico',
                    badge: window.location.origin + '/favicon.ico',
                    data: {
                        clickAction: `/gcm-agent/agent-occurrence-details/${occurrenceId}`
                    },

                });
            }, 'image/jpeg', 0.8);
        };

    }


    private async occurrenceFaceDetected(data: { occurrenceId: string; imageUrl: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('faceDetectedAlert'), {
            body: translate('faceDetectedAlert'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image: data.imageUrl,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceMissingFaceDetected(data: { occurrenceId: string; imageUrl: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('missingFaceDetectedAlert'), {
            body: translate('missingFaceDetectedAlert'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image: data.imageUrl,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceAreaInvasion(data: { occurrenceId: string; imageUrl: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('areaInvasionAlert'), {
            body: translate('areaInvasionAlert'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image: data.imageUrl,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceMpFaceDetected(data: { occurrenceId: string; imageUrl: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('mpFaceDetectedAlert'), {
            body: translate('mpFaceDetectedAlert'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image: data.imageUrl,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceMpPlateDetected(data: { occurrenceId: string; plate: string; imageUrl: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('mpPlateDetectedAlert'), {
            body: `${translate('mpPlateDetectedAlert')} - ${data.plate}`,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image: data.imageUrl,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceAlarmCenter(data: { occurrenceId: string; alarmCenterTitle: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('alarmCenterAlert'), {
            body: data.alarmCenterTitle,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceSceneChangedDetected(data: { occurrenceId: string; cameraTitle: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('cameraDepredationSuspect'), {
            body: data.cameraTitle,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrenceNotAllowedFaceDetected(data: { occurrenceId: string; imageUrl: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('notAllowedFaceDetectedAlert'), {
            body: translate('notAllowedFaceDetectedAlert'),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image: data.imageUrl,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async occurrence(data: { occurrenceId: string; occurrenceTypeName: string; }) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        await serviceWorkerRegistration.showNotification(translate('occurrence'), {
            body: data.occurrenceTypeName,
            icon: window.location.origin + '/favicon.ico',
            badge: window.location.origin + '/favicon.ico',
            data: {
                clickAction: `/gcm-agent/agent-occurrence-details/${data.occurrenceId}`
            },
        });
    }

    private async loadToken() {

        if (Notification.permission != 'granted') {
            await Notification.requestPermission();
        }

        if (Notification.permission != 'granted') {
            throw new invalidFirebaseToken(`The page doesn't have permission to access notifications.`);
        }

        let token;

        try {
            token = await this.messaging?.getToken({ vapidKey: process.env.FIREBASE_PUBLIC_VAPID_KEY });
        } catch (error) {
            // An error occurred while retrieving token.
            // BUT THE NEW TOKEN WILL PROBABLY BE SUCCESSFULLY FETCHED
            // note: idk why this happens :(
            token = await this.messaging?.getToken({ vapidKey: process.env.FIREBASE_PUBLIC_VAPID_KEY });
        }

        if (!token) {
            throw new invalidFirebaseToken('Invalid firebase token.');
        }

        this.token = token;
    }

    async registerToken() {

        if (!await centralAPI.isAuthenticated()) {
            throw new tryingToCreateFirebaseTokenBeforeAuth('You need to be authenticated to register firebase token.');
        }

        if (!this.token) {
            await this.loadToken();
        }

        const oldToken = await AsyncStorage.getItem('FIREBASE_TOKEN');

        if (oldToken != this.token) {
            await AsyncStorage.setItem('FIREBASE_TOKEN', this.token);
        }
        await centralAPI.registerUserDevice({
            firebaseToken: this.token
        });

    }

}

export const notificationService = new NotificationService();
