import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { useState } from 'react';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';
import MarkerDropdown from '../../../components/facial/MarkerDropdown';
import PersonTypeDropdown from '../../../components/facial/PersonTypeDropdown';
import { PersonType } from '../../../services/central-api/facial';

interface Props {
    filters: FacialParamList['PeopleList'];
    setFilters: React.Dispatch<React.SetStateAction<FacialParamList['PeopleList']>>;
    textFilter: string;
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
}

export default function PeopleFilter({ filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<FacialParamList, 'PeopleList'>>();

    const [onlyActive, setOnlyActive] = useState<boolean>(filters.onlyActive ?? true);
    const [selectedMarkersIds, setSelectedMarkersIds] = useState<number[]>(filters.markers ?? []);
    const [personType, setPersonType] = useState<PersonType | ''>(filters.personType ?? '');

    const handleCleanFilter = () => {
        setTextFilter('');
        setOnlyActive(true);
        setSelectedMarkersIds([]);
        setPersonType('');
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            name: textFilter,
            page: 0,
            onlyActive: onlyActive,
            markers: selectedMarkersIds.length ? selectedMarkersIds : undefined,
            personType: personType,
        });

        navigation.setParams({
            name: textFilter,
            page: 1,
            onlyActive: onlyActive,
            markers: selectedMarkersIds.length ? selectedMarkersIds : undefined,
            personType: personType,
        });

    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchByName')}
                placeholder={translate('typeToSearch')}
                value={textFilter}
                onChange={text => {
                    setTextFilter(text);
                }}
            />
            <FilterCheckBox
                label={translate('onlyActive')}
                value={onlyActive}
                onChange={setOnlyActive}
            />
            <PersonTypeDropdown setValue={setPersonType} value={personType} zIndex={3} includeEmptyItem={true} />
            <MarkerDropdown setValue={setSelectedMarkersIds} value={selectedMarkersIds} zIndex={2} />
        </Filters>
    );
}



