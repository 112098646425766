import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { WindowInformation } from '../../../services/window-information';
import { cameraService } from '../../../services/central-api/cameras';
import moment from 'moment';
import { Modal, View } from 'react-native';
import FormActions from '../../../components/formActions';
import { translate } from '../../../services/translate';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import FormInput from '../../../components/formInput';
import MyAppText from '../../../components/MyAppText';
import MyDatetimeInput from '../../../components/MyDatetimeInput';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    cameraId?: string;
}

const regex = /^[a-zA-Z0-9 +\-./:=_ãõẽíáàâêóôúüçÃÕẼÍÁÀÂÊÓÔÚÜÇ]*$/;

export default function DownloadModal({ cameraId, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);

    const windowInfo = WindowInformation();

    const [startInDateFilter, setStartInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [endInDateFilter, setEndInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [endInHourFilter, setEndInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [downloadName, setDownloadName] = useState<string>('');

    const [isOpenTags, setIsOpenTags] = useState<boolean>(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [selectableTags, setSelectableTags] = useState<{ label: string, value: number; }[]>([]);

    function closeDownloadModal() {
        setModalVisible(false);
        resetDownloadForm();
    }

    function resetDownloadForm() {
        setIsOpenTags(false);
        setDownloadName('');
        setSelectedTagsIds([]);
        setStartInDateFilter(moment(new Date().getTime()).format('DD/MM/YYYY'));
        setStartInHourFilter(moment(new Date().getTime()).format('HH:mm:ss'));
        setEndInDateFilter(moment(new Date().getTime()).format('DD/MM/YYYY'));
        setEndInHourFilter(moment(new Date().getTime()).format('HH:mm:ss'));
    }

    async function getDownloadTags() {
        try {
            const tags = await cameraService.getCameraTagsSimplified();
            setSelectableTags(tags.map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    function isDownloadFormValid() {
        return downloadName !== '' &&
            regex.test(downloadName) &&
            startInDateFilter !== '' &&
            startInHourFilter !== '' &&
            endInDateFilter !== '' &&
            endInHourFilter !== '' &&
            moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf() <= moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf();
    }

    async function createDownload() {
        try {
            if (!cameraId) {
                return;
            }

            await cameraService.createCameraDownload({
                cameraId: cameraId,
                name: downloadName,
                msStart: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf(),
                msFinish: moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf(),
                tags: selectedTagsIds
            });
            closeDownloadModal();
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('downloadStarted'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function validateDownloadName(value: string) {
        if (value == '') {
            return true;
        }
        return !regex.test(value);
    }

    useEffect(() => {
        if (isModalVisible) {
            getDownloadTags();
        }
    }, [isModalVisible]);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => closeDownloadModal()} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                    <FormInput
                        label={translate('name')}
                        placeholder={translate('name')}
                        value={downloadName}
                        onChangeText={setDownloadName}
                        invalid={validateDownloadName}
                        errorMessage={translate('invalidCharacters')}
                    />
                    <View style={{ zIndex: 2 }}>
                        <View style={{ flex: 1, gap: 5 }}>
                            <MyAppText>{translate('tags')}</MyAppText>
                            <MyDropDownPicker
                                items={selectableTags}
                                multiple={true}
                                mode='BADGE'
                                value={selectedTagsIds}
                                setValue={setSelectedTagsIds}
                                setOpen={setIsOpenTags}
                                open={isOpenTags}
                            />
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', columnGap: 10, flexWrap: 'wrap', zIndex: 1 }}>
                        <MyDatetimeInput
                            label={translate('beginIn')}
                            date={startInDateFilter}
                            onChangeDate={setStartInDateFilter}
                            time={startInHourFilter}
                            onChangeTime={setStartInHourFilter} />
                        <MyDatetimeInput
                            label={translate('endIn')}
                            date={endInDateFilter}
                            onChangeDate={setEndInDateFilter}
                            time={endInHourFilter}
                            onChangeTime={setEndInHourFilter} />
                    </View>
                    <View>
                        <FormActions
                            onSubmit={createDownload}
                            onClose={() => closeDownloadModal()}
                            disabled={!isDownloadFormValid()} />
                    </View>
                </View>
            </View>
        </Modal>);
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
