import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal, Image } from 'react-native';
import Map from '../../components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faLocationDot, faTags } from '@fortawesome/free-solid-svg-icons';
import { centralAPI } from '../../services/central-api';
import { translate } from '../../services/translate';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import MyAppText from '../../components/MyAppText';
import { MyCheckbox } from '../../components/MyCheckbox';
import { cameraService, GetCamerasMapParameters } from '../../services/central-api/cameras';
import { polygonService } from '../../services/central-api/polygons';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../services/window-information';

let timer: NodeJS.Timeout;

interface CamerasProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}


export default function Cameras({ navigation, authenticatedUser }: CamerasProps) {
    const { theme } = useContext(ThemeContext);
    const searchSelector = [
        { id: 'cameras', title: translate('cameras'), icon: faCamera },
        { id: 'location', title: translate('location'), icon: faLocationDot },
        { id: 'tags', title: translate('tags'), icon: faTags },
    ];
    const loaders = useRef({ didTagsLoad: false, didSearchLoad: false, didSelectedTagsLoad: false });

    const [filters, setFilters] = useState<GetCamerasMapParameters>({
        online: true,
        offline: false,
        types: [],
        tags: [],
        integrationType: []
    });

    const [filterSelectorOpen, setFilterSelectorOpen] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState<'cameras' | 'location' | 'tags'>('location');
    const [cameras, setCameras] = useState<CameraMap[]>([]);
    const [polygonsType, setPolygonsType] = useState<{ type: string; }[]>([]);
    const [selectedPolygonsType, setSelectedPolygonsType] = useState<string | null>(null);
    const [polygons, setPolygons] = useState<MapPolygon[]>([]);
    const [pointsOfInterest, setPointsOfInterest] = useState<PointOfInterest[]>([]);
    const [location, setLocation] = useState<{ lat: number, lng: number; } | LatLng | undefined>();
    const [address, setAddress] = useState<string>();
    const [selectableTagsFilter, setSelectableTagsFilter] = useState<{ label: string, value: number; }[]>([]);
    const [isTagPickerOpen, setIsTagPickerOpen] = useState(false);
    const [isCameraPickerOpen, setIsCameraPickerOpen] = useState(false);
    const [isPolygonPickerOpen, setIsPolygonPickerOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [createPointOfInterest, setCreatePointOfInterest] = useState(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [latitude, setLatitude] = useState<string>('');
    const [longitude, setLongitude] = useState<string>('');
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const windowInfo = WindowInformation();
    const [showMapPoi, setShowMapPoi] = useState<boolean>(false);

    const [onSearchCameras, setOnSearchCameras] = useState<{ label: string, value: string; }[]>([]);
    const [cameraTitle, setCameraTitle] = useState<string>('');
    const [cameraTitleLoading, setCameraTitleLoading] = useState<boolean>(false);
    const typingTimeout = useRef<NodeJS.Timeout>();

    const styles = getStyles(theme);

    function pinClick(pin: Marker) {
        if (pin?.metadata?.camera?.id) {
            goToCamera(pin.metadata.camera.id);
        }
    }

    function goToCamera(id: string) {
        navigation.navigate('CameraPlayer', { id });
    }


    async function getCamerasList() {
        try {
            const cameras = await cameraService.getCamerasMap(filters);
            setCameras(cameras);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getCamerasList();
    }, [filters]);

    async function getPolygonsType() {
        try {
            const polygonsType = await polygonService.getPolygonsType();
            setPolygonsType(polygonsType);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPolygonsByType(type: string) {
        try {
            const polygons = await polygonService.getPolygonsByType(type);
            setPolygons(polygons);
        } catch (err) {
            console.error(err);
        }
    }

    function getPlacesByAddress(delay: number) {
        clearTimeout(timer);

        timer = setTimeout(async () => {
            try {
                if (address) {
                    const response = await centralAPI.getPlaceByAddress(address);
                    const newLocation = { lat: response?.candidates[0]?.geometry?.location.lat, lng: response?.candidates[0]?.geometry?.location.lng };
                    setLocation(newLocation);

                }
            } catch (err) {
                console.error(err);
            }
        }, delay);

    }

    async function getTags() {
        try {
            const tags = await cameraService.getCameraTagsSimplified();
            setSelectableTagsFilter(tags.map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    async function getPointsOfInterest() {
        try {
            const pointsOfInterest = await cameraService.getPointOfInterests();
            setPointsOfInterest(pointsOfInterest);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!loaders.current.didSelectedTagsLoad) {
            loaders.current.didSelectedTagsLoad = true;
            return;
        }

        setFilters({ ...filters, tags: selectedTags });
    }, [selectedTags]);

    useEffect(() => {
        getTags();
        getPointsOfInterest();
        getPolygonsType();
    }, []);


    useEffect(() => {
        if (selectedPolygonsType) {
            getPolygonsByType(selectedPolygonsType);
        } else {
            setPolygons([]);
        }
    }, [selectedPolygonsType]);

    useEffect(() => {
        getPlacesByAddress(1000);
    }, [address]);

    function getMapLocation(event: LatLng) {
        if (createPointOfInterest) {
            setLatitude(String(event.lat));
            setLongitude(String(event.lng));
            setModalVisible(true);
        }
    }

    function isFormValid() {
        return name !== '';
    }

    async function setSearchedCameras() {
        try {
            setCameraTitleLoading(true);
            if (cameraTitle.length > 0) {
                const cameras = await cameraService.searchCameras({ textFilter: cameraTitle });
                setOnSearchCameras(cameras.map((camera) => ({
                    label: camera.title,
                    value: camera.id
                })));
            } else {
                setOnSearchCameras([]);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setCameraTitleLoading(false);
        }
    }

    useEffect(() => {
        if (typingTimeout.current) {
            clearTimeout(typingTimeout.current);
        }
        const timeout = setTimeout(async () => {
            setSearchedCameras();
        }, 1000);
        typingTimeout.current = timeout;
        return () => clearTimeout(timeout);
    }, [cameraTitle]);

    return (
        <>
            <View style={styles.container}>
                <View style={styles.content}>
                    <View style={styles.mapTopBar}>
                        <View style={styles.filters}>
                            <View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('lpr')}
                                    checked={filters.types?.includes('lpr') ? true : false}
                                    setChecked={(value) => {
                                        value ? filters.types?.push('lpr') : filters.types?.splice(filters.types?.indexOf('lpr'), 1);
                                        setFilters({ ...filters, types: filters.types });
                                    }}
                                />
                            </View>
                            <View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('ptz')}
                                    checked={filters.types?.includes('ptz') ? true : false}
                                    setChecked={(value) => {
                                        value ? filters.types?.push('ptz') : filters.types?.splice(filters.types?.indexOf('ptz'), 1);
                                        setFilters({ ...filters, types: filters.types });
                                    }}
                                />
                            </View>
                            <View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('pinned')}
                                    checked={filters.types?.includes('pinned') ? true : false}
                                    setChecked={(value) => {
                                        value ? filters.types?.push('pinned') : filters.types?.splice(filters.types?.indexOf('pinned'), 1);
                                        setFilters({ ...filters, types: filters.types });
                                    }}
                                />
                            </View>
                            <View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('panoramic')}
                                    checked={filters.types?.includes('panoramic') ? true : false}
                                    setChecked={(value) => {
                                        value ? filters.types?.push('panoramic') : filters.types?.splice(filters.types?.indexOf('panoramic'), 1);
                                        setFilters({ ...filters, types: filters.types });
                                    }}
                                />
                            </View>
                            <View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('type_2')}
                                    checked={filters.integrationType?.includes('type_2') ? true : false}
                                    setChecked={(value) => {
                                        value ? filters.integrationType?.push('type_2') : filters.integrationType?.splice(filters.integrationType?.indexOf('type_2'), 1);
                                        setFilters({ ...filters, integrationType: filters.integrationType });
                                    }}
                                />
                            </View>
                            <View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('type_3')}
                                    checked={filters.integrationType?.includes('type_3') ? true : false}
                                    setChecked={(value) => {
                                        value ? filters.integrationType?.push('type_3') : filters.integrationType?.splice(filters.integrationType?.indexOf('type_3'), 1);
                                        setFilters({ ...filters, integrationType: filters.integrationType });
                                    }}
                                />
                            </View>
                        </View>
                        <View style={{ flex: 1 }}>
                        </View>
                        <View style={{ width: 150, minHeight: 40, borderRadius: 5, zIndex: 3 }}>
                            <MyDropDownPicker
                                height={40}
                                disableBorderRadius={true}
                                open={filterSelectorOpen}
                                setOpen={setFilterSelectorOpen}
                                value={selectedSearch}
                                setValue={setSelectedSearch}
                                items={(Array.isArray(searchSelector) ? searchSelector : []).map((filter) => ({
                                    label: filter.title,
                                    value: filter.id,
                                    icon: () => (
                                        <FontAwesomeIcon icon={filter.icon} fontSize={16} color='#58595B' />
                                    ),
                                }))}
                            />
                        </View>
                        {authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_management_point_of_interest
                            ? <View style={styles.newPointOfInterest}>
                                <TouchableOpacity onPress={() => {
                                    setCreatePointOfInterest(true);
                                    Toast.show({
                                        type: 'sentinelxWarning',
                                        text1: translate('clickOnMap'),
                                    });
                                }}>
                                    <Image source={require('../../../assets/pin_point_of_interest.svg')} style={{ width: 22, height: 26 }} />
                                </TouchableOpacity>
                            </View>
                            : null
                        }

                        <View style={{ width: '100%', minWidth: 300, maxWidth: 600, minHeight: 40, zIndex: 2 }}>
                            {selectedSearch === 'cameras' ? (
                                <MyDropDownPicker
                                    height={40}
                                    placeholderColor={getThemedColor(theme, '#CCCCCC')}
                                    placeholder={translate('filter')}
                                    open={isCameraPickerOpen}
                                    setOpen={setIsCameraPickerOpen}
                                    items={onSearchCameras}
                                    value={1}
                                    setValue={(camera) => {
                                        goToCamera(camera(1));
                                    }}
                                    onChangeSearchText={(value: string) => {
                                        setCameraTitle(value);
                                    }}
                                    searchable={true}
                                    disableLocalSearch={true}
                                    loading={cameraTitleLoading}
                                />) : null
                            }
                            {selectedSearch === 'location' ?
                                <MyAppText style={{ fontSize: 16, minHeight: 40 }}>
                                    <GooglePlacesAutocomplete
                                        apiOptions={{
                                            language: 'pt-BR',
                                            region: 'BR',
                                        }}
                                        autocompletionRequest={{
                                            bounds: [
                                                { lat: -24, lng: -47 },
                                                { lat: -22, lng: -45 }
                                            ],
                                            componentRestrictions: { country: 'br' }
                                        }}
                                        selectProps={{
                                            onChange: (value) => {
                                                setAddress(value?.label);
                                            },
                                            styles: {
                                                singleValue(base) {
                                                    base.color = getThemedColor(theme, '#222222');
                                                    return base;
                                                },
                                                placeholder(base) {
                                                    base.color = getThemedColor(theme, '#CBCBCB');
                                                    return base;
                                                },
                                                input(base) {
                                                    base.color = getThemedColor(theme, '#222222');
                                                    return base;
                                                },
                                                control(base) {
                                                    base.backgroundColor = getThemedColor(theme, '#FFFFFF');
                                                    base.borderColor = getThemedColor(theme, '#CCCCCC');
                                                    base.height = 40;
                                                    return base;
                                                },
                                                menuList(base) {
                                                    base.color = getThemedColor(theme, '#222222');
                                                    base.backgroundColor = getThemedColor(theme, '#FFFFFF');
                                                    return base;
                                                },

                                            }
                                        }}
                                    />
                                </MyAppText> : null}
                            {selectedSearch === 'tags' ? (
                                <MyDropDownPicker
                                    height={40}
                                    placeholderColor={getThemedColor(theme, '#CCCCCC')}
                                    placeholder={translate('filter')}
                                    open={isTagPickerOpen}
                                    items={selectableTagsFilter}
                                    setOpen={setIsTagPickerOpen}
                                    value={selectedTags}
                                    setValue={setSelectedTags}
                                    multiple={true}
                                    mode='BADGE'
                                    searchable={true}
                                />
                            ) : null}
                        </View>
                    </View>
                    {authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_management_point_of_interest ?
                        <View style={styles.newPointOfInterest}>
                            <TouchableOpacity onPress={() => {
                                setCreatePointOfInterest(true);
                                Toast.show({
                                    type: 'sentinelxWarning',
                                    text1: translate('clickOnMap'),
                                });
                            }}>
                                <Image source={require('../../../assets/pin_point_of_interest.svg')} style={{ width: 22, height: 26 }} />
                            </TouchableOpacity>
                        </View>
                        : null}
                    <View style={styles.onlineOffline}>
                        <View>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={'on'}
                                checked={filters.online || false}
                                setChecked={(value) => {
                                    if (!value && !filters.offline) {
                                        return;
                                    }
                                    setFilters({ ...filters, online: value });
                                }}
                            />
                        </View>
                        <View>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={'off'}
                                checked={filters.offline || false}
                                setChecked={(value) => {
                                    if (!value && !filters.online) {
                                        return;
                                    }
                                    setFilters({ ...filters, offline: value });
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.overlaySelector}>
                        <MyDropDownPicker
                            height={40}
                            placeholderColor={getThemedColor(theme, '#CCCCCC')}
                            placeholder={translate('map')}
                            open={isPolygonPickerOpen}
                            setOpen={setIsPolygonPickerOpen}
                            items={[
                                { label: '', value: undefined },
                                ...polygonsType.map((polygon) => {
                                    return {
                                        label: polygon.type,
                                        value: polygon.type
                                    };
                                })
                            ]}
                            value={selectedPolygonsType}
                            setValue={setSelectedPolygonsType}
                            searchable={true}
                        />
                    </View>
                    <View style={styles.mapPoi}>
                        <MyCheckbox
                            style={styles.checkbox}
                            label={translate('pointsOfInterest')}
                            checked={showMapPoi}
                            setChecked={setShowMapPoi}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Map pinClick={pinClick}
                            dynamicPosition={location}
                            pinnedMarker={location}
                            cameraPositions={cameras}
                            pointsOfInterestPositions={pointsOfInterest}
                            onClick={getMapLocation}
                            refreshPointsOfInterest={getPointsOfInterest}
                            canManagePointsOfInterest={authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_management_point_of_interest}
                            polygons={polygons}
                            showMapPoi={showMapPoi}
                        />
                    </View>
                </View>

            </View>
            <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                            <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: getThemedColor(theme, '#58595B') }}>{translate('pointOfInterest')}</MyAppText>
                            <FormInput label={translate('name')} invalid={() => false} value={name} onChangeText={setName} />
                            <FormInput numberOfLines={5} multiline={true} label={translate('description')} invalid={() => false} value={description} onChangeText={setDescription} />
                            <FormActions
                                onSubmit={async () => {
                                    setCreatePointOfInterest(false);
                                    try {
                                        await cameraService.createPointOfInterest({
                                            name: name,
                                            description: description,
                                            latitude: Number(latitude),
                                            longitude: Number(longitude)
                                        });
                                        setModalVisible(false);
                                        getPointsOfInterest();
                                        Toast.show({
                                            type: 'sentinelxSuccess',
                                            text1: translate('ActionSuccessfully'),
                                        });
                                    } catch (error) {
                                        Toast.show({
                                            type: 'sentinelxError',
                                            text1: translate('unexpectedError'),
                                        });
                                    } finally {
                                        setName('');
                                        setDescription('');
                                    }
                                }}
                                onClose={() => setModalVisible(false)}
                                disabled={!isFormValid()} />
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
        },
        content: {
            flex: 1,
            flexWrap: 'wrap'
        },
        dropdown: {
            flexDirection: 'row',
            columnGap: 10,
            flex: 1,
            minWidth: 450
        },
        mapTopBar: {
            position: 'absolute',
            flexDirection: 'row',
            zIndex: 1000,
            top: 10,
            paddingHorizontal: 10,
            flexWrap: 'wrap',
            gap: 10,
            display: 'flex',
            width: '100%',
        },
        filters: {
            flexDirection: 'row',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CBCBCB'),
            maxWidth: 400,
            paddingHorizontal: 8,
            columnGap: 8,
            justifyContent: 'space-evenly'
        },
        onlineOffline: {
            position: 'absolute',
            flexDirection: 'row',
            zIndex: 1000,
            bottom: 10,
            left: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CBCBCB'),
            paddingHorizontal: 8,
            columnGap: 8,
            justifyContent: 'space-evenly',
            height: 40
        },
        overlaySelector: {
            position: 'absolute',
            zIndex: 1000,
            bottom: 10,
            left: 120,
            width: 240
        },
        mapPoi: {
            position: 'absolute',
            zIndex: 1000,
            bottom: 10,
            left: 368,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CBCBCB'),
            paddingHorizontal: 8,
            columnGap: 8,
            height: 40
        },
        newPointOfInterest: {
            width: 40,
            height: 40,
            position: 'absolute',
            zIndex: 2,
            right: 10,
            bottom: 209,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CBCBCB'),
        },
        checkbox: {
            height: 38
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        formContainer: {
            minWidth: 350,
            maxWidth: 400,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 200,
            rowGap: 30
        },
    });
}
