import React from 'react';
import { TextInput, View, ViewStyle } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from './MyAppText';
import { translate } from '../services/translate';

interface Props {
    title: string;
    placeholder?: string;
    textFilter?: string;
    setTextFilter: (value: string) => void;
    style?: ViewStyle;
}

function CustomTextFilter({ title, textFilter, placeholder, setTextFilter, style }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <View style={style ?? { flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
            <MyAppText style={styles.filterText}>{translate(title)}</MyAppText>
            <TextInput
                style={[styles.filterInput, styles.textColor(textFilter)]}
                value={textFilter}
                onChangeText={setTextFilter}
                placeholder={translate(placeholder ?? 'typeToSearch')}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    filterText: {
        fontSize: 16,
        height: 25,
    },
    filterInput: {
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.container.background,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.container.border,
        height: 40,
        minHeight: 40,
        fontSize: 16,
        padding: 10
    },

    textColor: (text?: string) => ({
        color: text ? theme.colors.field.text : theme.colors.disabledText
    })
}));

export default CustomTextFilter;
