import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faGear, faLocationDot, faVideo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import personDefaultPicture from '../../../assets/person-default-picture.png';
import Map from '../../components/Map';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { useNavigationState } from '@react-navigation/native';
import { facialService } from '../../services/central-api/facial';
import { ScrollView } from 'react-native-web-hover';
import { TextMask } from 'react-native-masked-text';
import getBestContrastColor from '../../services/best-contrast-color';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../components/CustomButton';
import { useAuth } from '../../context/Auth';

interface EventDetailParams {
    navigation: Navigation;
    route: {
        params: {
            eventId: string;
        };
    };
    module: 'smart-search-facial' | 'facial';
    page: 'search' | 'live-alerts' | 'live-events' | 'search';
}

export default function EventDetail({ navigation, route, module, page }: EventDetailParams) {
    const { styles, theme } = useStyles(stylesheet);

    const [event, setEvent] = useState<FacialDetection>();
    const index = useNavigationState(state => state);
    const { user: authenticatedUser } = useAuth();

    async function getDetection(eventId: string) {
        try {
            const event = await facialService.getFacialDetection(Number(eventId));
            setEvent(event);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDetection(route.params.eventId);
    }, [route]);

    if (!event || !authenticatedUser) {
        return <></>;
    }

    function PersonInfoInRow({ title, desc }: { title: string; desc: string; }) {
        return <View style={{ flexDirection: 'row', columnGap: 5 }}>
            <MyAppText style={styles.personInfoTitleText}>
                {title}:
            </MyAppText>
            <MyAppText style={styles.personInfoText}>
                {desc}
            </MyAppText>
        </View>;
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => {
                        if (index.routes.length > 1) {
                            return navigation.goBack();
                        } else {
                            if (module == 'facial') {
                                if (page == 'live-alerts') {
                                    window.location.href = '/facial/live-alerts';
                                }
                                if (page == 'live-events') {
                                    window.location.href = '/facial/live-events';
                                }
                                if (page == 'search') {
                                    window.location.href = '/facial/search';
                                }
                            }
                            if (module === 'smart-search-facial' && page == 'search') {
                                window.location.href = '/smart-search-facial/search';
                            }
                        }
                    }}>
                        <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                    </TouchableOpacity>
                    <MyAppText style={styles.headerText}>
                        {translate('detection')}
                    </MyAppText>
                </View>

                {authenticatedUser?.isAdmin || authenticatedUser?.permissions.crm_module_access ?
                    <CustomButton
                        text={translate('cameraConfiguration')}
                        icon={faGear}
                        type='secondary'
                        onPress={() => window.open(`/CRM/cameras/details/${event.camera.id}`)} /> : null
                }
            </View>
            <View style={styles.cardList}>
                <ScrollView contentContainerStyle={{
                    columnGap: 20,
                    rowGap: 5,
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}>
                    <View style={{ rowGap: 10, flexBasis: '25%', minWidth: 350, minHeight: 550 }}>
                        <View style={{ flexDirection: 'row', columnGap: 15, flexBasis: '30%' }}>
                            <View style={{ flex: 1 }}>
                                <MyAppText style={styles.imageText}>
                                    {translate('detection')}
                                </MyAppText>
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={event.imageUrl}
                                />
                            </View>
                            <View style={{ flex: 1 }}>
                                <MyAppText style={styles.imageText}>
                                    {translate('reference')}
                                </MyAppText>
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    src={event.person?.facePictures.length ? event.person.facePictures[0].imageUrl : personDefaultPicture}
                                />
                            </View>
                        </View>
                        <MyAppText style={styles.confidenceText}>
                            {event.confidence ?
                                translate('confidence') + ': ' + (Number(event.confidence) * 100).toFixed(0) + '%'
                                : null
                            }
                        </MyAppText>
                        <View style={{ rowGap: 5 }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                                <FontAwesomeIcon icon={faVideo} style={{ width: 22 }} color={theme.colors.textColor} />
                                <TouchableOpacity onPress={() => {
                                    window.open(`/cameras/map/${event.camera.id}`);
                                }} style={styles.link}>
                                    <MyAppText style={[styles.detectionInfoText, { textDecorationLine: 'underline' }]}>
                                        {event.camera.title}
                                    </MyAppText>
                                </TouchableOpacity>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                                <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 22 }} color={theme.colors.textColor} />
                                <MyAppText style={styles.detectionInfoText}>
                                    {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                                </MyAppText>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                                <FontAwesomeIcon icon={faLocationDot} style={{ width: 22 }} color={theme.colors.textColor} />
                                <View style={styles.link}>
                                    <MyAppText style={styles.detectionInfoText}>
                                        {event.camera.address}
                                    </MyAppText>
                                </View>
                            </View>
                        </View>
                        <Map pinnedMarker={{
                            lat: event.camera.latitude,
                            lng: event.camera.longitude
                        }} />
                    </View>
                    {event.person ?
                        <ScrollView style={{ flex: 1 }}>
                            <View style={{ rowGap: 10 }}>
                                <MyAppText style={styles.nameText}>
                                    {event.person.name}
                                </MyAppText>
                                {event.person.nickname ?
                                    <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                        <MyAppText style={styles.personInfoTitleText}>
                                            {translate('nickname')}:
                                        </MyAppText>
                                        <MyAppText style={styles.personInfoText}>
                                            {event.person.nickname}
                                        </MyAppText>
                                    </View>
                                    : null
                                }
                                <View style={{ flexDirection: 'row' }}>
                                    {event?.person?.markers?.map((marker) =>
                                        <View key={marker.id} style={[styles.marker, { backgroundColor: marker.color }]}>
                                            <MyAppText style={styles.markerText(marker.color)}>
                                                {marker.name}
                                            </MyAppText>
                                        </View>
                                    )}
                                </View>
                                <View style={{ flexDirection: 'row', columnGap: 35, flexWrap: 'wrap', rowGap: 5 }}>
                                    <View>
                                        <MyAppText style={styles.personDocumentInfoText}>CPF:</MyAppText>
                                        <TextMask
                                            style={[styles.personDocumentInfoText, { fontFamily: 'Open Sans' }]}
                                            value={event.person.document}
                                            type={'cpf'}
                                        />
                                    </View>
                                    <View>
                                        <MyAppText style={styles.personDocumentInfoText}>
                                            {translate('personRegister')}:
                                        </MyAppText>
                                        <MyAppText style={styles.personDocumentInfoText}>
                                            {event.person.register}
                                        </MyAppText>
                                    </View>
                                    <View>
                                        <MyAppText style={styles.personDocumentInfoText}>
                                            {translate('registeredOn')}:
                                        </MyAppText>
                                        <MyAppText style={styles.personDocumentInfoText}>
                                            {moment(new Date(event.person.createdAt || 0)).format('DD/MM/YYYY HH:mm:ss')}
                                        </MyAppText>
                                    </View>
                                </View>
                                {event.person.comment ?
                                    <View style={{ marginVertical: 20 }}>
                                        <MyAppText style={styles.personInfoTitleText}>
                                            {translate('additionalComments')}:
                                        </MyAppText>
                                        <MyAppText style={styles.personInfoText}>
                                            {event.person.comment}
                                        </MyAppText>
                                    </View>
                                    : null
                                }
                                <View style={{ marginVertical: 20 }}>
                                    {event.person.city ?
                                        <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                            <MyAppText style={styles.personInfoTitleText}>
                                                {translate('city')}:
                                            </MyAppText>
                                            <MyAppText style={styles.personInfoText}>
                                                {event.person.city}/{event.person.state}
                                            </MyAppText>
                                        </View>
                                        : null
                                    }
                                </View>
                                {event.person.personType == 'wanted' ?
                                    <>
                                        <MyAppText style={[styles.nameText]}>
                                            {translate('arrestWarrants')}:
                                        </MyAppText>
                                        {event?.person?.lawsuits?.map((lawsuit) => (
                                            <View key={lawsuit.id} style={{ rowGap: 5 }}>
                                                <PersonInfoInRow
                                                    title={translate('situation')}
                                                    desc={lawsuit.situation}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('arrestWarrantNumber')}
                                                    desc={lawsuit.arrestWarrantNumber}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('shippingDate')}
                                                    desc={lawsuit.shippingDate}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('expirationDate')}
                                                    desc={lawsuit.expirationDate}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('lawsuitNumber')}
                                                    desc={lawsuit.lawsuitNumber}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('prisonKind')}
                                                    desc={lawsuit.prisonKind}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('magistrate')}
                                                    desc={lawsuit.magistrate}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('issuingBody')}
                                                    desc={lawsuit.issuingBody}
                                                />
                                                <PersonInfoInRow
                                                    title={translate('county')}
                                                    desc={lawsuit.lawsuitCity}
                                                />
                                            </View>
                                        ))}
                                    </>
                                    : null
                                }
                            </View>
                        </ScrollView>
                        : null
                    }
                </ScrollView>
            </View>
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerText: {
        fontSize: 24,
        color: theme.colors.labelColor,
        fontWeight: 'bold'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        padding: 24,
        backgroundColor: theme.colors.container.background,
        flex: 1,
    },
    imageText: {
        color: theme.colors.cardDetailText,
        marginBottom: 5,
        fontSize: 11
    },
    detectionInfoText: {
        fontSize: 14,
        color: theme.colors.labelColor,
    },
    confidenceText: {
        color: theme.colors.cardDetailText,
        fontSize: 14,
    },
    nameText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 20
    },
    personInfoTitleText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    personInfoText: {
        color: theme.colors.textColor,
        fontSize: 16
    },
    personDocumentInfoText: {
        color: theme.colors.textColor,
        fontSize: 14
    },
    marker: {
        borderRadius: 8,
        height: 16,
        paddingLeft: 10,
        paddingRight: 10,
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
    link: {
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flex: 1
    }
}));
