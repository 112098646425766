import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { accessService } from '../../../services/central-api/access';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../../services/window-information';
import MyAppText from '../../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../components/DeleteModal';
import { ClientError } from '../../../services/central-api/base-service';
import { Tabs } from '../../../components/Tabs';
import { ScrollView } from 'react-native-web-hover';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomTextInput from '../../../components/CustomTextInput';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    actingBodyId?: number;
}

export default function ActingBodiesModal({ onClose, actingBodyId, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [roles, setRoles] = useState<Role[]>([{ name: '' }]);
    const [selectedRoleForDeletion, setSelectedRoleForDeletion] = useState<Role>();
    const [units, setUnits] = useState<ActingBodyUnit[]>([{ name: '' }]);
    const [commands, setCommands] = useState<ActingBodyCommand[]>([{ name: '' }]);
    const [isDeleteRoleModalVisible, setIsDeleteRoleModalVisible] = useState(false);
    const [isDeleteUnitModalVisible, setIsDeleteUnitModalVisible] = useState(false);
    const [isDeleteCommandModalVisible, setIsDeleteCommandModalVisible] = useState(false);
    const [selectedUnitForDeletion, setSelectedUnitForDeletion] = useState<ActingBodyUnit>();
    const [selectedCommandForDeletion, setSelectedCommandForDeletion] = useState<ActingBodyCommand>();

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    function clearValues() {
        setName('');
        setDescription('');
        setRoles([]);
        setUnits([]);
        setCommands([]);
    }

    async function deleteUnit(unit: ActingBodyUnit) {
        try {
            if (!unit.id) {
                return;
            }
            await accessService.deleteUnit(unit.id);
            const index = units.findIndex(u => u.id == unit.id);
            units.splice(index, 1);
            setUnits([...units]);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteCommand(command: ActingBodyCommand) {
        try {
            if (!command.id) {
                return;
            }
            await accessService.deleteCommand(command.id);
            const index = commands.findIndex(u => u.id == command.id);
            commands.splice(index, 1);
            setCommands([...commands]);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteRole(role: Role) {
        try {
            if (!role.id) {
                return;
            }
            await accessService.deleteRole(role.id);
            const index = roles.findIndex(r => r.id == role.id);
            roles.splice(index, 1);
            setRoles([...roles]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getActingBody(actingBodyId: number) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);
            setName(actingBody.name);
            setDescription(actingBody.description);
            setRoles(actingBody.roles || []);
            setUnits(actingBody.actingBodyUnits || []);
            setCommands(actingBody.actingBodyCommands || []);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getActingBody(actingBodyId);
        }
    }, [actingBodyId]);

    async function formSubmit() {
        try {
            if (actingBodyId) {
                await accessService.updateActingBody({
                    id: actingBodyId,
                    name,
                    description,
                    roles: roles.filter(r => r.name !== ''),
                    actingBodyUnits: units.filter(u => u.name !== ''),
                    actingBodyCommands: commands.filter(c => c.name !== ''),
                });
            } else {
                await accessService.createActingBody({
                    name,
                    description,
                    roles: roles.filter(r => r.name !== ''),
                    actingBodyUnits: units.filter(u => u.name !== ''),
                    actingBodyCommands: commands.filter(c => c.name !== ''),
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => {
            setModalVisible(false);
            onClose();
            clearValues();
        }} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '30%' }]}>
                    <Tabs tabs={[{
                        key: 'details',
                        label: translate('details')
                    }, {
                        key: 'roles',
                        label: translate('roles')
                    }, {
                        key: 'commands',
                        label: translate('commands')
                    }, {
                        key: 'units',
                        label: translate('units')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'details') {
                                return (<>
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isNameInvalid}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={() => false}
                                    />
                                </>);
                            } else if (selectedTab == 'roles') {
                                return <ScrollView>
                                    <View style={{ gap: 8 }}>
                                        <MyAppText>{translate('roles')}</MyAppText>
                                        {roles.map((role, index) => (
                                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                                <CustomTextInput
                                                    placeholder={'role'}
                                                    value={role.name}
                                                    onChangeText={(value) => {
                                                        roles[index].name = value;
                                                        setRoles([...roles]);
                                                    }} />
                                                <TouchableOpacity onPress={() => {
                                                    if (roles[index].id) {
                                                        setSelectedRoleForDeletion(roles[index]);
                                                        setIsDeleteRoleModalVisible(true);
                                                        return;
                                                    }
                                                    roles.splice(index, 1);
                                                    setRoles([...roles]);
                                                }}>
                                                    <FontAwesomeIcon fontSize={24} icon={faTimes} color={theme.colors.iconColor} />
                                                </TouchableOpacity>
                                            </View>))
                                        }

                                        <TouchableOpacity onPress={() => {
                                            roles.push({ name: '' });
                                            setRoles([...roles]);
                                        }}>
                                            <MyAppText>+ {translate('addRole')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                </ScrollView>;
                            } else if (selectedTab == 'units') {
                                return <ScrollView>
                                    <View style={{ gap: 8 }}>
                                        <MyAppText>{translate('units')}</MyAppText>

                                        {units.map((unit, index) => (
                                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                                <CustomTextInput
                                                    placeholder={'unit'}
                                                    value={unit.name}
                                                    onChangeText={(value) => {
                                                        units[index].name = value;
                                                        setUnits([...units]);
                                                    }} />
                                                <TouchableOpacity onPress={() => {
                                                    if (units[index].id) {
                                                        setSelectedUnitForDeletion(units[index]);
                                                        setIsDeleteUnitModalVisible(true);
                                                        return;
                                                    }
                                                    units.splice(index, 1);
                                                    setUnits([...units]);
                                                }}>
                                                    <FontAwesomeIcon fontSize={24} icon={faTimes} color={theme.colors.iconColor} />
                                                </TouchableOpacity>
                                            </View>))
                                        }

                                        <TouchableOpacity onPress={() => {
                                            units.push({ name: '' });
                                            setUnits([...units]);
                                        }}>
                                            <MyAppText>+ {translate('addUnit')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                </ScrollView>;
                            } else if (selectedTab == 'commands') {
                                return <ScrollView>
                                    <View style={{ gap: 8 }}>
                                        <MyAppText>{translate('commands')}</MyAppText>

                                        {commands.map((command, index) => (
                                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                                <CustomTextInput
                                                    placeholder={'command'}
                                                    value={command.name}
                                                    onChangeText={(value) => {
                                                        commands[index].name = value;
                                                        setCommands([...commands]);
                                                    }} />
                                                <TouchableOpacity onPress={() => {
                                                    if (commands[index].id) {
                                                        setSelectedCommandForDeletion(commands[index]);
                                                        setIsDeleteUnitModalVisible(true);
                                                        return;
                                                    }
                                                    commands.splice(index, 1);
                                                    setCommands([...commands]);
                                                }}>
                                                    <FontAwesomeIcon fontSize={24} icon={faTimes} color={theme.colors.iconColor} />
                                                </TouchableOpacity>
                                            </View>))
                                        }

                                        <TouchableOpacity onPress={() => {
                                            commands.push({ name: '' });
                                            setCommands([...commands]);
                                        }}>
                                            <MyAppText>+ {translate('addCommand')}</MyAppText>
                                        </TouchableOpacity>
                                    </View>
                                </ScrollView>;
                            }
                        }}
                    </Tabs>

                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View>

                <DeleteModal
                    setModalVisible={setIsDeleteRoleModalVisible}
                    isModalVisible={isDeleteRoleModalVisible}
                    itemName={selectedRoleForDeletion?.name}
                    onSubmit={async () => {
                        if (selectedRoleForDeletion) {
                            await deleteRole(selectedRoleForDeletion);
                        }
                    }}
                />

                <DeleteModal
                    setModalVisible={setIsDeleteUnitModalVisible}
                    isModalVisible={isDeleteUnitModalVisible}
                    itemName={selectedUnitForDeletion?.name}
                    onSubmit={async () => {
                        if (selectedUnitForDeletion) {
                            await deleteUnit(selectedUnitForDeletion);
                        }
                    }}
                />

                <DeleteModal
                    setModalVisible={setIsDeleteCommandModalVisible}
                    isModalVisible={isDeleteCommandModalVisible}
                    itemName={selectedCommandForDeletion?.name}
                    onSubmit={async () => {
                        if (selectedCommandForDeletion) {
                            await deleteCommand(selectedCommandForDeletion);
                        }
                    }}
                />
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
