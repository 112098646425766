import React from 'react';
import LateralMenu from '../LateralMenu';
import { faVideo, faTag, faMap, faDownload, faMagnifyingGlass, faBell } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import logo from '../../../assets/logo_cameras.png';
import collapsedLogo from '../../../assets/cameras_icon.png';

export default function CamerasLateralMenu({ navigation, authenticatedUser, open, setOpen, selectedMenu }: LateralMenuProps) {
    const items = [];

    if (authenticatedUser) {
        items.push({
            icon: faMap,
            text: translate('map'),
            action: () => navigation.navigate('Cameras', { screen: 'CamerasMap' }),
            selected: selectedMenu == 'map'
        }, {
            icon: faVideo,
            text: translate('cameras'),
            action: () => navigation.navigate('Cameras', { screen: 'CamerasList' }),
            selected: selectedMenu == 'cameras'
        }, {
            icon: faWindows,
            text: translate('mosaics'),
            action: () => navigation.navigate('Cameras', { screen: 'Mosaic' }),
            selected: selectedMenu == 'mosaic'
        },);
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_manage_tags) {
        items.push({
            icon: faTag,
            text: translate('tags'),
            action: () => navigation.navigate('Cameras', { screen: 'CameraTags' }),
            selected: selectedMenu == 'camera-tags'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_manage_download_tags) {
        items.push({
            icon: faTag,
            text: translate('downloadsTags'),
            action: () => navigation.navigate('Cameras', { screen: 'DownloadTags' }),
            selected: selectedMenu == 'download-tags'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_download_video || authenticatedUser?.permissions.camera_watch_download) {
        items.push({
            icon: faDownload,
            text: 'Downloads',
            action: () => navigation.navigate('Cameras', { screen: 'Downloads' }),
            selected: selectedMenu == 'downloads'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_video_analysis || authenticatedUser?.permissions.camera_watch_video_analysis) {
        items.push({
            icon: faMagnifyingGlass,
            text: translate('zeusAI'),
            action: () => navigation.navigate('Cameras', { screen: 'VideoAnalysis' }),
            selected: selectedMenu === 'video-analysis'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.manage_camera_alert_groups) {
        items.push({
            icon: faBell,
            text: translate('alertGroups'),
            action: () => navigation.navigate('Cameras', { screen: 'AlertGroups' }),
            selected: selectedMenu == 'alert-groups'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            setOpen={setOpen}
            items={items}
            open={open}
            authenticatedUser={authenticatedUser}
        />
    );
}
