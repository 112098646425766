import React, { createContext, useState, useEffect } from 'react';
import { useColorScheme } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UnistylesRuntime } from 'react-native-unistyles';

interface ThemeContextValue {
    theme: Theme;
    toggleTheme: () => Promise<void>;
}

const defaultValue: ThemeContextValue = {
    theme: 'light',
    toggleTheme: async () => { }
};

const ThemeContext = createContext(defaultValue);

export const ThemeProvider = ({ children }: { children: React.ReactNode | undefined; }) => {
    const colorScheme = useColorScheme();
    const [theme, setTheme] = useState<Theme>(colorScheme || 'light');

    useEffect(() => {
        // Load saved theme from storage
        const getTheme = async () => {
            try {
                const savedTheme = await AsyncStorage.getItem('theme');
                if (savedTheme) {
                    setTheme(savedTheme as Theme);
                    UnistylesRuntime.setTheme(savedTheme as Theme);
                }
            } catch (error) {
                console.error('Error loading theme:', error);
            }
        };
        getTheme();
    }, []);

    useEffect(() => {
        // set theme to system selected theme
        if (colorScheme) {
            setTheme(colorScheme);
            UnistylesRuntime.setTheme(colorScheme);
        }
    }, [colorScheme]);

    const toggleTheme = async () => {
        // TODO: change those two lines to 'lightAlt' if you want to test the new layout
        let newTheme: Theme = 'light';
        if (theme === 'light') {
            newTheme = 'lightAlt';
        }
        if (theme === 'lightAlt') {
            newTheme = 'dark';
        }
        setTheme(newTheme);
        UnistylesRuntime.setTheme(newTheme);

        // Save selected theme to storage
        await AsyncStorage.setItem('theme', newTheme);
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
export default ThemeContext;
