import React, { useMemo, useState } from 'react';
import { Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import MyAppText from '../../../components/MyAppText';
import FormInput from '../../../components/formInput';
import MyDatetimeInput from '../../../components/MyDatetimeInput';
import FormActions from '../../../components/formActions';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { cameraService } from '../../../services/central-api/cameras';

export interface VideoAnalysisValues {
    prompt: string;
    msStart: number;
    msFinish: number;
}

interface Props {
    cameraId?: string;
}

function NewAnalysisModal({ cameraId }: Props) {
    const { styles } = useStyles(stylesheet);

    const [isVisible, setIsVisible] = useState(false);

    const [formValues, setFormValues] = useState({
        prompt: '',
        startAtDate: moment(new Date().getTime()).format('DD/MM/YYYY'),
        startAtHour: moment(new Date().getTime()).subtract(5, 'm').format('HH:mm:ss'),
        endAtDate: moment(new Date().getTime()).format('DD/MM/YYYY'),
        endAtHour: moment(new Date().getTime()).format('HH:mm:ss'),
    });

    const disabled = useMemo(() => {
        return Object.values(formValues).some(value => !value || value === '');
    }, [formValues]);

    async function createVideoAnalysis() {
        try {
            if (!cameraId) {
                return;
            }

            await cameraService.createVideoAnalysis(cameraId, {
                prompt: formValues.prompt,
                msStart: moment(`${formValues.startAtDate} ${formValues.startAtHour}`, 'DD/MM/YYYY HH:mm:ss').valueOf(),
                msFinish: moment(`${formValues.endAtDate} ${formValues.endAtHour}`, 'DD/MM/YYYY HH:mm:ss').valueOf(),
            });

            setIsVisible(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('requestAnalysisCreated'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View>
            <CustomButton icon={faPlus} onPress={() => setIsVisible(true)} text={translate('zeusAI')} />

            <Modal
                visible={isVisible}
                animationType='fade'
                transparent
                onRequestClose={() => {
                    setIsVisible(false);

                    setFormValues({
                        prompt: '',
                        startAtDate: moment(new Date().getTime()).format('DD/MM/YYYY'),
                        startAtHour: moment(new Date().getTime()).format('HH:mm:ss'),
                        endAtDate: moment(new Date().getTime()).format('DD/MM/YYYY'),
                        endAtHour: moment(new Date().getTime()).format('HH:mm:ss'),
                    });
                }}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalComponent}>
                        <MyAppText style={styles.modalTitle}>{translate('requestAnalysis')}</MyAppText>

                        <FormInput
                            label={translate('request')}
                            placeholder={translate('requestFieldPlaceholder')}
                            value={formValues.prompt}
                            onChangeText={(prompt) => {
                                setFormValues(old => ({
                                    ...old,
                                    prompt
                                }));
                            }}
                            invalid={(value: string) => {
                                return value == '';
                            }}
                            multiline
                            numberOfLines={4}
                        />

                        <MyAppText>{translate('analysisPeriod')}</MyAppText>

                        <View style={styles.dateFieldsContainer}>
                            <MyDatetimeInput
                                label={translate('beginIn')}
                                date={formValues.startAtDate}
                                onChangeDate={(startAtDate) => {
                                    setFormValues(old => ({
                                        ...old,
                                        startAtDate
                                    }));
                                }}
                                time={formValues.startAtHour}
                                onChangeTime={(startAtHour) => {
                                    setFormValues(old => ({
                                        ...old,
                                        startAtHour
                                    }));
                                }}
                            />
                            <MyDatetimeInput
                                label={translate('endIn')}
                                date={formValues.endAtDate}
                                onChangeDate={(endAtDate) => {
                                    setFormValues(old => ({
                                        ...old,
                                        endAtDate
                                    }));
                                }}
                                time={formValues.endAtHour}
                                onChangeTime={(endAtHour) => {
                                    setFormValues(old => ({
                                        ...old,
                                        endAtHour
                                    }));
                                }}
                            />
                        </View>

                        <FormActions
                            onSubmit={() => {
                                createVideoAnalysis();
                            }}
                            confirmText='toRequest'
                            onClose={() => {
                                setIsVisible(false);

                                setFormValues({
                                    prompt: '',
                                    startAtDate: moment(new Date().getTime()).format('DD/MM/YYYY'),
                                    startAtHour: moment(new Date().getTime()).format('HH:mm:ss'),
                                    endAtDate: moment(new Date().getTime()).format('DD/MM/YYYY'),
                                    endAtHour: moment(new Date().getTime()).format('HH:mm:ss'),
                                });
                            }}
                            disabled={disabled}
                        />
                    </View>
                </View>
            </Modal>
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
        justifyContent: 'center',
        alignItems: 'center',
    },

    modalComponent: {
        minWidth: {
            xs: undefined,
            md: 450
        },
        minHeight: 150,
        width: {
            md: undefined,
            xs: '90%'
        },
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },

    modalTitle: {
        fontSize: 18,
        fontWeight: '600'
    },

    dateFieldsContainer: {
        flexDirection: 'row',
        gap: {
            xs: 10,
            md: 50
        },
        flexWrap: 'wrap'
    }
}));

export default NewAnalysisModal;
