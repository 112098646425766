import React, { useEffect, useState } from 'react';
import { cameraService } from '../../services/central-api/cameras';
import { translate } from '../../services/translate';
import FilterDropdown from '../Filter/components/FilterDropdown';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function AlertGroupsDropdown({ value, setValue, zIndex }: Props) {
    const [selectableAlertGroups, setSelectableAlertGroups] = useState<{ label: string, value: number; }[]>([]);

    async function getAlertGroups() {
        try {
            const alertGroups = await cameraService.getAlertGroups({ page: 0, limit: 9999999 });
            setSelectableAlertGroups([
                { value: 0, label: translate('maleNone') },
                ...alertGroups.rows.map((alertGroup) => {
                    return {
                        value: alertGroup.id,
                        label: alertGroup.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getAlertGroups();
    }, []);

    return (
        <FilterDropdown
            label={translate('alertGroup')}
            items={selectableAlertGroups}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default AlertGroupsDropdown;
