import React from 'react';
import { View, ViewStyle, StyleProp } from 'react-native';
import { translate } from '../services/translate';
import CustomButton from './CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface FormInputProps {
    onSubmit: () => void,
    onClose: () => void,
    disabled: boolean;
    cancelText?: string;
    confirmText?: string;
    style?: StyleProp<ViewStyle>;
    isLoading?: boolean;
}

export default function FormActions({ onSubmit, onClose, disabled, style, cancelText = 'cancel', confirmText = 'save', isLoading }: FormInputProps) {
    const { styles } = useStyles(styleSheet);

    return (
        <View style={[styles.container, style]}>
            <CustomButton onPress={onClose} text={translate(cancelText)} type='secondary' />
            <CustomButton onPress={onSubmit} text={translate(confirmText)} disabled={disabled || isLoading} isLoading={isLoading} />
        </View>
    );
}

const styleSheet = createStyleSheet(() => ({
    container: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        columnGap: 15,
        zIndex: -30
    },
}));
