import React, { useEffect, useMemo, useState } from 'react';
import { View, TouchableOpacity, ActivityIndicator, Image, NativeSyntheticEvent, TextInputKeyPressEventData, Modal } from 'react-native';
import { translate } from '../services/translate';
import { centralAPI } from '../services/central-api';
import { invalidFirebaseToken, notificationService, tryingToCreateFirebaseTokenBeforeAuth } from '../services/notification';
import MyAppText from '../components/MyAppText';
import app from '../services/version.json';
import FormInput from '../components/formInput';
import FormActions from '../components/formActions';
import PasswordInput from '../components/passwordInput';
import { ClientError, UnexpectedError } from '../services/central-api/base-service';
import { WindowInformation } from '../services/window-information';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';
import CustomButton from '../components/CustomButton';
import { RootStackParamList } from '../typings/Params';
import { NavigationProp, useNavigation } from '@react-navigation/native';

export default function Login() {
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    const { styles, theme } = useStyles(stylesheet);
    const [isModalVisible, setModalVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const isSmartSampa = useMemo(() => window.location.hostname.includes('smartsampa'), [window.location]);
    const windowInfo = WindowInformation();

    useEffect(() => {
        (async () => {
            try {
                const isAuthenticated = await centralAPI.isAuthenticated();
                if (isAuthenticated) {
                    await centralAPI.saveLocationToken();
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'Menu' }],
                    });
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);


    async function login() {
        if (email == '' || password == '') {
            return;
        }
        setLoading(true);
        try {
            await centralAPI.authenticate({ email, password });
            await centralAPI.saveLocationToken();
            navigation.reset({
                index: 0,
                routes: [{ name: 'Menu' }],
            });

            try {
                await notificationService.registerToken();
                console.log('firebase token registered.');
            } catch (err) {
                if (err instanceof invalidFirebaseToken || err instanceof tryingToCreateFirebaseTokenBeforeAuth) {
                    console.warn(err);
                    return;
                }
                console.error(err);
                return;
            }
        } catch (err) {
            console.log(err);
            if (err instanceof ClientError) {
                setErrorMessage(translate(err.message));
            } else if (err instanceof UnexpectedError || err instanceof TypeError) {
                setErrorMessage(translate('unexpectedError'));
            } else if (err instanceof DOMException) {
                setErrorMessage(translate('timeoutLoginException'));
            }

        } finally {
            setLoading(false);
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            if (email == '' || password == '') {
                return;
            }
            login();
        }
    }

    async function forgotPassword() {
        try {
            await centralAPI.forgotPassword(email);
            setModalVisible(false);
            setPassword('');
            setEmail('');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <View style={styles.container}>
                {isSmartSampa && !windowInfo.isMobileOrTablet ?
                    <Image style={styles.image(370)} source={require(`../../assets/big_smartsampa_logo_${UnistylesRuntime.themeName}.png`)} />
                    :
                    <Image style={styles.image(300)} source={require(`../../assets/SentinelX_logo_${UnistylesRuntime.themeName}.png`)} />
                }
                <View style={styles.formContainer}>
                    <FormInput
                        label={translate('email')}
                        placeholder=''
                        value={email}
                        onChangeText={setEmail}
                        invalid={() => false}
                        keyboardType='email-address'
                        onKeyPress={handleKeyDown}
                    />
                    <PasswordInput
                        label={translate('password')}
                        placeholder=''
                        value={password}
                        onChangeText={setPassword}
                        invalid={() => password === ''}
                        onKeyPress={handleKeyDown}
                    />
                    {errorMessage ? <MyAppText style={styles.error}>{errorMessage}</MyAppText> : null}
                    {loading ?
                        <View style={{ height: 40 }}>
                            <ActivityIndicator size='small' color={theme.colors.iconColor} />
                        </View>
                        : <CustomButton text={translate('login')} onPress={login} />
                    }
                    <TouchableOpacity onPress={() => setModalVisible(true)}>
                        <MyAppText style={[styles.label, { textDecorationLine: 'underline' }]}>
                            {translate('forgotPassword')}
                        </MyAppText>
                    </TouchableOpacity>
                    <MyAppText style={{ alignSelf: 'center' }}>{translate('version')}: {app[0].version}</MyAppText>
                </View>
            </View>
            <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalBody}>
                        <View style={{ rowGap: 10 }}>
                            <MyAppText style={styles.modalHeaderText}>
                                {translate('forgotYourPassword')}
                            </MyAppText>
                            <MyAppText style={styles.modalBodyText}>
                                {translate('emailToRedefinePassword')}
                            </MyAppText>
                        </View>
                        <View style={{ height: 65, maxWidth: 380, minWidth: 300 }}>
                            <FormInput
                                label={translate('email')}
                                placeholder={translate('email')}
                                value={email}
                                onChangeText={setEmail}
                                invalid={() => false}
                            />
                        </View>
                        <FormActions
                            onSubmit={forgotPassword}
                            onClose={() => setModalVisible(false)}
                            disabled={email === ''}
                            confirmText={'send'} />
                    </View>
                </View>
            </Modal>
        </ >
    );
}

const stylesheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 50,
    },
    formContainer: {
        flexDirection: 'column',
        gap: 30,
        maxWidth: 375,
        width: '100%',
        padding: 5
    },
    label: {
        color: theme.colors.labelColor,
        alignSelf: 'center',
    },
    error: {
        color: 'red',
        alignSelf: 'center',
        height: 20
    },
    modalContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1,
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        paddingHorizontal: 90,
        paddingVertical: 50,
        rowGap: 20,
        maxWidth: 570,
        height: 325
    },
    modalHeaderText: {
        fontSize: 24,
        color: theme.colors.labelColor,
        fontWeight: 'bold'
    },
    modalBodyText: {
        fontSize: 14,
        color: theme.colors.disabledText,
    },
    image: (width) => ({
        height: 60,
        width,
        resizeMode: 'contain'
    }),
}));
