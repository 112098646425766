import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import PeopleAllowListDropdown from '../../../components/facial/PeopleAllowListDropdown';
import CameraDropdown from '../../../components/facial/CameraDropdown';

interface Props {
    filters: FacialParamList['AllowListGroup'];
    setFilters: React.Dispatch<React.SetStateAction<FacialParamList['AllowListGroup']>>;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<FacialParamList, 'AllowListGroup'>>>();
    const [selectedCameraId, setSelectedCameraId] = useState<string>(filters.cameraId ?? '');
    const [selectedPersonId, setSelectedPersonId] = useState<number>(filters.personId ?? 0);

    const handleCleanFilter = () => {
        setTextFilter('');
        setSelectedCameraId('');
        setSelectedPersonId(0);
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            page: 1,
            textFilter,
            cameraId: selectedCameraId ? selectedCameraId : undefined,
            personId: selectedPersonId !== 0 ? selectedPersonId : undefined,
        });

        setFilters({
            ...filters,
            page: 1,
            textFilter,
            cameraId: selectedCameraId ? selectedCameraId : undefined,
            personId: selectedPersonId !== 0 ? selectedPersonId : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />
            <CameraDropdown setValue={setSelectedCameraId} value={selectedCameraId} zIndex={3} />
            <PeopleAllowListDropdown setValue={setSelectedPersonId} value={selectedPersonId} zIndex={2} />
        </Filters>
    );
}


export default Filter;
