import React, { useEffect, useState } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { accessService } from '../../../services/central-api/access';
import { translate } from '../../../services/translate';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
    disabled: boolean;
}

function InstallationCompanyDropdown({ value, setValue, zIndex, disabled }: Props) {
    const [selectableItems, setSelectableItems] = useState<{ label: string, value: number; }[]>([]);

    async function getItems() {
        try {
            const items = await accessService.getInstallationCompaniesSimplified();
            setSelectableItems(items.map((item: DropdownResource) => {
                return { label: item.name, value: item.id };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    return (
        <FilterDropdown
            label={translate('installationCompany')}
            items={selectableItems}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
        />
    );
}

export default InstallationCompanyDropdown;
