import React from 'react';
import { View, Modal } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from './CustomButton';


interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    onCancel?: () => void;
    itemName?: string;
    customMessage?: string;
    customConfirm?: string;
}

export default function DeleteModal({ isModalVisible, setModalVisible, onSubmit, onCancel, itemName, customMessage, customConfirm }: ModalProps) {
    const { styles } = useStyles(styleSheet);

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onCancel && onCancel();
                setModalVisible(false);
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={styles.body}>
                    <MyAppText style={styles.deleteConfirmationText}>{customMessage ?? (itemName ? `${translate('confirm_delete')}: ${itemName}` : `${translate('confirm_delete')}?`)}</MyAppText>
                    <View style={styles.deleteButtonsView}>
                        <CustomButton
                            onPress={() => {
                                onCancel && onCancel();
                                setModalVisible(false);
                            }}
                            text={translate('cancel')}
                            type='secondary' />
                        <CustomButton
                            onPress={() => {
                                onSubmit();
                                setModalVisible(false);
                            }}
                            style={{ backgroundColor: '#FE0127' }}
                            text={customConfirm ?? translate('delete')} />
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    body: {
        backgroundColor: theme.colors.cardBackground,
        flexDirection: 'column',
        borderRadius: 4,
        padding: 25,
        rowGap: 20,
    },
    headerText: {
        fontSize: 20,
    },
    buttonsView: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: 20
    },
    deleteConfirmationText: {
        fontSize: 20,
    },
    deleteButtonsView: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    }
}));
