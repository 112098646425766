import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Tabs } from '../../../components/Tabs';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import { facialService } from '../../../services/central-api/facial';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    allowListGroupId?: number;
}

export default function AllowListGroupModal({ onClose, allowListGroupId, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState<string>('');
    const [selectablePeople, setSelectablePeople] = useState<SelectableItem[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<SelectableItem[]>([]);
    const [selectableCameras, setSelectableCameras] = useState<SelectableItem[]>([]);
    const [selectedCameras, setSelectedCameras] = useState<SelectableItem[]>([]);
    const [displayFilter, setDisplayFilter] = useState<string[]>([]);
    const [groupCameraIds, setGroupCameraIds] = useState<string[]>([]);
    const [groupPersonIds, setGroupPersonIds] = useState<number[]>([]);

    async function getAllowListGroup(id: number, cameras: FacialSimplifiedCamera[], people: FacialSimplifiedPerson[]) {

        const groupCamerasIds: Record<string, boolean> = {};
        const groupPeopleIds: Record<string, boolean> = {};


        const allowListGroup = await facialService.getAllowListGroup(id);
        setName(allowListGroup.name);
        setDescription(allowListGroup.description ?? '');

        const selectedPeople = allowListGroup.allowListPeople.map((person) => {
            return {
                id: person.id,
                name: person.name
            };
        });
        setSelectedPeople(selectedPeople);
        setGroupPersonIds(allowListGroup.allowListPeople.map((person) => person.id || 0));

        const selectedCameras = allowListGroup.allowListCameras.map((camera) => {
            return {
                id: camera.id,
                name: camera.title
            };
        });
        setSelectedCameras(selectedCameras);
        setGroupCameraIds(allowListGroup.allowListCameras.map((camera) => camera.id || ''));

        selectedCameras.map((camera) => groupCamerasIds[camera.id] = true);
        selectedPeople.map((person) => groupPeopleIds[person.id] = true);

        setSelectablePeople(people
            .filter((person) => !selectedPeople.find((groupPerson) => groupPerson.id == person.id))
            .map((person) => {
                return {
                    id: person.id,
                    name: person.name
                };
            })
        );

        setSelectableCameras(cameras
            .filter((camera) => !selectedCameras.find((groupCamera) => groupCamera.id == camera.id))
            .map((camera) => {
                return {
                    id: camera.id,
                    name: camera.title
                };
            })
        );

    }

    async function loadPageInfo() {
        try {


            const [cameras, people] = await Promise.all([
                facialService.getSimplifiedCameras(),
                facialService.getAllowListPeople(),
            ]);

            if (allowListGroupId) {
                await getAllowListGroup(allowListGroupId, cameras, people);
            } else {
                setSelectablePeople(people
                    .map((person) => {
                        return {
                            id: person.id,
                            name: person.name
                        };
                    })
                );

                setSelectableCameras(cameras
                    .map((camera) => {
                        return {
                            id: camera.id,
                            name: camera.title
                        };
                    })
                );

            }


            setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));


        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function clearValues() {
        setName('');
        setDescription('');
        setSelectablePeople([]);
        setSelectedPeople([]);
        setSelectableCameras([]);
        setSelectedCameras([]);
    }

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    function isEmpty(value: string) {
        return value.trim() == '';
    }

    function isFormValid() {
        return !isEmpty(name);
    }

    async function formSubmit() {
        try {
            const selectedPersonIds = selectedPeople.map(person => Number(person.id));
            const peopleToAdd = selectedPersonIds.filter((selectedPersonId) => !groupPersonIds.includes(selectedPersonId));
            const peopleToRm = groupPersonIds.filter((groupPersonId) => !selectedPersonIds.includes(groupPersonId));

            const selectedCameraIds = selectedCameras.map((camera) => String(camera.id));
            const camerasToAdd = selectedCameraIds.filter((selectedCameraId) => !groupCameraIds.includes(selectedCameraId));
            const camerasToRm = groupCameraIds.filter((groupCameraId) => !selectedCameraIds.includes(groupCameraId));


            if (allowListGroupId) {
                await facialService.updateAllowListGroup({
                    id: allowListGroupId,
                    name,
                    description,
                    camerasToAdd,
                    peopleToAdd,
                    camerasToRm,
                    peopleToRm,
                });
            } else {
                await facialService.createAllowListGroup({
                    name,
                    description,
                    camerasToAdd,
                    peopleToAdd,
                });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                setModalVisible(false);
                onClose();
                clearValues();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 635 }]}>
                    <Tabs tabs={[{
                        key: 'details',
                        label: translate('details')
                    }, {
                        key: 'people',
                        label: translate('people')
                    }, {
                        key: 'cameras',
                        label: translate('cameras')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'details') {
                                return (<>
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isEmpty}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={() => false}
                                    />
                                </>);
                            } else if (selectedTab == 'people') {
                                return <SelectableList
                                    selectableLabel={translate('people')}
                                    selectableContent={selectablePeople}
                                    selectedLabel={translate('participants')}
                                    selectedContent={selectedPeople}
                                />;
                            } else if (selectedTab == 'cameras') {
                                return <SelectableList
                                    displayFilter={displayFilter}
                                    selectableLabel={translate('cameras')}
                                    selectableContent={selectableCameras}
                                    selectedLabel={translate('participants')}
                                    selectedContent={selectedCameras}
                                />;
                            }
                        }}
                    </Tabs>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View >
            </View>
        </Modal >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25,
        height: 600
    }
}));
