import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import MosaicEditModal from './MosaicEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faEdit } from '@fortawesome/free-regular-svg-icons';
import { translate } from '../../../services/translate';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { Pagination } from '../../../components/Pagination';
import { cameraService } from '../../../services/central-api/cameras';
import MyDataTable from '../../../components/MyDataTable';
import { ClientError } from '../../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../../typings/Params';
import CustomButton from '../../../components/CustomButton';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import useFilters from '../../../components/Filter/hooks/useFilters';
import Filter from './Filters';

export default function Mosaics({ navigation }: { navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<CamerasParamList, 'Mosaic'>>();

    const [filters, setFilters] = useFilters<CamerasParamList['Mosaic']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [mosaic, setMosaic] = useState<Mosaic>();
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [mosaicsList, setMosaicsList] = useState<Mosaic[]>([]);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [totalItems, setTotalItems] = useState(0);

    async function getMosaicsList() {
        try {
            setIsLoading(true);
            const response = await cameraService.getMosaics(filters);
            setMosaicsList(response.rows);
            setTotalItems(response.count);

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getMosaicsList();
    }, [filters]);

    function handleFormClose() {
        setMosaic(undefined);
        setModalVisible(false);
        getMosaicsList();
    }

    function handleFormNew() {
        setMosaic(undefined);
        setModalVisible(true);
    }

    function handleEditClick(mosaic: Mosaic) {
        setMosaic(mosaic);
        setModalVisible(true);
    }

    function handleOpenClick(mosaic: Mosaic) {
        navigation.navigate('MosaicDetail', { id: mosaic.id });
    }

    function showDeleteConfirmationDialog(mosaic: Mosaic) {
        setMosaic(mosaic);
        setDeleteModalVisible(true);
    }

    async function confirmDelete(mosaic: Mosaic) {
        try {
            if (mosaic?.id) {
                await cameraService.deleteMosaic(mosaic.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });

                setDeleteModalVisible(false);
                getMosaicsList();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchMosaic')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filter
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <CustomButton icon={faPlus} text={translate('create')} onPress={handleFormNew} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        onRowClicked={handleOpenClick}
                        columns={[
                            {
                                name: translate('title'),
                                selector: row => row.title,
                                wrap: true,
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => handleEditClick(row)}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => showDeleteConfirmationDialog(row)}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                            }
                        ]}
                        data={mosaicsList}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <MosaicEditModal
                onClose={handleFormClose}
                mosaic={mosaic}
                isModalVisible={isModalVisible}
            />

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={String(mosaic?.title) || ''}
                onSubmit={async () => {
                    if (mosaic) {
                        await confirmDelete(mosaic);
                    }
                }}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
