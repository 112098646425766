import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { TextMask } from 'react-native-masked-text';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export function GuardianAppOccurrence({ occurrence, navigation }: { occurrence: Occurrence, navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);

    if (occurrence.triggerType !== 'guardian_app' || !occurrence.externalReference) {
        return <></>;
    }

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: theme.colors.textColor, fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: theme.colors.textColor }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={theme.colors.iconColor} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('guardianAppAlert')}
                </MyAppText>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 15 }}>
                    <View style={{ gap: 5 }}>
                        <MyAppText style={styles.text}>{translate('requester')}</MyAppText>
                        <MyAppText>{translate('name')}: {occurrence.externalReference.name}</MyAppText>
                        <MyAppText>{translate('phone')}: {occurrence.externalReference.phone}</MyAppText>
                        <MyAppText>{translate('phone')} IMEI: {occurrence.externalReference.imei}</MyAppText>
                        <View style={{ flexDirection: 'row', gap: 5 }}>
                            <MyAppText>CPF:</MyAppText>
                            <TextMask
                                style={[{ fontFamily: 'Open Sans', color: theme.colors.iconColor, fontSize: 14 }]}
                                value={occurrence.externalReference.document}
                                type={'cpf'}
                            />
                        </View>
                        <MyAppText>{translate('email')}: {occurrence.externalReference.email}</MyAppText>
                        <MyAppText>{translate('address')}: {occurrence.externalReference.address}</MyAppText>
                        <MyAppText>{translate('birth')}: {moment(new Date(occurrence.externalReference.birth)).format('DD/MM/YYYY')}</MyAppText>
                        {occurrence.externalReference.aggressorName ?
                            <MyAppText>{translate('aggressorName')}: {occurrence.externalReference.aggressorName}</MyAppText>
                            : null}
                    </View>
                    <View style={{ gap: 3 }}>
                        <MyAppText style={styles.text}>{translate('location')}</MyAppText>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(new Date(occurrence.createdAt)).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {occurrence.address}
                            </MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    card: {
        gap: 20,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 14,
        display: 'flex',
        alignItems: 'flex-start',
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    detectionInfoText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    text: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    alertText: {
        color: theme.colors.textColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    cardText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    cardBoldText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
}));
