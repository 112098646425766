import React, { useEffect, useState, useCallback, useRef } from 'react';
import { View } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import EventList from '../../../components/lpr/EventList';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface LiveEventsParams {
    navigation: Navigation;
}

export default function LiveEvents({ navigation }: LiveEventsParams) {
    const { styles, theme } = useStyles(styleSheet);

    const route = useRoute<RouteProp<LPRParamList, 'LprLiveEvents'>>();
    const [filters, setFilters] = useFilters<LPRParamList['LprLiveEvents']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
    });
    const loaders = useRef({ didSelectedCamerasLoad: false });
    const [isCameraPickerOpen, setIsCameraPickerOpen] = useState(false);
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>(filters.cameras ?? []);
    const [selectableCameraFilter, setSelectableCameraFilter] = useState<{ label: string, value: string; }[]>([]);

    async function getCameras() {
        try {
            const cameras = await licensePlateRecognitionService.getCameras();
            setSelectableCameraFilter(cameras.map((camera) => {
                return {
                    value: camera.id,
                    label: camera.title
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getCameras();
    }, []);

    useEffect(() => {
        if (!loaders.current.didSelectedCamerasLoad) {
            loaders.current.didSelectedCamerasLoad = true;
            return;
        }
        setFilters({
            ...filters,
            cameras: selectedCameraFilter,
        });
        navigation.setParams({
            ...filters,
            cameras: selectedCameraFilter,
        });
    }, [selectedCameraFilter]);

    useEffect(() => {
        getDetections();

        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will only work when the page is active
            if (!window.location.href.endsWith('LPR/live-events')) {
                return;
            }

            getDetections();
        }, 2000);
        return () => clearInterval(interval);
    }, [filters]);

    const [isLoading, setIsLoading] = useState(false);
    const [occurrences, setOccurrences] = useState<LprDetection[]>([]);

    async function getDetections() {
        try {
            setIsLoading(true);

            const newOccurrences = await licensePlateRecognitionService.getDetections({
                ...filters,
                limit: 50
            });

            if (filters.page == 0) {
                occurrences.length = 0;
            }

            occurrences.push(...newOccurrences);
            setOccurrences(occurrences);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <View style={styles.container}>
            <View style={{ minHeight: 65 }}>
                <MyAppText style={styles.filterText}>{translate('cameras')}</MyAppText>
                <MyDropDownPicker
                    open={isCameraPickerOpen}
                    value={selectedCameraFilter}
                    items={selectableCameraFilter}
                    setOpen={setIsCameraPickerOpen}
                    setValue={setSelectedCameraFilter}
                    multiple={true}
                    mode='BADGE'
                    searchable={true}
                    borderColor={theme.colors.field.border}
                />
            </View>
            <EventList
                events={occurrences}
                navigation={navigation}
                hasMoreResults={false}
                isLoading={isLoading}
                isPolling={true}
                page='live-events'
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    filterText: {
        color: theme.colors.field.text,
        fontSize: 16,
        height: 25,
    },
}));
