import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { dispatchService } from '../../../services/central-api/dispatch';
import AssignmentModal from './AssignmentModal';
import MyDataTable from '../../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterInput from '../../../components/Filter/components/FilterInput';
import CustomButton from '../../../components/CustomButton';
import Filter from './Filters';
import useFilters from '../../../components/Filter/hooks/useFilters';

export default function Assignments({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DispatchParamList, 'Assignments'>>();

    const [filters, setFilters] = useFilters<DispatchParamList['Assignments']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function getAssignments() {
        try {
            setIsLoading(true);
            const response = await dispatchService.getAssignments(filters);
            setAssignments(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(assignment: Assignment) {
        try {
            await dispatchService.deleteAssignment(assignment.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setDeleteModalVisible(false);
            getAssignments();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleFormClose() {
        setSelectedAssignment(undefined);
        setModalVisible(false);
        getAssignments();
    }

    useEffect(() => {
        getAssignments();
    }, [filters]);

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.headerContent}>
                    <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                        <FilterInput
                            label={translate('searchAssignment')}
                            value={textFilter}
                            placeholder={translate('typeToSearch')}
                            onChange={text => {
                                setTextFilter(text);
                            }}
                            onKeyPress={handleKeyDown}
                        />
                    </View>
                    <View style={{ flex: 1 }}></View>
                    <View style={styles.buttonsContainer}>
                        <Filter
                            authenticatedUser={authenticatedUser}
                            filters={filters}
                            setFilters={setFilters}
                            textFilter={textFilter}
                            setTextFilter={setTextFilter} />
                        <CustomButton
                            icon={faPlus}
                            text={translate('create')}
                            onPress={() => {
                                setSelectedAssignment(undefined);
                                setModalVisible(true);
                            }} />
                    </View>
                </View>
                <View style={{ flex: 1 }}>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    grow: 5
                                },
                                {
                                    name: translate('actingBody'),
                                    selector: row => row.actingBody.name,
                                    wrap: true,
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedAssignment(row);
                                                setModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedAssignment(row);
                                                setDeleteModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={assignments}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination
                        currentPage={filters.page}
                        totalItems={totalItems}
                        setPage={page => {
                            setFilters(old => ({
                                ...old,
                                page
                            }));

                            navigation.setParams({
                                ...route.params,
                                page: page + 1
                            });
                        }}
                        pageSize={filters.limit}
                        setPageSize={pageSize => {
                            setFilters(old => ({
                                ...old,
                                limit: pageSize,
                                page: 0,
                            }));

                            navigation.setParams({
                                ...route.params,
                                limit: pageSize,
                                page: 0,
                            });
                        }}
                    />
                </View>
            </View>
            {/* Delete Modal */}
            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={String(selectedAssignment?.name) || ''}
                onSubmit={async () => {
                    try {
                        if (selectedAssignment) {
                            await confirmDelete(selectedAssignment);
                        }
                        Toast.show({
                            type: 'sentinelxSuccess',
                            text1: translate('ActionSuccessfully'),
                        });
                    } catch (err) {
                        if (err instanceof ClientError) {
                            return Toast.show({
                                type: 'sentinelxError',
                                text1: translate(err.message),
                            });
                        }

                        console.error(err);
                        Toast.show({
                            type: 'sentinelxError',
                            text1: translate('unexpectedError'),
                        });
                    }
                }}
                onCancel={() => {
                    setSelectedAssignment(undefined);
                }}
            />

            <AssignmentModal
                isModalVisible={isModalVisible}
                authenticatedUser={authenticatedUser}
                assignmentId={selectedAssignment?.id}
                onClose={handleFormClose}
            />
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
