import React from 'react';
import { TextInput, View, ViewStyle } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { translate } from '../services/translate';

interface Props {
    placeholder?: string;
    value: string;
    onChangeText: (value: string) => void;
    style?: ViewStyle;
    multiline?: boolean;
}

function CustomTextInput({ value, placeholder, onChangeText, style, multiline }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <View style={style ?? { flexGrow: 1 }}>
            <TextInput
                style={[styles.filterInput(multiline), styles.textColor(value)]}
                value={value}
                onChangeText={onChangeText}
                placeholder={placeholder ? translate(placeholder) : undefined}
                multiline={multiline}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    filterInput: (multiline?: boolean) => ({
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.container.background,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.container.border,
        height: multiline ? 150 : 40,
        minHeight: multiline ? 50 : 40,
        fontSize: 16,
        padding: 10,
    }),

    textColor: (text?: string) => ({
        color: text ? theme.colors.field.text : theme.colors.disabledText
    })
}));

export default CustomTextInput;
