import React from 'react';
import { Modal, TouchableOpacity, View } from 'react-native';
import { translate } from '../../../services/translate';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import CopyToClipboardInput from '../../../components/CopyToClipboardInput';

interface ModalProps {
    cameraPassword: string;
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PasswordModal({ cameraPassword, isModalVisible, setIsModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setIsModalVisible(false)}>
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <View style={styles.informationRow}>
                        <MyAppText>{translate('passwordInformation')}</MyAppText>
                        <TouchableOpacity onPress={() => setIsModalVisible(false)}>
                            <FontAwesomeIcon
                                icon={faXmark}
                                color={theme.colors.iconColor}
                                style={{ alignSelf: 'flex-end' }}
                            />
                        </TouchableOpacity>
                    </View>
                    <CopyToClipboardInput label={translate('password')} value={cameraPassword} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        width: '25%',
        minHeight: 150,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25,
        justifyContent: 'space-between'
    },
    informationRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));
