import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../MyAppText';

interface Props {
    label: string;
    value: number | null;
    onChange: Dispatch<SetStateAction<number | null>>;
    maxValue?: number;
    placeholder: string;
}

function FilterInputNumeric({ label, value, onChange, maxValue, placeholder }: Props) {
    const { styles } = useStyles(stylesheet);

    const isPlaceholder = useMemo(() => !value && value === null, [value]);
    return (
        <View style={styles.inputContainer}>
            <MyAppText style={styles.filterText}>{label}</MyAppText>
            <input
                style={styles.filterInput(isPlaceholder)}
                value={value !== null ? value : ''}
                onChange={(e) => {
                    const parsedValue = e.target.value.trim() === '' ? null : parseFloat(e.target.value);
                    if (parsedValue === null || !isNaN(parsedValue)) {
                        onChange(parsedValue);
                    }
                }}
                placeholder={placeholder}
                type='number'
                step='1'
                max={maxValue}
                min={0}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        minWidth: 200,
    },

    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },

    filterInput: (isPlaceholder: boolean) => ({
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.field.background,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.field.border,
        height: 40,
        minHeight: 40,
        fontSize: 16,
        color: isPlaceholder ? theme.colors.placeholder : theme.colors.field.text,
        paddingLeft: 10,
        paddingRight: 10,
        paddingVertical: 3,
    })
}));

export default FilterInputNumeric;
