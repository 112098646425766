import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import Filters from '../../../components/Filter';
import { useState } from 'react';
import moment from 'moment';
import FilterDatePicker from '../../../components/Filter/components/FilterDateInput';
import { translate } from '../../../services/translate';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';
import MarkerDropdown from '../../../components/facial/MarkerDropdown';
import CamerasDropdown from '../../../components/facial/CamerasDropdown';
import PeopleDropdown from '../../../components/facial/PeopleDropdown';
import { View } from 'react-native';
import FilterInputNumeric from '../../../components/Filter/components/FilterInputNumeric';
import PersonTypeDropdown from '../../../components/facial/PersonTypeDropdown';
import { PersonType } from '../../../services/central-api/facial';

interface Props {
    filters: FacialParamList['Search'];
    setFilters: React.Dispatch<React.SetStateAction<FacialParamList['Search']>>;
}

export default function FacialSearchFilter({ filters, setFilters }: Props) {
    const navigation = useNavigation<NavigationProp<FacialParamList, 'Search'>>();

    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beggingIn ? new Date(filters.beggingIn) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beggingIn ? moment(filters.beggingIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? new Date(filters.endIn) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [onlyWithPerson, setOnlyWithPerson] = useState<boolean>(filters.onlyWithPerson ?? true);
    const [selectedMarkersIds, setSelectedMarkersIds] = useState<number[]>(filters.markers ?? []);
    const [selectedCamerasIds, setSelectedCamerasIds] = useState<string[]>(filters.cameras ?? []);
    const [selectedPersonId, setSelectedPersonId] = useState<number>(filters.personId ?? 0);
    const [confidence, setConfidence] = useState<number | null>(filters.confidence ?? null);
    const [personType, setPersonType] = useState<PersonType | ''>(filters.personType ?? '');

    const handleCleanFilter = () => {
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
        setOnlyWithPerson(true);
        setSelectedMarkersIds([]);
        setSelectedCamerasIds([]);
        setSelectedPersonId(0);
        setConfidence(null);
        setPersonType('');
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        setFilters({
            ...filters,
            page: 0,
            onlyWithPerson: onlyWithPerson,
            markers: selectedMarkersIds.length ? selectedMarkersIds : undefined,
            cameras: selectedCamerasIds.length ? selectedCamerasIds : undefined,
            beggingIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            personId: selectedPersonId !== 0 ? selectedPersonId : undefined,
            confidence: confidence ? confidence : undefined,
            personType: personType !== '' ? personType : undefined,
        });

        navigation.setParams({
            page: 1,
            onlyWithPerson: onlyWithPerson,
            markers: selectedMarkersIds.length ? selectedMarkersIds : undefined,
            cameras: selectedCamerasIds.length ? selectedCamerasIds : undefined,
            beggingIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            personId: selectedPersonId !== 0 ? selectedPersonId : undefined,
            confidence: confidence ? confidence : undefined,
            personType: personType !== '' ? personType : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('beginIn')}
                zIndex={6}
            />
            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('endIn')}
                zIndex={5}
            />
            <View>
                <FilterCheckBox label={translate('onlyByReference')} value={onlyWithPerson} onChange={setOnlyWithPerson} />
            </View>
            <MarkerDropdown setValue={setSelectedMarkersIds} value={selectedMarkersIds} zIndex={5} />
            <CamerasDropdown setValue={setSelectedCamerasIds} value={selectedCamerasIds} zIndex={4} />
            <PeopleDropdown setValue={setSelectedPersonId} value={selectedPersonId} zIndex={3} />
            <PersonTypeDropdown setValue={setPersonType} value={personType} zIndex={2} includeEmptyItem={true} />
            <FilterInputNumeric
                label={translate('confidence')}
                value={confidence}
                onChange={setConfidence}
                placeholder={translate('typeAValue')}
                maxValue={100}
            />
        </Filters>
    );
}



