import React, { useEffect, useState } from 'react';
import { translate } from '../../../services/translate';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';

export const VIDEO_ANALYSIS_STATUS: Record<VideoAnalysisStatus, string> = {
    failed: 'failed',
    pending: 'pending',
    success: 'success'
};

interface Props {
    value?: string;
    onChange: (value: string) => void;
    zIndex: number;
}

function StatusDropdown({ value, onChange, zIndex }: Props) {
    const [selectedStatus, setSelectedStatus] = useState<string>(value ?? '');

    useEffect(() => {
        onChange(selectedStatus);
    }, [selectedStatus]);


    const DROPDOWN_VALUES = Object.entries(VIDEO_ANALYSIS_STATUS).map(([key, value]) => ({
        label: translate(value),
        value: key
    }));

    return (
        <FilterDropdown
            label={translate('status')}
            items={DROPDOWN_VALUES}
            multiple={false}
            setValue={setSelectedStatus}
            value={selectedStatus}
            zIndex={zIndex}
        />
    );
}

export default StatusDropdown;
