import React from 'react';
import EventDetail from '../EventDetail';

interface EventDetailParams {
    navigation: Navigation;
    route: {
        params: {
            eventId: string;
        };
    };
}

export default function LiveEventDetail({ navigation, route }: EventDetailParams) {
    return <EventDetail navigation={navigation} route={route} module={'facial'} page={'live-events'} />;
}
