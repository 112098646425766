import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { Pagination } from '../../../components/Pagination';
import { cameraService } from '../../../services/central-api/cameras';
import { ClientError } from '../../../services/central-api/base-service';
import { CamerasParamList } from '../../../typings/Params';
import { RouteProp, useRoute } from '@react-navigation/native';
import MyDataTable from '../../../components/MyDataTable';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterInput from '../../../components/Filter/components/FilterInput';
import CustomButton from '../../../components/CustomButton';
import useFilters from '../../../components/Filter/hooks/useFilters';
import UpsertAlertGroupModal from './UpsertAlertGroupModal';
import Filter from './Filters';

export default function AlertGroups({ navigation }: { navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<CamerasParamList, 'AlertGroups'>>();

    const [alertGroups, setAlertGroups] = useState<AlertGroupList[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isUpsertAlertGroupModalVisible, setUpsertAlertGroupModalVisible] = useState<boolean>(false);
    const [selectedAlertGroup, setSelectedAlertGroup] = useState<AlertGroupList>();

    const [filters, setFilters] = useFilters<CamerasParamList['AlertGroups']>({
        ...route.params,
        page: Math.max(route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [totalItems, setTotalItems] = useState(0);

    async function getAlertGroups(): Promise<void> {
        try {
            setIsLoading(true);

            const response = await cameraService.getAlertGroups(filters);
            setAlertGroups(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAlertGroups();
    }, []);

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            setFilters(old => ({
                ...old,
                textFilter
            }));

            navigation.setParams({
                ...filters,
                textFilter
            });
        }
    }

    function showDeleteConfirmationDialog(alertGroup: AlertGroupList) {
        setSelectedAlertGroup(alertGroup);
        setDeleteModalVisible(true);
    }

    function handleEditClick(alertGroup: AlertGroupList) {
        setSelectedAlertGroup(alertGroup);
        setUpsertAlertGroupModalVisible(true);
    }

    function handleFormClose() {
        setSelectedAlertGroup(undefined);
        setUpsertAlertGroupModalVisible(false);
        getAlertGroups();
    }

    async function confirmDelete(alertGroup: AlertGroupList) {
        try {
            await cameraService.deleteAlertGroup(alertGroup.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            setDeleteModalVisible(false);
            getAlertGroups();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchAlertGroup')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filter
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                        setSelectedAlertGroup(undefined);
                        setUpsertAlertGroupModalVisible(true);
                    }} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true,
                                grow: 3
                            },
                            {
                                name: translate('cameraCount'),
                                selector: row => row.cameraCount || 0,
                                wrap: true,
                                grow: 1,
                                center: true,
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => handleEditClick(row)}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => showDeleteConfirmationDialog(row)}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                            }
                        ]}
                        data={alertGroups}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <UpsertAlertGroupModal
                isModalVisible={isUpsertAlertGroupModalVisible}
                setModalVisible={setUpsertAlertGroupModalVisible}
                onClose={handleFormClose}
                alertGroupId={selectedAlertGroup?.id}
            />

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={String(selectedAlertGroup?.name) || ''}
                onSubmit={async () => {
                    if (selectedAlertGroup) {
                        await confirmDelete(selectedAlertGroup);
                    }
                }}
            />

        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
