import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import { AccessParamList } from '../../../typings/Params';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import ActingBodiesDropdown from '../../../components/Dropdowns/ActingBodiesDropdown';
import ActingBodiesUnitsDropdown from '../../../components/Dropdowns/ActingBodiesUnitsDropdown';
import MyPhoneInput from '../../../components/MyPhoneInput';
import MyCpfInput from '../../../components/MyCpfInput';
import RolesDropdown from '../../../components/Dropdowns/RolesDropdown';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';

interface Props {
    filters: AccessParamList['Users'];
    setFilters: React.Dispatch<React.SetStateAction<AccessParamList['Users']>>;
    textFilter: string;
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
    authenticatedUser?: AuthenticatedUser;
}

function UsersFilter({ filters, setFilters, setTextFilter, textFilter, authenticatedUser }: Props) {
    const [selectedActingBodyId, setSelectedActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [selectedActingBodyUnitId, setSelectedActingBodyUnitId] = useState<number>(filters.actingBodyIdUnitId ?? 0);
    const [phone, setPhone] = useState<string>(filters.phone ?? '');
    const [document, setDocument] = useState<string>(filters.document ?? '');
    const [role, setRole] = useState<number>(filters.role ?? 0);
    const [fieldAgent, setFieldAgent] = useState<boolean>(filters.fieldAgent ?? false);

    const navigation = useNavigation<NavigationProp<AccessParamList, 'Users'>>();

    const handleCleanFilter = () => {
        setTextFilter('');
        setSelectedActingBodyUnitId(0);
        setSelectedActingBodyId(0);
        setFieldAgent(false);
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            textFilter: textFilter !== '' ? textFilter : undefined,
            document: document !== '' ? document : undefined,
            phone: phone !== '' ? phone : undefined,
            role: role !== 0 ? role : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            actingBodyIdUnitId: selectedActingBodyUnitId !== 0 ? selectedActingBodyUnitId : undefined,
            fieldAgent: fieldAgent ? fieldAgent : undefined
        });

        navigation.setParams({
            textFilter: textFilter !== '' ? textFilter : undefined,
            document: document !== '' ? document : undefined,
            phone: phone !== '' ? phone : undefined,
            role: role !== 0 ? role : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            actingBodyIdUnitId: selectedActingBodyUnitId !== 0 ? selectedActingBodyUnitId : undefined,
            fieldAgent: fieldAgent ? fieldAgent : undefined,
            page: 1
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchUser')}
                value={textFilter}
                placeholder={translate('userFilterPlaceholder')}
                onChange={text => {
                    setTextFilter(text);
                }}
            />

            <MyCpfInput
                value={document}
                onChangeText={setDocument}
                viewStyle={{ flexGrow: 1, minWidth: 200, }}
                isInvalid={false}
                setIsInvalid={() => { }}
            />

            <MyPhoneInput
                label={translate('phone')}
                placeholder={translate('phone')}
                value={phone}
                onChangeText={setPhone}
                invalid={() => false}
                viewStyle={{
                    flexGrow: 1, minWidth: 200,
                }}
                labelStyle={{
                    fontSize: 16
                }}
            />

            {authenticatedUser?.isAdmin &&
                (
                    <>
                        <ActingBodiesDropdown
                            value={selectedActingBodyId}
                            setValue={setSelectedActingBodyId}
                            zIndex={3} />
                        <ActingBodiesUnitsDropdown actingBodyId={selectedActingBodyId}
                            value={selectedActingBodyUnitId}
                            setValue={setSelectedActingBodyUnitId}
                            zIndex={2} />
                    </>
                )
            }
            <RolesDropdown
                actingBodyId={authenticatedUser?.isAdmin ? selectedActingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                value={role}
                setValue={setRole}
                zIndex={1} />

            <FilterCheckBox
                label={translate('fieldAgent')}
                value={fieldAgent}
                onChange={setFieldAgent}
            />
        </Filters>
    );
}

export default UsersFilter;
