import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import { dashboardSummaryService, GetOccurrencesSummary } from '../../services/central-api/dashboard-summary';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../styles/date-ranger-picker.css';
import HeatMap from '../../components/HeatMap';
import { DashBoardParamList } from '../../typings/Params';
import useFilters from '../../components/Filter/hooks/useFilters';
import OccurrencesFilter from '../dispatch/Occurrences/Filters';
import { RouteProp, useRoute } from '@react-navigation/native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export default function DashboardOccurrences({ authenticatedUser }: { authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DashBoardParamList, 'DashboardOccurrences'>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [summary, setSummary] = useState<GetOccurrencesSummary>({
        total: 0,
        pendingOccurrences: 0,
        inServiceOccurrences: 0,
        onGoingOccurrences: 0,
        concludedOccurrences: 0,
        onFootTeamsTotal: 0,
        fourWheelsTeamsTotal: 0,
        twoWheelsTeamsTotal: 0,
        activesTotal: 0,
        onFootTeamsCurrent: 0,
        fourWheelsTeamsCurrent: 0,
        twoWheelsTeamsCurrent: 0,
        onFootTeamsNameTotal: 0,
        fourWheelsTeamsNameTotal: 0,
        twoWheelsTeamsNameTotal: 0,
        activesCurrent: 0,
        posList: []
    });

    const [filters, setFilters] = useFilters<DashBoardParamList['DashboardOccurrences']>({ ...route.params });

    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    async function getOccurrencesSummary() {
        try {
            setIsLoading(true);
            const occurrencesSummary = await dashboardSummaryService.getOccurrencesSummary(filters);
            setSummary(occurrencesSummary);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrencesSummary();
    }, [filters]);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flex: 1 }}>
                    <MyAppText style={styles.title} >{translate('occurrences')} </MyAppText>
                </View>
                <OccurrencesFilter
                    authenticatedUser={authenticatedUser}
                    filters={filters}
                    setFilters={setFilters}
                    textFilter={textFilter}
                    setTextFilter={setTextFilter} />
            </View>
            {isLoading ?
                <ActivityIndicator size='large' style={{ minHeight: 190 }} color={theme.colors.iconColor} /> :
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 20 }}>
                    <View style={{ flex: 2, flexDirection: 'row', justifyContent: 'space-between', minWidth: 250 }}>
                        <View style={styles.row}>
                            <View>
                                <MyAppText style={styles.number}>{summary.twoWheelsTeamsCurrent + '/' + summary.twoWheelsTeamsTotal + '/' + summary.twoWheelsTeamsNameTotal} </MyAppText>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.text}>{translate('2WheelsTeams')} </MyAppText>
                                </View>
                            </View>
                            <View >
                                <MyAppText style={styles.number}>{summary.fourWheelsTeamsCurrent + '/' + summary.fourWheelsTeamsTotal + '/' + summary.fourWheelsTeamsNameTotal} </MyAppText >
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.text}>{translate('4WheelsTeams')} </MyAppText>
                                </View>
                            </View>
                            <View>
                                <MyAppText style={styles.number}>{summary.onFootTeamsCurrent + '/' + summary.onFootTeamsTotal + '/' + summary.onFootTeamsNameTotal} </MyAppText>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.text}>{translate('OnFootTeams')} </MyAppText>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <View style={styles.occurrenceTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('type')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('amount')}</MyAppText>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.occurrenceTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('pending')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.pendingOccurrences}</MyAppText>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.occurrenceTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('in_service')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.inServiceOccurrences}</MyAppText>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.occurrenceTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('status_on_going')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.onGoingOccurrences}</MyAppText>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.occurrenceTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('concluded')}</MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.concludedOccurrences}</MyAppText>
                            </View>
                        </View>
                    </View>
                </View>
            }
            <HeatMap posList={summary.posList} />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 40,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        flex: 1,
        padding: 20,
        flexGrow: 3,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    tableLine: {
        borderBottomColor: theme.colors.container.border,
        borderBottomWidth: 1,
    },
    tableRows: {
        flex: 1,
        justifyContent: 'space-evenly',
    },
    occurrenceTableRow: {
        flexDirection: 'row',
        flex: 1
    },
    title: {
        fontFamily: 'Open Sans',
        color: theme.colors.labelColor,
        fontSize: 20,
        fontWeight: 'bold'
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text: {
        fontSize: 15,
        color: theme.colors.labelColor,
        whiteSpace: 'break-spaces'
    },
    tableText: {
        color: theme.colors.labelColor,
        margin: 5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    number: {
        fontSize: 35,
        fontWeight: 'bold'
    },
}));
