import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Modal, ActivityIndicator } from 'react-native';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { WindowInformation } from '../../../services/window-information';
import FormActions from '../../formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyRadio from '../../MyRadio';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';

interface FinishReasonModalParameters {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    finishCategory: number;
    finishSubCategory: number;
    setFinishCategory: (value: number) => void;
    setFinishSubCategory: (value: number) => void;
    actingBodyId: number;
}
export default function FinishReasonModal({
    isModalVisible,
    setModalVisible,
    onSubmit,
    finishCategory,
    finishSubCategory,
    setFinishCategory,
    setFinishSubCategory,
    actingBodyId
}: FinishReasonModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [finishCategories, setFinishCategories] = useState<FinishCategoryBase[]>([]);
    const [loading, setIsLoading] = useState(false);

    async function loadPageInfo() {
        try {
            if (actingBodyId !== 0) {
                setIsLoading(true);
                const finishCategories = await dispatchService.getSimplifiedFinishReasons(actingBodyId);
                setFinishCategories(finishCategories);
            }
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        loadPageInfo();
    }, [isModalVisible, actingBodyId]);

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                setModalVisible(false);
                setFinishCategory(0);
                setFinishSubCategory(0);
            }}
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '40%' }]}>
                        {loading ?
                            <View style={{ height: 40 }}>
                                <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                            </View>
                            : finishCategories.length ?
                                <View>
                                    <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('finishReason')}</MyAppText>
                                    <View style={styles.content}>
                                        <MyRadio
                                            style={styles.customRadio}
                                            selected={String(finishCategory)}
                                            setSelected={(value) => {
                                                setFinishCategory(value);
                                                setFinishSubCategory(0);
                                            }}
                                            options={finishCategories.map((finishCategory) => {
                                                return {
                                                    label: finishCategory.name,
                                                    value: finishCategory.id
                                                };
                                            })}
                                        />
                                        {finishCategory && (finishCategories.map(fg => fg.id)).includes(finishCategory) ?
                                            <View style={{ rowGap: 10, flex: 1 }}>
                                                <MyRadio
                                                    style={styles.customRadio}
                                                    selected={String(finishSubCategory)}
                                                    setSelected={(value) => {
                                                        setFinishSubCategory(value);
                                                    }}
                                                    options={(finishCategories.find(finishCat => finishCat.id == finishCategory) as FinishCategory).finishSubCategories
                                                        .map((finishSubCategory) => {
                                                            return {
                                                                label: finishSubCategory.name,
                                                                value: finishSubCategory.id
                                                            };
                                                        })}
                                                />
                                            </View> : null}
                                    </View>
                                </View> :
                                <View>
                                    <MyAppText>{translate('NonFinishCategoryMessage')}</MyAppText>
                                </View>
                        }

                        <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                            <FormActions
                                onSubmit={() => {
                                    onSubmit();
                                    setModalVisible(false);
                                }}
                                onClose={() => {
                                    setModalVisible(false);
                                    setFinishCategory(0);
                                    setFinishSubCategory(0);
                                }}
                                disabled={!(finishCategory && finishSubCategory)} />
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        videoNotReadyText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 560,
            padding: 30,
            justifyContent: 'space-between'
        },
        row: {
            flexDirection: 'column',
            columnGap: 30,
        },
        content: {
            flexDirection: 'row',
            columnGap: 30,
            flex: 1
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        customRadio: {
            flexDirection: 'column',
            rowGap: 10,
        },
        input: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
        },
        multiline: {
            minHeight: 50,
            height: 100,
        },
    });
}
