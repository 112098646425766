import React, { useEffect, useState } from 'react';
import { View, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import { Tabs } from '../../../components/Tabs';
import { WindowInformation } from '../../../services/window-information';
import { dispatchService } from '../../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';


interface ModalProps {
    isModalVisible: boolean;
    onClose: () => void;
    skillId?: number;
    authenticatedUser?: AuthenticatedUser;
}

export default function SkillModal({ onClose, skillId, authenticatedUser, isModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);
    const [skillUserIds, setSkillUserIds] = useState<number[]>([]);

    function clearValues() {
        setName('');
        setDescription('');
        setSelectableUsers([]);
        setSelectedUsers([]);
        setSkillUserIds([]);
        setActingBodyId(0);
    }

    async function getSkill(id: number) {
        const skill = await dispatchService.getSkill(id);
        setName(skill.name);
        setDescription(skill.description);
        setActingBodyId(skill.actingBodyId);

        const selectedUsers = skill.Users.map((user) => {
            return {
                id: user.id,
                name: user.name
            };
        });
        setSelectedUsers(selectedUsers);
        setSkillUserIds(skill.Users.map((user) => user.id));
    }

    async function loadPageInfo() {
        try {
            if (skillId) {
                await getSkill(skillId);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function getSelectableUsers(actingBodyId?: number) {
        const users = await dispatchService.getSkillUsers(actingBodyId);
        setSelectableUsers(users
            .filter((user) => !selectedUsers.find((skillUser) => skillUser.id == user.id))
            .map((user) => {
                return {
                    id: user.id,
                    name: user.name
                };
            })
        );
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }

        if (!(authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_skills)) {
            getSelectableUsers();
        } else if (actingBodyId !== 0) {
            getSelectableUsers(actingBodyId);
        }

    }, [actingBodyId, authenticatedUser]);

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name);
    }

    async function formSubmit() {
        try {
            if (skillId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !skillUserIds.includes(selectedUserId));
                const usersToRm = skillUserIds.filter((skillUserId) => !selectedUserIds.includes(skillUserId));

                await dispatchService.updateSkill(skillId, {
                    name,
                    description,
                    usersToAdd,
                    usersToRm,
                });
            } else {
                await dispatchService.createSkill({
                    name,
                    description,
                    usersToAdd: selectedUsers.map(item => Number(item.id)),
                    actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            clearValues();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onClose();
                clearValues();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 635 }]}>
                    <Tabs tabs={[{
                        key: 'details',
                        label: translate('details')
                    }, {
                        key: 'users',
                        label: translate('users')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'details') {
                                return (<>
                                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_skills) &&
                                        <ActingBodiesDropdown includeAllOption={false} value={actingBodyId} setValue={setActingBodyId} zIndex={2} disabled={!!skillId} />
                                    }
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isEmpty}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={() => false}
                                    />
                                </>);
                            }
                            if (selectedTab == 'users') {
                                return <SelectableList
                                    selectableLabel={translate('users')}
                                    selectableContent={selectableUsers}
                                    selectedLabel={translate('participants')}
                                    selectedContent={selectedUsers}
                                />;
                            }
                        }}
                    </Tabs >
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            clearValues();
                        }}
                        disabled={!isFormValid()} />
                </View >
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        height: 500,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
