import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import { Tabs } from '../../../components/Tabs';
import MyAppText from '../../../components/MyAppText';
import { MyCheckbox } from '../../../components/MyCheckbox';
import { accessService } from '../../../services/central-api/access';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { WindowInformation } from '../../../services/window-information';
import { cameraService } from '../../../services/central-api/cameras';
import { centralAPI } from '../../../services/central-api';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import ActingBodiesDropdown from '../../dispatch/Units/ActingBodiesDropdown';
import Toast from 'react-native-toast-message';
import ActingBodiesUnitDropdown from '../../dispatch/Units/ActingBodyUnitDropdown';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    groupId?: number;
}

const defaultPermissionsValue: GroupPermissions = {
    camera_watch: true,
    camera_watch_timeline: false,
    camera_move_ptz: false,
    camera_watch_facial_detections: false,
    camera_watch_lpr_detections: false
};

export default function GroupModal({ onClose, groupId, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [ownUser, setOwnUser] = useState<UserData>();
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [unitId, setUnitId] = useState<number>(0);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);
    const [groupUserIds, setGroupUserIds] = useState<number[]>([]);
    const [selectableCameras, setSelectableCameras] = useState<SelectableItem[]>([]);
    const [selectedCameras, setSelectedCameras] = useState<SelectableItem[]>([]);
    const [groupCameraIds, setGroupCameraIds] = useState<string[]>([]);
    const [selectableTagsFilter, setSelectableTagsFilter] = useState<{ label: string, value: number; }[]>([]);
    const [isTagPickerOpen, setIsTagPickerOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [cameras, setCameras] = useState<AccessSimplifiedCameraData[]>([]);
    const [displayFilter, setDisplayFilter] = useState<string[]>([]);
    const [permissions, setPermissions] = useState<GroupPermissions>(defaultPermissionsValue);

    function cleanValues() {
        setName('');
        setDescription('');
        setSelectedUsers([]);
        setSelectableUsers([]);
        setActingBodyId(0);
        setUnitId(0);
        setSelectableCameras([]);
        setSelectedCameras([]);
        setSelectedTags([]);
        setCameras([]);
        setPermissions(defaultPermissionsValue);
    }

    async function getGroup(id: number, cameras: AccessSimplifiedCameraData[]) {
        const group = await accessService.getGroup(id);
        setName(group.name);
        setDescription(group.description);
        setPermissions(group.groupPermissionObj);
        if (group.actingBodyUnit?.actingBodyId) {
            setActingBodyId(group.actingBodyUnit.actingBodyId);
            setUnitId(group.actingBodyUnitId);

            const users = await loadUsers(group.actingBodyUnit.id || 0);
            const selectedUsers = group.Users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            });
            setSelectedUsers(selectedUsers);

            setSelectableUsers((users || [])
                .filter((user) => !selectedUsers.find((groupUser) => groupUser.id == user.id))
                .map((user) => {
                    return {
                        id: user.id || 0,
                        name: user.name
                    };
                })
            );

            setGroupUserIds(group.Users.map((user) => user.id || 0));
        }


        const selectedCameras = group.Cameras.map((camera) => {
            return {
                id: camera.id,
                name: camera.title
            };
        });
        setSelectedCameras(selectedCameras);
        setGroupCameraIds(group.Cameras.map((camera) => camera.id));
        setSelectableCameras(cameras
            .filter((camera) => !selectedCameras.find((groupCamera) => groupCamera.id == camera.id))
            .map((camera) => {
                return {
                    id: camera.id,
                    name: camera.title
                };
            })
        );
    }

    async function loadPageInfo() {
        try {
            const [cameras, tags, ownUser] = await Promise.all([
                accessService.getAllCameras({}),
                cameraService.getCameraTagsSimplified(),
                centralAPI.getOwnUser({ includeRole: true }),
            ]);

            setOwnUser(ownUser);
            setSelectableTagsFilter((tags || []).map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name
                };
            }));

            setCameras(cameras);
            setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));

            if (groupId) {
                await getGroup(groupId, cameras);
            } else {
                setSelectableCameras(cameras.map((camera) => {
                    return {
                        id: camera.id || 0,
                        name: camera.title
                    };
                }));

                if (!ownUser.isAdmin && ownUser.actingBodyUnitId) {
                    setActingBodyId(ownUser.role?.actingBodyId || 0);
                    const users = await loadUsers(ownUser.actingBodyUnitId);
                    setSelectableUsers((users || []).map((user) => {
                        return {
                            id: user.id || 0,
                            name: user.name
                        };
                    }));
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadUsers(actingBodyUnitId: number) {
        try {
            const users = await accessService.getAccessAllUsers({ actingBodyUnitId: actingBodyUnitId });
            return users;
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    useEffect(() => {
        if (!selectableTagsFilter || !selectedTags || !selectableCameras || !selectedCameras || !cameras) {
            return;
        }

        if (selectedTags.length == 0) {
            return setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));
        }

        setDisplayFilter(cameras.filter((camera: AccessSimplifiedCameraData) => {
            return camera.tagList?.some(tag => selectedTags.includes(tag));
        }).map(camera => {
            return camera.id;
        }));
    }, [selectedTags]);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name) && (!ownUser?.isAdmin || unitId !== 0);
    }

    async function formSubmit() {
        try {
            if (groupId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !groupUserIds.includes(selectedUserId));
                const usersToRm = groupUserIds.filter((groupUserId) => !selectedUserIds.includes(groupUserId));

                const selectedCameraIds = selectedCameras.map((camera) => String(camera.id));
                const camerasToAdd = selectedCameraIds.filter((selectedCameraId) => !groupCameraIds.includes(selectedCameraId));
                const camerasToRm = groupCameraIds.filter((groupCameraId) => !selectedCameraIds.includes(groupCameraId));

                await accessService.updateGroup(groupId, {
                    name,
                    description,
                    usersToAdd,
                    usersToRm,
                    camerasToAdd,
                    camerasToRm,
                    permissions
                });
            } else {
                await accessService.createGroup({
                    name,
                    description,
                    usersToAdd: selectedUsers.map(item => Number(item.id)),
                    camerasToAdd: selectedCameras.map(item => String(item.id)),
                    permissions,
                    unitId,
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            cleanValues();
            return;
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function changedUnit(unitId: number) {
        const users = await loadUsers(unitId);

        setSelectableUsers((users || []).map((user) => {
            return {
                id: user.id || 0,
                name: user.name
            };
        }));
    }

    useEffect(() => {
        if (actingBodyId !== 0) {
            setSelectedUsers([]);
            setSelectableUsers([]);
        }

        if (unitId !== 0 && !groupId) {
            changedUnit(unitId);
        }
    }, [actingBodyId, unitId]);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => {
            setModalVisible(false);
            onClose();
            cleanValues();
        }} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '80%' }]}>
                    <Tabs tabs={[{
                        key: 'details',
                        label: translate('details')
                    }, {
                        key: 'users',
                        label: translate('users')
                    }, {
                        key: 'cameras',
                        label: translate('cameras')
                    }, {
                        key: 'permissions',
                        label: translate('permissions')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'details') {
                                return (<>
                                    {ownUser?.isAdmin ?
                                        <View style={{ flexDirection: 'row', gap: 16, zIndex: 2 }}>
                                            <View style={{ flex: 1 }}>
                                                <ActingBodiesDropdown
                                                    value={actingBodyId}
                                                    setValue={setActingBodyId}
                                                    zIndex={3}
                                                    disabled={!!groupId}
                                                    includeAllOption={false} />
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <ActingBodiesUnitDropdown
                                                    actingBodyId={actingBodyId}
                                                    value={unitId}
                                                    setValue={setUnitId}
                                                    zIndex={2}
                                                    disabled={!!groupId} />
                                            </View>
                                        </View>
                                        : null
                                    }
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isEmpty}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={() => false}
                                    />
                                </>);
                            } else if (selectedTab == 'users') {
                                return <SelectableList
                                    selectableLabel={translate('users')}
                                    selectableContent={selectableUsers}
                                    selectedLabel={translate('participants')}
                                    selectedContent={selectedUsers}
                                />;
                            } else if (selectedTab == 'cameras') {
                                return (<View style={{ rowGap: 10, flex: 1 }}>
                                    <View style={{ zIndex: 2 }}>
                                        <MyAppText>{translate('tags')}</MyAppText>
                                        <MyDropDownPicker
                                            placeholderColor={theme.colors.placeholder}
                                            placeholder={translate('filter')}
                                            open={isTagPickerOpen}
                                            items={selectableTagsFilter}
                                            setOpen={setIsTagPickerOpen}
                                            value={selectedTags}
                                            setValue={setSelectedTags}
                                            multiple={true}
                                            mode='BADGE'
                                            searchable={true}
                                        />
                                    </View>
                                    <SelectableList
                                        displayFilter={displayFilter}
                                        selectableLabel={translate('cameras')}
                                        selectableContent={selectableCameras}
                                        selectedLabel={translate('participants')}
                                        selectedContent={selectedCameras}
                                    />
                                </View>);
                            } else if (selectedTab == 'permissions') {
                                return (
                                    <View style={{ gap: 4 }}>
                                        <MyAppText style={{ fontWeight: 'bold' }}>{translate('cameras')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('canWatchLive')}
                                            checked={permissions.camera_watch}
                                            setChecked={() => { }}
                                        />
                                        <MyCheckbox
                                            label={translate('canWatchRecords')}
                                            checked={permissions.camera_watch_timeline}
                                            setChecked={(value) => {
                                                setPermissions((prevPermissions) => ({
                                                    ...prevPermissions,
                                                    camera_watch_timeline: value,
                                                }));
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('canMovePtz')}
                                            checked={permissions.camera_move_ptz}
                                            setChecked={(value) => {
                                                setPermissions((prevPermissions) => ({
                                                    ...prevPermissions,
                                                    camera_move_ptz: value,
                                                }));
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('canWatchFacialDetections')}
                                            checked={permissions.camera_watch_facial_detections}
                                            setChecked={(value) => {
                                                setPermissions((prevPermissions) => ({
                                                    ...prevPermissions,
                                                    camera_watch_facial_detections: value,
                                                }));
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('canWatchLprDetections')}
                                            checked={permissions.camera_watch_lpr_detections}
                                            setChecked={(value) => {
                                                setPermissions((prevPermissions) => ({
                                                    ...prevPermissions,
                                                    camera_watch_lpr_detections: value,
                                                }));
                                            }}
                                        />
                                    </View>);
                            }
                        }}
                    </Tabs>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            cleanValues();
                        }}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        height: '80%',
        backgroundColor: theme.colors.cardBackground,
        borderRadius: 2,
        padding: 20,
        minHeight: 520,
        justifyContent: 'space-between',
        rowGap: 10
    },
}));
