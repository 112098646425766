import React from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData, TouchableOpacity, View } from 'react-native';
import { useEffect, useState } from 'react';
import MyDataTable from '../../../components/MyDataTable';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Filters from './Filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import { dispatchService } from '../../../services/central-api/dispatch';
import EditModal from './EditModal';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import useFilters from '../../../components/Filter/hooks/useFilters';
import CustomButton from '../../../components/CustomButton';
import FilterInput from '../../../components/Filter/components/FilterInput';

interface Props {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function FinishCategories({ navigation, authenticatedUser }: Props) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DispatchParamList, 'FinishCategories'>>();

    const [filters, setFilters] = useFilters<DispatchParamList['FinishCategories']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [finishCategories, setFinishCategories] = useState<FinishCategory[]>([]);

    const [selectedFinishCategory, setSelectedFinishCategory] = useState<number>();
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedFinishCategoryToDelete, setSelectedFinishCategoryToDelete] = useState<FinishCategory | null>(null);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    async function getFinishCategories(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getPaginatedFinishCategories(filters);
            setFinishCategories(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(finishCategory: FinishCategory) {
        try {
            await dispatchService.deleteFinishCategory(finishCategory.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setDeleteModalVisible(false);
            getFinishCategories();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    function handleFormClose() {
        setSelectedFinishCategory(undefined);
        setModalVisible(false);
        getFinishCategories();
    }

    useEffect(() => {
        getFinishCategories();
    }, [filters]);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchFinishCategory')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filters
                        authenticatedUser={authenticatedUser}
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <CustomButton icon={faPlus}
                        text={translate('create')}
                        onPress={() => {
                            setSelectedFinishCategory(undefined);
                            setModalVisible(true);
                        }} />
                </View>
            </View>

            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true,
                            },
                            {
                                name: translate('actingBody'),
                                selector: row => row.actingBody.name,
                                wrap: true,
                            },
                            {
                                name: translate('actions'),
                                cell: row => (

                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedFinishCategory(row.id);
                                            setModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedFinishCategoryToDelete(row);
                                            setDeleteModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                                ),
                                wrap: true,
                                button: true
                            }
                        ]}
                        data={finishCategories}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    currentPage={filters.page}
                    totalItems={totalItems}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                />
            </View>

            <EditModal
                onClose={handleFormClose}
                finishCategoryId={selectedFinishCategory ?? undefined}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                authenticatedUser={authenticatedUser}
            />

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={selectedFinishCategoryToDelete?.name || ''}
                onSubmit={async () => {
                    if (selectedFinishCategoryToDelete) {
                        await confirmDelete(selectedFinishCategoryToDelete);
                    }
                }}
            />

        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
